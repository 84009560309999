<template>
  <div>
    <v-card elevation="9" class="rounded-lg" v-if="isVisible" width="450px">
      <v-card-title class="primary py-3">
        <h5 class="text-caption mr-1 font-weight-bold white--text" >
          <span color="white"> <v-icon color="white">mdi-chat</v-icon></span>
          {{
            tripDetails &&
            tripDetails.driver_details &&
            tripDetails.driver_details.driver_name
              ? tripDetails.driver_details.driver_name
              : ""
          }}
        </h5>
        <v-spacer></v-spacer>
        <v-icon color="white" @click="toggleChatBox=false" v-if="toggleChatBox"
          >mdi-minus</v-icon
        >
        <v-icon color="white" @click="toggleChatBox=true" v-if="!toggleChatBox"
          >mdi-chevron-up</v-icon
        >
        <v-icon color="white" @click="$emit('closeChat')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text
        :style="`height: ${chatHeight}; overflow-y: auto; position: relative`"
        id="chatBoxContainer"
        @scroll="scrollControlChatBox"
        class="pa-0"
        ref="chatParent"
        v-if="toggleChatBox"
      >
        <v-img
          :src="audioImage"
          v-show="isImageVisible"
          contain
          class="audio-listening"
        />
        <v-list v-for="(chat, index) in msgList" :key="index">
          <v-list-item
            v-if="chat && chat.sent_by_driver"
            class="d-flex justify-start"
          >
            <v-card outlined class="pa-3 mb-2" style="max-width: 80%">
              <v-list-item-title class="d-flex align-center ma-0 pa-0">
                <div>
                  <v-img
                    alt=""
                    style="height: 19px; width: 19px; border-radius: 50%"
                    contain
                    class="mr-2"
                    :src="sendImage"
                  ></v-img>
                </div>
                <span class="caption mt-n2 pt-2" v-if="chat && chat.driver">{{
                  chat.driver
                }}</span>
              </v-list-item-title>
              <v-divider class="mt-2"></v-divider>
              <div v-if="chat && chat.message_format === 'audio'">
                <audio controls style="width: 220px" class="mt-4">
                  <source :src="chat.attachment" type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              <div v-else-if="chat && chat.message_format == 'document'">
                <div>
                  <a
                    target="_blank"
                    :href="chat.attachment"
                    style="text-decoration: none"
                    v-if="
                      ['pdf', 'doc'].indexOf(getFormatOfFile(chat.attachment)) >
                      -1
                    "
                  >
                    <v-card elevation="0" class="mt-2" outlined>
                      <v-card-text>
                        <v-icon x-large color="error">mdi-file-pdf-box</v-icon>
                      </v-card-text>
                    </v-card>
                  </a>
                  <a
                    target="_blank"
                    :href="chat.attachment"
                    style="text-decoration: none"
                    v-else
                  >
                    <v-card elevation="0" class="mt-2" outlined>
                      <v-card-text>
                        <v-icon x-large color="green">mdi-file-excel</v-icon>
                      </v-card-text>
                    </v-card>
                  </a>
                </div>
              </div>
              <div v-else-if="chat && chat.message_format === 'image'">
                <a target="_blank" :href="chat.attachment">
                  <v-img
                    :src="chat.attachment"
                    width="200px"
                    height="150px"
                    contain
                  />
                </a>
              </div>
              <div v-else class="px-2 py-1">
                <span>{{ chat.message }}</span>
              </div>
              <div>
                <h6
                  class="text-caption text-right mt-3"
                  style="font-size: 9px !important"
                >
                  {{ chat.created }}
                  <v-icon x-small color="primary" class="ml-2"
                    >mdi-calendar-text</v-icon
                  >
                </h6>
              </div>
            </v-card>
          </v-list-item>
          <v-list-item v-else class="d-flex justify-end">
            <v-card
              outlined
              class="pa-3 mb-2"
              style="max-width: 80%"
              v-if="chat"
            >
              <v-list-item-title class="d-flex align-center ma-0 pa-0">
                <div>
                  <v-img
                    alt=""
                    style="height: 19px; width: 19px; border-radius: 50%"
                    contain
                    class="mr-2"
                    :src="sendImage"
                  ></v-img>
                </div>
                <span
                  class="caption mt-n2 pt-2"
                  v-if="chat && chat.admin_user"
                  >{{ chat.admin_user }}</span
                >
              </v-list-item-title>
              <v-divider class="mt-2"></v-divider>

              <div v-if="chat && chat.message_format === 'audio'">
                <audio controls style="width: 220px" class="mt-4 pr-4">
                  <source :src="chat.attachment" type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              <!-- <div v-else-if="chat && chat.message_format == 'document'">
                <a
                  target="_blank"
                  :href="chat.attachment"
                  style="text-decoration: none"
                >
                  <v-card elevation="0" class="mt-2" outlined>
                    <v-card-text>
                      <v-icon x-large color="green">mdi-file-excel</v-icon>
                    </v-card-text>
                  </v-card>
                </a>
              </div> -->
              <div v-else-if="chat && chat.message_format == 'document'">
                <div>
                  <a
                    target="_blank"
                    :href="chat.attachment"
                    style="text-decoration: none"
                    v-if="
                      ['pdf', 'doc'].indexOf(getFormatOfFile(chat.attachment)) >
                      -1
                    "
                  >
                    <v-card elevation="0" class="mt-2" outlined>
                      <v-card-text>
                        <v-icon x-large color="error">mdi-file-pdf-box</v-icon>
                      </v-card-text>
                    </v-card>
                  </a>
                  <a
                    target="_blank"
                    :href="chat.attachment"
                    style="text-decoration: none"
                    v-else
                  >
                    <v-card elevation="0" class="mt-2" outlined>
                      <v-card-text>
                        <v-icon x-large color="green">mdi-file-excel</v-icon>
                      </v-card-text>
                    </v-card>
                  </a>
                </div>
              </div>
              <div v-else-if="chat && chat.message_format === 'image'">
                <a target="_blank" :href="chat.attachment">
                  <v-img
                    :src="chat.attachment"
                    width="200px"
                    height="150px"
                    contain
                  />
                </a>
              </div>
              <div v-else class="px-2 py-1">
                <span>{{ chat.message }}</span>
              </div>
              <div>
                <h6
                  class="text-caption text-right mt-2"
                  style="font-size: 9px !important"
                >
                  {{ chat.created }}
                  <v-icon x-small color="primary" class="ml-2"
                    >mdi-calendar-text</v-icon
                  >
                </h6>
              </div>
            </v-card>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="ml-1" v-if="toggleChatBox">
        <v-row>
          <v-col cols="12" class="d-flex px-0 py-1 app-custom-search-input">
            <v-text-field
              dense
              hide-details="auto"
              outlined
              class="chat-box mt-2"
              @keyup.enter="sendMessageToDriver"
              placeholder="message"
              v-model="chatText"
            ></v-text-field>
            <v-icon size="20" class="mr-2" @click="sendMessageToDriver">
              mdi-send
            </v-icon>
            <v-icon size="20" @click="onAudioUpload" v-if="!isImageVisible">
              mdi-microphone-settings
            </v-icon>
            <v-icon size="20" class="text-red" @click="onAudioUpload" v-else>
              mdi-stop
            </v-icon>
            <form ref="chatForm" id="chatForm" enctype="multipart/form-data">
              <input
                name="attachment"
                ref="uploadImage"
                class="d-none"
                type="file"
                @change="onUploadFile"
                accept="*"
              />
              <v-icon
                size="20"
                class="mdi-rotate-90 pa-3"
                @click="onClickFileUpload"
              >
                mdi-attachment
              </v-icon>
            </form>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import defaultDriverImage from "@/assets/images/profile.jpg";
import defaultSendImage from "@/assets/images/profile.jpg";
import audioImage from "@/assets/images/startrecording.gif";

export default {
  props: {
    value: Boolean,
    isOffline: {
      default: false,
    },
    tripObj: {
      type: [Object, undefined, null],
    },
    tripId: {
      required: false,
      default: null,
    },
    chatHeight: {
      default: "350px",
    },
  },
  data() {
    return {
      id: null,
      filter: {},
      // Pagination Vars
      totalItems: 0,
      itemsPerPage: 10,
      pageNo: 1,

      chatDialogBox: true,
      chatText: "",
      driverName: null,
      isImageVisible: false,
      file: "",
      audioImage: audioImage,
      driverImage: defaultDriverImage,
      sendImage: defaultSendImage,
      ChatData: {
        message_format: null,
      },
      mediaRecorder: null,
      chunks: [],
      chatListData: [],
      msgList: [],
      chatScrollHeight: null,
      scrollEnable: false,
      tripDetails: {},
      intervalTimer: null,
      toggleChatBox: true,
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
    chatbox: {
      get() {
        this.msgList = [];
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    isVisible(val) {
      if (val) {
        this.tripDetails = this.tripObj;
        this.msgList = [];
        this.id = this.tripDetails.id;
        this.pageNo = 1;
        // this.$wbSocket.connection();
        this.getChatHistory();
        this.intervalTimer = setInterval(() => {
          this.getChatNotification();
        }, 5000);
      } else {
        clearInterval(this.intervalTimer);
      }
    },
    isOffline(val) {
      if (val == true) {
        if (this.$globalConstant.wbConnection) {
          // this.$wbSocket.closeConnection();
        }
      } else {
        // this.$wbSocket.closeConnection();
        // this.$wbSocket.connection();
      }
    },
  },

  methods: {
    minimizeChat(){
      this.toggleChatBox = false;
    },
    getFormatOfFile(str) {
      return str.substring(str.lastIndexOf(".") + 1);
    },
    scrollControlChatBox() {
      let elem = this.$refs.chatParent;
      if (this.chatScrollHeight == null) {
        this.chatScrollHeight = elem.scrollTop;
      }
      if (elem == null) {
        return false;
      } else if (elem.scrollTop < 1 && this.scrollEnable) {
        this.nextPage();
      }
    },
    autoScroll() {
      setTimeout(() => {
        if (document.getElementById("chatBoxContainer")) {
          let nid = document.getElementById("chatBoxContainer");
          nid.scrollTop = nid.scrollHeight;
        }
      }, 200);
    },
    nextPage() {
      this.pageNo++;
      if (this.pageNo <= this.totalPages) {
        this.getChatHistory();
      }
    },
    itemsPerPageChange(value) {
      this.pageNo = 1;
      this.itemsPerPage = value;
      this.getChatHistory();
    },
    onAudioUpload() {
      this.isImageVisible = !this.isImageVisible;
      this.chunks = [];
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        if (this.isImageVisible) {
          navigator.mediaDevices
            .getUserMedia({
              audio: true,
            })
            .then((stream) => {
              this.mediaRecorder = new MediaRecorder(stream, {
                mimeType: "audio/webm",
              });
              this.mediaRecorder.start();
              this.mediaRecorder.ondataavailable = (e) => {
                this.chunks.push(e.data);
              };
              this.mediaRecorder.onstop = (e) => {
                let b = new File(this.chunks, `${Date()}.mp3`, {
                  type: "audio/mp3",
                });
                const formData = new FormData();
                formData.append("message_format", "audio");
                formData.append("attachment", b);
                // formData.append("trip", this.tripDetails.id);
                formData.append("message", "audio_message");
                this.SubmitData(formData);
              };
            })
            .catch((err) => {
              console.error(
                "The following getUserMedia error occurred: " + err
              );
            });
        } else {
          this.mediaRecorder.stop();
          this.mediaRecorder.stream
            .getTracks()
            .forEach((track) => track.stop());
        }
      } else {
        console.error("getUserMedia not supported on your browser!");
      }
    },
    onClickFileUpload() {
      this.$refs.uploadImage.click();
    },
    onUploadFile() {
      this.file = this.$refs.uploadImage.files[0];

      if (this.file && this.file == "") return false;

      const formData = new FormData();
      formData.append("message_format", "image");
      formData.append("attachment", this.file);
      // formData.append("trip", this.tripDetails.id);
      formData.append("message", "file sent by user");
      this.SubmitData(formData);
    },
    SubmitData(data) {
      this.$api.operation
        .sendMessageToDriver(this.tripId, data)
        .then((res) => {
          this.chatText = "";
          this.file = "";
          // this.msgList = res.data;
          // response.data["attachment"] = response.data.attachment;
          this.msgList.push(res.data);
          this.autoScroll();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getChatHistory() {
      this.$api.operation
        .getChatHistory(this.tripId, {
          offset: this.offset,
          limit: this.itemsPerPage,
        })
        .then((res) => {
          // this.msgList = res.data.reverse();
          // this.autoScroll();
          this.totalItems = res.count;
          if (this.msgList && this.msgList.length == 0) {
            this.msgList = res.data.reverse();
            this.autoScroll();
            this.scrollEnable = true;
          } else {
            if (res.data.length > 0) {
              let Sorting = res.data.reverse();
              this.msgList = [...Sorting, ...this.msgList];
              setTimeout(() => {
                document.getElementById("chatBoxContainer").scrollTop =
                  this.chatScrollHeight;
                this.chatScrollHeight = null;
              }, 300);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getChatNotification() {
      this.$api.operation
        .getChatNotification(this.tripId)
        .then((res) => {
          if (res && res.data.new_message) {
            this.pageNo = 0;
            this.msgList = [];
            this.totalItems = 0;
            setTimeout(() => {
              this.getChatHistory();
            }, 100);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getChatHistoryReceived(chatHistory) {
      // if (chatHistory) {
      //   this.totalItems = chatHistory.count;
      //   chatHistory.attachment = chatHistory.attachment;
      //   if (this.msgList && this.msgList.length == 0) {
      //     this.msgList = chatHistory.reverse();
      //     this.autoScroll();
      //     this.scrollEnable = true;
      //   } else {
      //     if (chatHistory.length > 0) {
      //       let Sorting = chatHistory;
      //       this.msgList = [...Sorting, ...this.msgList];
      //       setTimeout(() => {
      //         document.getElementById("chatBoxContainer").scrollTop =
      //           this.chatScrollHeight;
      //         this.chatScrollHeight = null;
      //       }, 300);
      //     }
      //   }
      // }
    },
    clear() {
      this.msgList = [];
      this.isImageVisible = false;
      this.mediaRecorder = null;
      this.chatScrollHeight = null;
      this.filter = {};
      this.scrollEnable = false;
    },
    sendMessageToDriver() {
      if (this.chatText.length === 0) return false;

      const formData = new FormData();
      formData.append("message_format", "text");
      formData.append("sent_by_driver", false);
      formData.append("message", this.chatText);

      this.SubmitData(formData);
      // if (
      //   this.$globalConstant.wbConnection &&
      //   this.$globalConstant.wbConnection.readyState === 3
      // ) {
      //   this.$wbSocket.connection.closeConnection();
      //   this.$wbSocket.connection.then((res) => {
      //     this.$wbSocket.wbConnection.send(JSON.stringify(payload));
      //   });
      // } else {
      //   this.$wbSocket.wbConnection.send(JSON.stringify(payload));
      // }

      this.chatText = "";
      this.file = "";
      this.autoScroll();
    },
    getChatData(wb) {
      // this.tripId
      // this.getChatHistory();
      // this.getChatNotification();
      // if (wb.type == "new_message") {
      //   this.msgList.push(wb.data);
      //   this.autoScroll();
      // } else if (wb.type == "new_message_received") {
      //   this.msgList.push(wb.data);
      //   this.autoScroll();
      // } else if (wb.type == "chat_history") {
      //   this.getChatHistoryReceived(wb.data);
      // } else if (wb.type == "error") {
      //   this.msgList.push(wb.data);
      //   this.autoScroll();
      // }
    },
  },
  created() {
    this.$bus.$on("getChatData", this.getChatData);
  },
  beforeDestroy() {
    clearInterval(this.intervalTimer);
  },
};
</script>

<style scoped lang="scss">
.chatMainContainer {
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 999;
  width: 550px;
}
.chat-box .v-input__slot {
  box-shadow: none !important;
  border: 11px solid red !important;
}
.audio-listening {
  position: sticky;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  height: 150px;
  width: 150px;
}
</style>
