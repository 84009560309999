import { rate } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  getRateList(params) {
    return get(`${rate.base}`, { params: params });
  },
  getRateSelectList(params) {
    return get(`${rate.base}`, { params: params });
  },
  getRateObject(id) {
    return get(`${rate.base}${id}/`);
  },
  addRate(data) {
    return post(`${rate.base}`, data);
  },
  updateRate(data, id) {
    return put(`${rate.base}${id}/`, data);
  },
  getSampleRateFileDownload(params) {
    return get(`${rate.sampleRateFile}`, {
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
      params: params,
    });
  },
});
