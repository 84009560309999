export const customerManagementLabel = {
  name: "Customer Management",
  plural: "Customer Management",
  // Used in routes and navigation
  pathName: "customer-management",
  value: "customer-management",
  permissionKey: "customer_management",
};

export const customerLabel = {
  name: "Customer",
  plural: "Customers",
  // Used in routes and navigation
  pathName: "customer",
  value: "customer",
  permissionKey: "customer_management",
};

export const customerAddressLabel = {
  name: "Customer Address",
  plural: "Customer Addresses",
  // Used in routes and navigation
  pathName: "customer-address",
  value: "customer-address",
  permissionKey: "customer_management",
};

export const movementTypeOptions = {
  name: "Movement Type",
  list: [
    {
      label: "Short Haul",
      value: "short_haul",
    },
    {
      label: "Long Haul",
      value: "long_haul",
    },
  ],
  object: {
    shortHaul: "short_haul",
    longHaul: "long_haul",
  },
  array: ["short_haul", "long_haul"],
};
