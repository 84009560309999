export const configurationLabel = {
  name: "Configuration",
  plural: "Configurations",
  // Used in routes and navigation
  pathName: "config",
  value: "configuration",
  permissionKey: "configuration",
};

export const projectLabel = {
  name: "Project",
  plural: "Projects",
  // Used in routes and navigation
  pathName: "project",
  value: "project",
  permissionKey: "configuration",
};

export const warehouseLabel = {
  name: "Warehouse",
  plural: "Warehouses",
  // Used in routes and navigation
  pathName: "warehouse",
  value: "warehouse",
  permissionKey: "configuration",
};

export const storageTypeLabel = {
  name: "Storage Type",
  plural: "Storage Types",
  // Used in routes and navigation
  pathName: "storagetype",
  value: "storage-type",
  permissionKey: "configuration",
};

export const vehicleTypeLabel = {
  name: "Vehicle Type",
  plural: "Vehicle Types",
  // Used in routes and navigation
  pathName: "vehicletype",
  value: "vehicle-type",
  permissionKey: "configuration",
};

export const fieldLabel = {
  name: "Field Configuration",
  plural: "Field Configurations",
  // Used in routes and navigation
  pathName: "field",
  value: "field",
  permissionKey: "configuration",
};

export const paymentMethodLabel = {
  name: "Payment Method",
  plural: "Payment Methods",
  // Used in routes and navigation
  pathName: "paymentmethod",
  value: "payment-method",
  permissionKey: "configuration",
};

export const roleLabel = {
  name: "Role",
  plural: "Roles",
  // Used in routes and navigation
  pathName: "role",
  value: "role",
  permissionKey: "configuration",
};

export const roadBanLabel = {
  name: "Road Ban",
  plural: "Road Bans",
  // Used in routes and navigation
  pathName: "roadban",
  value: "road-ban",
  permissionKey: "configuration",
};

export const packageTypeLabel = {
  name: "Package Type",
  plural: "Package Types",
  // Used in routes and navigation
  pathName: "packagetype",
  value: "package-type",
  permissionKey: "configuration",
};
