import Blank from "@/layouts/blank.vue";
import { zoneLabel } from "@/utils/labels/laneManagementLabels";

const zoneRoutes = [
  {
    path: `${zoneLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${zoneLabel.value}-list`,
        component: () => import("@/pages/laneManagement/zone/ZoneList.vue"),
      },
      {
        path: "add/",
        name: `${zoneLabel.value}-add`,
        component: () => import("@/pages/laneManagement/zone/ZoneAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${zoneLabel.value}-edit`,
        component: () => import("@/pages/laneManagement/zone/ZoneEdit.vue"),
      },
    ],
  },
];

export default zoneRoutes;
