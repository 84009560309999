import Blank from "@/layouts/blank.vue";
import { vehicleServiceLabel } from "@/utils/labels/assetManagementLabels";

const vehicleServiceRoutes = [
  {
    path: `${vehicleServiceLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${vehicleServiceLabel.value}-list`,
        component: () =>
          import("@/pages/assets/vehicleService/VehicleServiceList.vue"),
      },
    ],
  },
];

export default vehicleServiceRoutes;
