export const itemPerPage = 15;

export const defaultTheme = {
  subHeading: "#cfd8dc",
  background_color: "#EFF2F5",
  open_transaction: "#DBDBDB",
  unassigned: "#607D8B",
  assigned: "#795548",
  enroute: "#80CBC4",
  pickedup: "#FF9800",
  intransit: "#FF9800",
  not_pickedup: "#FDD835",
  cancelled: "#F48FB1",
  delivered: "#4CAF50",
  completed: "#4CAF50",
  not_delivered: "#F44336",
  partially_delivered: "#E91E63",
  failed: "#F44336",
  returned: "#333333",
  trip_pending: "#ffb89a",
  trip_approved: "#ff467c",
  trip_declined: "#c9004b",
  trip_scheduled: "#607D8B",
  trip_active: "#2196F3",
  trip_paused: "#FF9800",
  trip_cancelled: "#F44336",
  admin_action_required: "#F48FB1",
  trip_completed: "#4CAF50",
};

export const userPermissions = {
  asset_management: {
    delete: false,
    change: false,
    add: false,
    view: false,
  },
  configuration: {
    delete: false,
    change: false,
    add: false,
    view: false,
  },
  customer_management: {
    delete: false,
    change: false,
    add: false,
    view: false,
  },

  integration_log: {
    delete: false,
    change: false,
    add: false,
    view: false,
  },
  item: {
    delete: false,
    change: false,
    add: false,
    view: false,
  },
  lane_management: {
    delete: false,
    change: false,
    add: false,
    view: false,
  },
  order: {
    delete: false,
    change: false,
    add: false,
    view: false,
  },
  // operation: {
  //   delete: false,
  //   change: false,
  //   add: false,
  //   view: false,
  // },

  transporter_management: {
    delete: false,
    change: false,
    add: false,
    view: false,
  },
  trip: {
    delete: false,
    change: false,
    add: false,
    view: false,
  },
  trip_planning: {
    delete: false,
    change: false,
    add: false,
    view: false,
  },
  user: {
    delete: false,
    change: false,
    add: false,
    view: false,
  },
  support: {
    delete: false,
    change: false,
    add: false,
    view: false,
  }
};

export const monthList = [
  {
    label: "January",
    value: "January",
  },
  {
    label: "February",
    value: "February",
  },
  {
    label: "March",
    value: "March",
  },
  {
    label: "April",
    value: "April",
  },
  {
    label: "May",
    value: "May",
  },
  {
    label: "June",
    value: "June",
  },
  {
    label: "July",
    value: "July",
  },
  {
    label: "August",
    value: "August",
  },
  {
    label: "September",
    value: "September",
  },
  {
    label: "October",
    value: "October",
  },
  {
    label: "November",
    value: "November",
  },
  {
    value: "December",
    label: "December",
  },
];

export const filterName = "userFilter";

export const currencyName = "SAR";

export default {
  itemPerPage,
  defaultTheme,
};
