import Blank from "@/layouts/blank.vue";
import { operationLabel } from "@/utils/labels/operationLabels";

const operationRoutes = [
  {
    path: `${operationLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${operationLabel.name}-list`,
        component: () => import("@/pages/operation/Operation.vue"),
        default: true,
      },
    ],
  },
];

export default operationRoutes;
