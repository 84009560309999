import { vehicleDocument, transporterVehicle } from "@/utils/urlConstants";
import { isTransporter } from "@/utils/functions";

export default (axios) => ({
  getVehicleDocumentList(params) {
    return axios.get(
      `${isTransporter() ? transporterVehicle.document : vehicleDocument.base}`,
      { params: params }
    );
  },
  addVehicleDocument(data) {
    return axios.post(
      `${isTransporter() ? transporterVehicle.document : vehicleDocument.base}`,
      data
    );
  },
  updateVehicleDocument(data, id) {
    return axios.put(
      `${
        isTransporter() ? transporterVehicle.document : vehicleDocument.base
      }}${id}/`,
      data
    );
  },
  deleteVehicleDocument(id) {
    return axios.delete(
      `${
        isTransporter() ? transporterVehicle.document : vehicleDocument.base
      }}${id}/`
    );
  },
});
