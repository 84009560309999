import Blank from "@/layouts/blank.vue";
import { packageTypeLabel } from "@/utils/labels/configurationLabels";

const packageTypeRoutes = [
  {
    path: `${packageTypeLabel.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${packageTypeLabel.value}-list`,
        component: () =>
          import("@/pages/configuration/packageType/packageTypeList.vue"),
        default: true,
      },
    ],
  },
];

export default packageTypeRoutes;
