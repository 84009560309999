import { vehicle, transporterVehicle } from "@/utils/urlConstants";
import { isTransporter } from "@/utils/functions";

export default ({ get, post, put, patch }) => ({
  getVehicleList(params) {
    return get(`${isTransporter() ? transporterVehicle.base : vehicle.base}`, {
      params: params,
    });
  },
  getVehicleActiveTrip(id) {
    return get(
      `${
        isTransporter() ? transporterVehicle.base : vehicle.base
      }${id}/current_trip/`
    );
  },
  getVehicleLogs(params) {
    return get(`${transporterVehicle.logs}`, { params: params });
  },
  getVehicleSelectList(params = {}) {
    return get(
      `${isTransporter() ? transporterVehicle.base : vehicle.base}select/`,
      {
        params: params,
      }
    );
  },
  getVehicleObject(id) {
    return get(
      `${isTransporter() ? transporterVehicle.base : vehicle.base}${id}/`
    );
  },
  getVehicleDetails(id) {
    return get(
      `${isTransporter() ? transporterVehicle.base : vehicle.base}${id}/view/`
    );
  },
  addVehicle(data) {
    return post(
      `${isTransporter() ? transporterVehicle.base : vehicle.base}`,
      data
    );
  },
  updateVehicle(data, id) {
    return put(
      `${isTransporter() ? transporterVehicle.base : vehicle.base}${id}/`,
      data
    );
  },
  changeVehicleStatus(data, id) {
    return patch(
      `${
        isTransporter() ? transporterVehicle.base : vehicle.base
      }${id}/update_vehicle_status/`,
      data
    );
  },
  changeVehicleMovementType(data, id) {
    console.log(data, id);
    return post(
      `${
        isTransporter() ? transporterVehicle.base : vehicle.base
      }${id}/toggle_movement_type/`,
      data
    );
  },
});
