import Blank from "@/layouts/blank.vue";
import { transporterManagementLabel } from "@/utils/labels/transporterManagementLabels";
import transporterRoutes from "@/router/transporterManagement/transporter.js";
import transporterUserRoutes from "@/router/transporterManagement/transporterUser.js";
import transporterRateRoutes from "@/router/transporterManagement/transporterRate";
import rateRoutes from "@/router/transporterManagement/Rate";
import bidRoutes from "@/router/transporterManagement/bid";

const transporterManagementRoutes = [
  {
    path: `${transporterManagementLabel.pathName}/`,
    component: Blank,
    children: [
      ...transporterRoutes,
      ...transporterUserRoutes,
      ...transporterRateRoutes,
      ...bidRoutes,
      ...rateRoutes,
    ],
    name: transporterManagementLabel.value,
  },
];

export default transporterManagementRoutes;
