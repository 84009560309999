import { authentication } from "@/utils/urlConstants";

export default ({ post, get, patch }) => ({
  login(data) {
    return post(authentication.login, data);
  },
  twoFactorLogin(data) {
    return post(authentication.twoFactorLogin, data);
  },
  verifyLogin(data) {
    return post(authentication.verifyOTP, data);
  },
  getProfileInfo() {
    return get(authentication.profileInfo);
  },
  getProfileUpdate(data) {
    return patch(authentication.profileInfo, data);
  },
  changePassword(data) {
    return patch(authentication.changePassword, data);
  },
  logout() {
    return post(authentication.logout);
  },
  twoFactorLogout() {
    return post(authentication.twoFactorLogout);
  },
  resendOTP(data) {
    return post(authentication.resendOTP, data);
  },
  twoFactorLogoutAll() {
    return post(authentication.twoFactorLogoutAll);
  },
});
