import Blank from "@/layouts/blank.vue";
import { vehicleLabel } from "@/utils/labels/assetManagementLabels";
import vehicleInsuranceRoutes from "./vehicleInsurance.js";
import vehicleServiceRoutes from "./vehicleService.js";
import vehicleConsumableRoutes from "./vehicleConsumable.js";

const vehicleRoutes = [
  {
    path: `${vehicleLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${vehicleLabel.value}-list`,
        component: () => import("@/pages/assets/vehicle/VehicleList.vue"),
      },
      {
        path: "details/:id",
        name: `${vehicleLabel.value}-details`,
        component: () => import("@/pages/assets/vehicle/VehicleDetail.vue"),
      },
      {
        path: "add/",
        name: `${vehicleLabel.value}-add`,
        component: () => import("@/pages/assets/vehicle/VehicleAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${vehicleLabel.value}-edit`,
        component: () => import("@/pages/assets/vehicle/VehicleEdit.vue"),
      },
      ...vehicleInsuranceRoutes,
      ...vehicleServiceRoutes,
      ...vehicleConsumableRoutes,
    ],
  },
];

export default vehicleRoutes;
