<template>
  <Dialog v-model="openProfileDialog" title="Profile" width="700">
    <template #body>
      <v-row no-gutters class="px-6 pt-4 pb-2">
        <v-col cols="3" class="pt-4">
          <ProfileUpload
            v-model="profileImage"
            @UploadProfile="updateProfile"
            imageHeight="140px"
          />
        </v-col>
        <v-col cols="1"> </v-col>
        <v-col cols="8">
          <v-form v-model="isValid" @submit.prevent="">
            <v-row class="ma-0 py-0">
              <v-col cols="12">
                <InputField
                  name="username"
                  :isRequired="true"
                  label="Username"
                  :error-messages="formErrors.username"
                  v-model="userDetails.username"
                  @input="delete formErrors.username"
                ></InputField>
              </v-col>
              <v-col cols="6">
                <InputField
                  name="first_name"
                  :isRequired="true"
                  label="First Name"
                  :fieldRules="[
                    (v) =>
                      !/[^a-zA-Z0-9 ]/.test(v) ||
                      `Special characters are not allowed`,
                  ]"
                  :error-messages="formErrors.first_name"
                  v-model="userDetails.first_name"
                  @input="delete formErrors.first_name"
                ></InputField>
              </v-col>
              <v-col cols="6" class="pt-4">
                <InputField
                  label="Last Name"
                  name="last_name"
                  :isRequired="true"
                  :fieldRules="[
                    (v) =>
                      !/[^a-zA-Z0-9 ]/.test(v) ||
                      `Special characters are not allowed`,
                  ]"
                  :error-messages="formErrors.last_name"
                  v-model="userDetails.last_name"
                  @input="delete formErrors.last_name"
                ></InputField>
              </v-col>
              <v-col cols="12" class="pt-4">
                <InputField
                  min="0"
                  step="01"
                  name="contact_number"
                  label="Contact number"
                  :isRequired="true"
                  :fieldRules="
                    userDetails.contact_number
                      ? [
                          (v) =>
                            (v && contactValidation(v)) ||
                            'Contact number should be of 10 digits!',
                        ]
                      : []
                  "
                  :error-messages="formErrors.contact_number"
                  v-model="userDetails.contact_number"
                  @input="delete formErrors.contact_number"
                ></InputField>
              </v-col>
              <v-col cols="12" class="pt-4">
                <InputField
                  outlined
                  label="Email"
                  name="email"
                  :isRequired="true"
                  :fieldRules="
                    userDetails.email
                      ? [(v) => (v && emailValidation(v)) || 'Invalid email id']
                      : []
                  "
                  dense
                  :error-messages="formErrors.email"
                  v-model="userDetails.email"
                  @input="delete formErrors.email"
                ></InputField>
              </v-col>
              <v-col cols="12" class="d-flex justify-end">
                <v-btn
                  type="submit"
                  @click.prevent="submit"
                  class="primary text-uppercase"
                  :disabled="!isValid"
                  depressed
                >
                  submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import { useUserInfoStore } from "@/stores/userInfo";

import {
  generateFormData,
  contactValidation,
  emailValidation,
} from "@/utils/functions";
import ProfileUpload from "@/components/FormBaseComponents/ProfileUpload.vue";
import Dialog from "../General/Dialog.vue";

export default {
  components: { InputField, ProfileUpload, Dialog },
  props: {
    value: Boolean,
  },
  setup() {
    const userInfoStore = useUserInfoStore();

    return { userInfoStore };
  },
  data() {
    return {
      contactValidation,
      emailValidation,
      isValid: true,
      nonFieldError: [],
      formErrors: {},
      userDetails: {},
      profileImage: null,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getProfileObj();
      }
    },
  },
  computed: {
    openProfileDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    updateProfile(image, isChangeable = false) {
      if (this.userDetails.update_profile_image || isChangeable) {
        this.userDetails.update_profile_image = true;
        if (image) {
          this.userDetails.profile_image = image;
        } else {
          delete this.userDetails.profile_image;
        }
      } else {
        this.userDetails.update_profile_image = false;
        delete this.userDetails.profile_image;
      }
    },
    getProfileObj() {
      this.$bus.$emit("showLoader", true);
      return this.$api.authentication
        .getProfileInfo()
        .then((res) => {
          this.userDetails = res.data;
          this.profileImage = res?.data?.profile_image;
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    closeForm() {
      this.openProfileDialog = false;
      (this.profileImage = null), (this.userDetails = {});
    },
    async submit() {
      let data = { ...this.userDetails };
      if (data && !data.update_profile_image) {
        delete data.profile_image;
      }
      const formData = await generateFormData(data);
      this.$bus.$emit("showLoader", true);
      this.$api.authentication
        .getProfileUpdate(formData)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.openProfileDialog = false;
          this.$bus.$emit("showToastMessage", {
            message: "User Details Updated Successfully",
            color: "success",
          });
          if (
            this.userInfoStore.userInfo.username !== this.userDetails.username
          ) {
            this.$emit("logout");
          }
          this.userInfoStore.setUserInfo(true);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          if ("non_field_errors" in err.data) {
            this.nonFieldError = err.non_field_errors;
          }
          this.formErrors = err.data;
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update user details!",
            color: "error",
          });
        });
    },
  },
};
</script>
