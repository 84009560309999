import Blank from "@/layouts/blank.vue";
import { warehouseLabel } from "@/utils/labels/configurationLabels";

const wareHouseRoutes = [
  {
    path: `${warehouseLabel.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${warehouseLabel.value}-list`,
        component: () =>
          import("@/pages/configuration/warehouse/WareHouseList.vue"),
        default: true,
      },
      {
        path: "add/",
        name: `${warehouseLabel.value}-add`,
        component: () =>
          import("@/pages/configuration/warehouse/WareHouseAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${warehouseLabel.value}-edit`,
        component: () =>
          import("@/pages/configuration/warehouse/WareHouseEdit.vue"),
      },
    ],
  },
];

export default wareHouseRoutes;
