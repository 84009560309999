<template>
  <v-textarea
    autocomplete="new-password"
    hide-details="auto"
    outlined
    v-bind="$attrs"
    v-on="$listeners"
    :clearable="clearable"
    :class="designClass"
    :value="value"
    :dense="dense"
    :rules="[
      ...(isRequired ? [(val) => !!val || `${label} is Required`] : []),
      ...fieldRules,
    ]"
    :label="`${label}${isRequired ? ' *' : ''}`"
    class="Border-Remove"
    background-color="background"
  />
</template>

<script>
export default {
  name: "TextareaTag",
  props: {
    value: {
      type: [String, Array, Date],
      default: "",
    },
    dense: {
      default: true,
    },
    label: {
      required: true,
    },
    isRequired: {
      default: false,
    },
    designClass: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    fieldRules: {
      default: () => [],
    },
  },
};
</script>