import Blank from "@/layouts/blank.vue";
import { customerLabel } from "@/utils/labels/customerManagementLabels";

const customerRoutes = [
  {
    path: `${customerLabel.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${customerLabel.value}-list`,
        component: () =>
          import("@/pages/customerManagement/customer/CustomerList.vue"),
        default: true,
      },
      {
        path: "details/:id",
        name: `${customerLabel.value}-details`,
        component: () => import("@/pages/customerManagement/customer/CustomerDetail.vue"),
      },
    ],
  },
];

export default customerRoutes;
