import Blank from "@/layouts/blank.vue";
import { fieldLabel } from "@/utils/labels/configurationLabels";

const fieldRoutes = [
  {
    path: `${fieldLabel.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${fieldLabel.value}-list`,
        component: () => import("@/pages/configuration/field/FieldList.vue"),
        default: true,
      },
    ],
  },
];

export default fieldRoutes;
