import Blank from "@/layouts/blank.vue";
import { roleLabel } from "@/utils/labels/userLabels";

const rolesRoutes = [
  {
    path: `${roleLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${roleLabel.value}-list`,
        component: () => import("@/pages/user/role/RoleList.vue"),
      },
      {
        path: "add/",
        name: `${roleLabel.value}-add`,
        component: () => import("@/pages/user/role/RoleAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${roleLabel.value}-edit`,
        component: () => import("@/pages/user/role/RoleEdit.vue"),
      },
    ],
  },
];

export default rolesRoutes;
