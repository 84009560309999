import Vue from "vue";
import axios from "axios";
import authHeader from "@/utils/authHeader";
import router from "@/router/index";
// import { handleResponse } from "@/utils/apiHandlers";
import { authentication } from "@/utils/urlConstants";
let config = {
  baseURL: import.meta.env.VITE_BASE_URL || "",
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (req) {
    const auth = authHeader();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const protectedRoutes = [
      authentication.login,
      authentication.twoFactorLogin,
      authentication.verifyOTP,
      authentication.resendOTP,
      "/err-server/"
    ];
    req.headers.timezone = timezone;
    if (protectedRoutes.indexOf(req.url) === -1) {
      if (auth) {
        req.headers["Authorization"] = auth;
        return req;
      } else {
        router.push("/login");
        throw new axios.Cancel();
      }
    } else {
      return req;
    }
  },
  function (error) {
    console.log(error);
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    const method = response?.config?.method;
    if (method == "options") {
      return response;
    }
    // if (response?.data?.message) {
    //   Vue.prototype.$bus.$emit("showToastMessage", {
    //     message: response.data.message,
    //     color: "success",
    //   });
    // }

    return response.data;
  },
  function (error) {
    const status = error?.response?.status;
    if (status && status == 401) {
      const path = router?.currentRoute?.path;
      if (path && path != "/login") {
        router.push("/login");
      }
    }
    const data = error?.response?.data;
    const message = data?.message;
    if (message) {
      Vue.prototype.$bus.$emit("showToastMessage", {
        message: message,
        color: "error",
      });
    }
    if (data) {
      return Promise.reject(data);
    }
    return Promise.reject(error.response);
  }
);

export default _axios;
