<template>
  <v-dialog persistent v-model="showConfirmationDialogue" width="500">
    <v-card>
      <v-card-title class="py-1" :class="status"></v-card-title>
      <v-card-text class="pa-3">
        <v-row class="ma-0">
          <v-col cols="6" class="d-flex justify-start align-center">
            <v-icon size="36" :color="status">{{ icon }}</v-icon>
            <p class="text-h6 font-weight-medium ml-2 ma-0 pa-0">
              {{ title }}
            </p>
          </v-col>
          <v-col cols="12" lg="10">
            <!-- <p class="text-subtitle-1 mb-0">{{ text }}</p> -->
            <span class="text-subtitle-1 ma-0 pa-0" v-html="text"> </span>
          </v-col>
          <v-col cols="12" v-if="showRemarks">
            <v-form @submit.prevent.stop ref="confirmationRemarks" v-model="isFormValid">
              <TextareaField :label="remarkTitle" name="confirmation-remarks" v-model="remarks" rows="3" auto-grow
                :isRequired="isRemarkRequired" />
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn v-if="!hideRejectButton" depressed @click="reject">
          {{ rejectButton }}
        </v-btn>
        <v-btn depressed :color="status" @click="confirm" :disabled="showRemarks && !isFormValid">
          {{ confirmButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";
export default {
  components: {
    TextareaField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "mdi-information",
    },
    status: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "Title",
    },
    text: {
      type: String,
      default: "Text",
    },
    confirmButton: {
      type: String,
      default: "Yes",
    },
    rejectButton: {
      type: String,
      default: "No",
    },
    hideRejectButton: {
      default: false,
    },
    showRemarks: {
      type: Boolean,
    },
    isRemarkRequired: {
      type: Boolean,
    },
    remarkTitle: {
      type: String,
      default: "Remarks",
    },
    remarkValue: {
      default: null,
    },
  },
  data() {
    return {
      remarks: null,
      isFormValid: true,
    };
  },
  computed: {
    showConfirmationDialogue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    showConfirmationDialogue(val) {
      if (val) {
        if (this.remarkValue) {
          this.remarks = this.remarkValue;
        }
      } else {
        this.reset();
      }
    },
  },
  methods: {
    reset() {
      const form = this.$refs?.confirmationRemarks;

      if (form) {
        this.remarks = null;
        form.resetValidation();
      }
    },
    confirm() {
      this.$bus.$emit("confirmed", this.remarks);
      this.showConfirmationDialogue = false;
    },
    reject() {
      this.$bus.$emit("rejected");
      this.showConfirmationDialogue = false;
    },
  },
};
</script>