import { trip, version, transporterTrip } from "@/utils/urlConstants";
import { isTransporter } from "@/utils/functions";
import { useUserInfoStore } from "@/stores/userInfo";

export default ({ get, post, put, patch }) => ({
  async getTripList(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      isTransporter()
        ? transporterTrip.base
        : `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}`,
      {
        params: params,
      }
    );
  },
  async getTripOrderList(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      isTransporter()
        ? transporterTrip.base
        : `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}/get_trip_orders/ `
    );
  },
  async getTripLogs(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.logs}`,
      {
        params: params,
      }
    );
  },
  async getTripCompleteObject(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}${trip.tripAdminActionRequired}`
    );
  },
  async tripAdminActionRequired(payload) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return patch(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${payload.id}${trip.tripAdminActionRequired}`,
      payload
    );
  },
  async tripComplete(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return patch(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}${trip.tripComplete}`
    );
  },
  async getTripObject(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}/`
    );
  },
  async getTripRoute(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}${trip.route}`
    );
  },
  async getTripSummary(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${trip.tripSummary}`,
      {
        params: params,
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
      }
    );
  },
  async tripAssetChange(id, data) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return post(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}${trip.tripAssetChange}`,
      data
    );
  },
  async getTripLoadSheetList(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${trip.tripLoadSheetList}`,
      {
        params: params,
        headers: { "Content-type": "application/pdf" },
      }
    );
  },
  async getTripLoadSheetPDF(id, params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}/generate_trip_sheet/`,
      {
        params: params,
        headers: { "Content-type": "application/pdf" },
      }
    );
  },
  async getTripDeliveryNotePDF(id, params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}/generate_delivery_note/`,
      {
        params: params,
        headers: { "Content-type": "application/pdf" },
      }
    );
  },
  async getTripPODSheetPDF(id, params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}/generate_trip_pod_sheet/`,
      {
        params: params,
        headers: { "Content-type": "application/pdf" },
      }
    );
  },
  async getTripDetails(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }

    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}/view/`
    );
  },
  async addTrip(data) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return post(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}`,
      data
    );
  },
  async tripReschedule(id, payload) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return patch(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}/reschedule_trip/`,
      payload
    );
  },
  async tripCancellation(id, payload) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return patch(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}/cancel_trip/`,
      payload
    );
  },
  async addDriverOverTime(data) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return post(
      `${version}/${userInfoStore.$state.userInfo.branchPath}/trip_driver_overtime/`,
      data
    );
  },
  async updateDriverOverTime(data, id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return put(
      `${version}/${userInfoStore.$state.userInfo.branchPath}/trip_driver_overtime/${id}/`,
      data
    );
  },
  async getTripDriverObj(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}/trip_driver_overtime/${id}/`
    );
  },
  async getOverTimeList(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}/trip_driver_overtime/`,
      {
        params: params,
      }
    );
  },
  async getTripDriverList(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}/get_drivers/`
    );
  },
  async updateTrip(id, data) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return put(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}/`,
      data
    );
  },
  acceptedTrip(id) {
    return patch(`${transporterTrip.base}${id}/accept_trip/`);
  },
  rejectedTrip(id) {
    return patch(`${transporterTrip.base}${id}/reject_trip/`);
  },
  tripAssetAllocation(id, data) {
    return patch(
      `${transporterTrip.base}${id}/transporter_asset_allocation/`,
      data
    );
  },
  async getTripLoadSheetExcel(id, params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}${trip.tripLoadSheetExcel}`,
      {
        params: params,
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
      }
    );
  },
  async getTripOvertimeDownload(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}/trip_driver_overtime/download_sheet/`,
      {
        params: params,
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
      }
    );
  },
  async getDriverOverTimeList(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      isTransporter()
        ? transporterTrip.base
        : `${version}/${userInfoStore.$state.userInfo.branchPath}/trip_driver_overtime/`,
      {
        params: params,
      }
    );
  },
  async driverOvertimeRemarks(payload) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return patch(
      `${version}/${userInfoStore.$state.userInfo.branchPath}/trip_driver_overtime/${payload.id}/mark_overtime_as_paid/`,
      payload
    );
  },
  async odomterUpdate(data) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return patch(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${data.trip}${trip.tripOdometer}`,
      data
    );
  },
  async tripAdminActionRequired(payload) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return patch(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${payload.id}${trip.tripAdminActionRequired}`,
      payload
    );
  },
  getTiaRecommendation(params = {}) {
    return get(`${version}/tia_recommendations/`, {
      params: params,
    });
  },
  async getTrackingDetails(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.tripStopLocation}tracking_detail/`,
      {
        params: params,
      }
    );
  },
});
