import Blank from "@/layouts/blank.vue";
import { storageTypeLabel } from "@/utils/labels/configurationLabels";

const storageTypeRoutes = [
  {
    path: `${storageTypeLabel.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${storageTypeLabel.value}-list`,
        component: () =>
          import("@/pages/configuration/storageType/StorageTypeList.vue"),
        default: true,
      },
    ],
  },
];

export default storageTypeRoutes;
