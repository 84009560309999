import Blank from "@/layouts/blank.vue";
import { bidLabel } from "@/utils/labels/bidLabels";

const bidRoutes = [
  {
    path: `${bidLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${bidLabel.value}-list`,
        component: () =>
          import("@/pages/transporterManagement/bid/BidList.vue"),
      },
      {
        path: "details/:id",
        name: `${bidLabel.value}-details`,
        component: () =>
          import("@/pages/transporterManagement/bid/BidDetail.vue"),
      },
      {
        path: "add/",
        name: `${bidLabel.value}-add`,
        component: () => import("@/pages/transporterManagement/bid/BidAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${bidLabel.value}-edit`,
        component: () =>
          import("@/pages/transporterManagement/bid/BidEdit.vue"),
      },
    ],
  },
];

export default bidRoutes;
