
<template>
  <v-overlay :value="showOverlay" color="blue-grey darken-2" z-index="1000">
    <v-row class="ma-0">
      <v-col cols="12" class="d-flex justify-center pa-0">
        <!-- <v-img max-width="250" src="@/assets/images/truck.gif"></v-img> -->
        <!-- <v-progress-circular indeterminate size="64"></v-progress-circular> -->
        <!-- <span class="text-h5">Loading ...</span> -->
      </v-col>
      <v-col cols="12" class="d-flex justify-center pa-0">
        <span class="text-h4 font-weight-medium font-italic">{{ loadingMessage }}</span>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  props: {
    showOverlay: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "Loading...",
    },
  },
};
</script>

<style></style>
