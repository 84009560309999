import { roadBan } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getRoadBanList(params) {
    return get(`${roadBan.base}`, {
      params: params,
    });
  },
  getRoadBanObject(id) {
    return get(`${roadBan.base}${id}/`);
  },
  addRoadBan(data) {
    return post(`${roadBan.base}`, data);
  },
  updateRoadBan(data, id) {
    return put(`${roadBan.base}${id}/`, data);
  },
});
