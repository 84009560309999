import Blank from "@/layouts/blank.vue";
import { reportLabel } from "@/utils/labels/reportLabels";

const reportRoutes = [
  {
    path: `${reportLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${reportLabel.value}-list`,
        component: () => import("@/pages/report/reportList.vue"),
        default: true,
      },
    ],
  },
];

export default reportRoutes;
