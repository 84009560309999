import { vehicle, driver } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  bulkUploadData(module, payload) {
    return post(`${module}bulk_upload/`, payload);
  },
  getSampleSheet(module, params) {
    return get(`${module}bulk_sample_excel/`,  {
      responseType: "blob",
      params: params
    });
  },
  bulkUpdateData(module, payload) {
    return patch(`${module}bulk_update/`, payload);
  },
  getBulkDownload(module, params) {
    return get(`${module}download_sheet/`, {
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
      params: params,
    });
  },
  getNewSampleSheet(module, params) {
    return get(`${module}bulk_sample_excel_v2/`,  {
      responseType: "blob",
      params: params
    });
  },
  newBulkUploadData(module, payload) {
    return post(`${module}bulk_upload_v2/`, payload);
  },
});
