import { role } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  getRoleList(params) {
    return get(`${role.base}`, { params: params });
  },
  getRoleSelectList(params) {
    return get(`${role.select}`, { params: params });
  },
  getRoleObject(id) {
    return get(`${role.base}${id}/`);
  },
  getRoleDetails(id) {
    return get(`${role.base}${id}/view/`);
  },
  addRole(data) {
    return post(`${role.base}`, data);
  },
  updateRole(data, id) {
    return put(`${role.base}${id}/`, data);
  },
});
