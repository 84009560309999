import Blank from "@/layouts/blank.vue";
import { vehicleInsuranceLabel } from "@/utils/labels/assetManagementLabels";

const vehicleInsuranceRoutes = [
  {
    path: `${vehicleInsuranceLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${vehicleInsuranceLabel.value}-list`,
        component: () =>
          import("@/pages/assets/vehicleInsurance/VehicleInsuranceList.vue"),
      },
    ],
  },
];

export default vehicleInsuranceRoutes;
