export const bidLabel = {
  name: "Bid",
  plural: "Bids",
  // Used in routes and navigation
  pathName: "bid",
  value: "bid",
  permissionKey: "transporter_management",
};

export const bidSelectOptionLabel = {
  statusList: [
    {
      label: "Scheduled",
      value: "scheduled",
    },
    {
      label: "Not Submitted",
      value: "not_submitted",
    },
    {
      label: "Rate Submitted",
      value: "rate_submitted",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ],
  statusType: {
    scheduled: "scheduled",
    not_submitted: "not_submitted",
    rate_submitted: "rate_submitted",
    rejected: "rejected",
  },
  statusTypeArray: ["scheduled", "not_submitted", "rate_submitted", "rejected"],
};
