import Blank from "@/layouts/blank.vue";
import { tripPlanningLabel } from "@/utils/labels/tripPlanningLabels";

const tripPlanningRoutes = [
  {
    path: `${tripPlanningLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${tripPlanningLabel.value}-list`,
        component: () =>
          import("@/pages/planTrip/tripPlanning/TripPlanList.vue"),
        default: true,
      },
      {
        path: "details/:id",
        name: `${tripPlanningLabel.value}-details`,
        component: () =>
          import("@/pages/planTrip/tripPlanning/TripPlanDetail.vue"),
        default: true,
      },
    ],
  },
];

export default tripPlanningRoutes;
