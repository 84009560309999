import { user, transporterUser } from "@/utils/urlConstants";
import { isTransporter } from "@/utils/functions";

export default ({ get, post, put }) => ({
  getUserList(params) {
    return get(`${isTransporter() ? transporterUser.base : user.base}`, {
      params: params,
    });
  },
  getUserObject(id) {
    return get(`${isTransporter() ? transporterUser.base : user.base}${id}/`);
  },
  addUser(data) {
    return post(`${isTransporter() ? transporterUser.base : user.base}`, data);
  },
  updateUser(data, id) {
    return put(
      `${isTransporter() ? transporterUser.base : user.base}${id}/`,
      data
    );
  },
});
