import { integrationLog } from "@/utils/urlConstants";
import { integrationTypes } from "@/utils/labels/integrationLogLabels";

export default ({ get, put, patch }) => ({
  getIntegrationLogList(params, endpoint = integrationTypes[0].value) {
    return get(`${integrationLog.base}${endpoint}/`, { params: params });
  },
  getIntegrationPayload(endpoint = integrationTypes[0].value, id) {
    return get(
      `${integrationLog.base}${endpoint}/${id}/${integrationLog.getPayload}/`
    );
  },
  getIntegrationItemObj(id) {
    return get(`${integrationLog.itemBase}${id}/get_payload_form/`);
  },
  getIntegrationCustomerObject(id) {
    return get(`${integrationLog.customerBase}${id}/get_payload_form/`);
  },
  getIntegrationOrderObject(id) {
    return get(`${integrationLog.orderBase}${id}/get_payload_form/`);
  },
  integrationItem(data, id) {
    return put(`${integrationLog.itemBase}${id}/item_log_fixing/`, data);
  },
  integrationCustomer(data, id) {
    return put(`${integrationLog.customerBase}customer_log_fixing/`, data);
  },
  setSuccessCustomerIntegration(id) {
    return patch(`${integrationLog.customerBase}${id}/customer_log_success/`);
  },
  setSuccessOrderIntegration(id) {
    return patch(`${integrationLog.orderBase}${id}/order_log_success/`);
  },
  setSuccessItemIntegration(id) {
    return patch(`${integrationLog.itemBase}${id}/item_log_success/`);
  },
  integrationLogReport(params) {
    return get(`${integrationLog.base}order_logs_failure_report/`, { params: params });
  },
});
