import { vehicleService } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  getVehicleServiceList(params) {
    return get(`${vehicleService.base}`, {
      params: params,
    });
  },
  getVehicleServiceObject(id) {
    return get(`${vehicleService.base}${id}/`);
  },
  addVehicleService(data) {
    return post(`${vehicleService.base}`, data);
  },
  updateVehicleService(data, id) {
    return put(`${vehicleService.base}${id}/`, data);
  },
});
