import { operation, version, trip } from "@/utils/urlConstants";
import { useUserInfoStore } from "@/stores/userInfo";

export default (axios) => ({
  getOperationOrderList(params = {}) {
    return axios.get(`${operation.base}trip_orders/`, {
      params: params,
    });
  },
  getOperationTripList(params = {}) {
    let url =
      params && params.completed_trips ? "completed_trips" : "ongoing_trips";
    return axios.get(`${operation.base}${url}/`, {
      params: params,
    });
  },
  getDownload(module, params) {
    return axios.get(`${module}`, {
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
      params: params,
    });
  },
  getDriverOperationList(params = {}) {
    return axios.get(`${operation.driverOperation}`, {
      params: params,
    });
  },
  getOperationTrip(params) {
    return axios.get(params.trip);
  },
  getTripAnalytics(params = {}) {
    return axios.get(`${operation.base}trip_analytics/`, { params: params });
  },
  getTripStats(params = {}) {
    return axios.get(`${operation.base}trip_stats/`, { params: params });
  },

  getFleetUtilization(params = {}) {
    return axios.get(`${operation.base}fleet_utilization/`, { params: params });
  },

  getStorageUtilization(params = {}) {
    return axios.get(`${operation.base}storage_utilization/`, {
      params: params,
    });
  },

  getReasonIncompleteDelivery(params = {}) {
    return axios.get(`${operation.base}reason_for_unfinished_delivery/`, {
      params: params,
    });
  },
  getOrderStatus(params = {}) {
    return axios.get(`${operation.base}order_stats/`, { params: params });
  },

  getOrderVelocityByHour(params = {}) {
    return axios.get(`${operation.base}`, {
      params: params,
    });
  },

  getTripDelay(params = {}) {
    return axios.get(`${operation.base}`, {
      params: params,
    });
  },
  getMapInfo(params = {}) {
    return axios.get(`${operation.base}map_info/`, {
      params: params,
    });
  },
  getDriverList(params = {}) {
    return axios.get(`${operation.base}`, {
      params: params,
    });
  },
  getTripDriverCounts(params = {}) {
    return axios.get(`${operation.base}get_trip_counts/`, {
      params: params,
    });
  },
  getTripKpi(id) {
    return axios.get(`${operation.base}${id}/get_trip_kpi/`);
  },
  async getChatHistory(id, params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }

    return axios.get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}/trip_chat/`,
      {
        params: params,
      }
    );
  },
  async getChatNotification(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }

    return axios.get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}/alraya_admin_messages_notifications/`
    );
  },
  async sendMessageToDriver(id, payload) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }

    return axios.post(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${trip.base}${id}/trip_chat/`,
      payload
    );
  },
});
