import Blank from "@/layouts/blank.vue";
import { vehicleTypeLabel } from "@/utils/labels/configurationLabels";

const vehicleTypeRoutes = [
  {
    path: `${vehicleTypeLabel.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${vehicleTypeLabel.value}-list`,
        component: () =>
          import("@/pages/configuration/vehicleType/VehicleTypeList.vue"),
        default: true,
      },
    ],
  },
];

export default vehicleTypeRoutes;
