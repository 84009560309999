<template>
  <div>
    <CustomerNavigation />
    <v-main class="background">
      <v-container fluid class="pa-0">
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import CustomerNavigation from "@/pages/orderTracking/utils/CustomerNavigation.vue";

export default {
  name: "default-customer-layout",
  components: {
    CustomerNavigation,
  },
};
</script>
