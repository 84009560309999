import "@/assets/styles/base.scss";

import Vue from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";

import App from "./App.vue";
import router from "./router";

import vuetify from "@/plugins/vuetify";
import api from "@/plugins/api";
import globals from "@/plugins/globals";

import confirmation from "@/plugins/confirmation";
import ws from "@/plugins/ws";
import { formatDate, formatToLocalDate } from "@/plugins/filters";
import globalsConstants from "@/utils/constants";
import Clipboard from 'v-clipboard'

Vue.prototype.$bus = new Vue();
Vue.prototype.$ws = ws;
Vue.prototype.$api = Object.freeze(api);
Vue.prototype.$confirm = confirmation;
Vue.prototype.$globals = globals;
Vue.prototype.$constants = globalsConstants;

Vue.use(Clipboard)
Vue.use(PiniaVuePlugin);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});
Vue.filter("formatDate", formatDate);
Vue.filter("formatToLocalDate", formatToLocalDate);

new Vue({
  router,
  pinia: createPinia(),
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
