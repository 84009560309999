export const userManagementLabel = {
  name: "User Management",
  plural: "User Management",
  pathName: "user-management",
  value: "user-management",
  permissionKey: "user",
};

export const userLabel = {
  name: "User",
  plural: "Users",
  pathName: "user",
  value: "user",
  permissionKey: "user",
};

export const roleLabel = {
  name: "Role",
  plural: "Roles",
  pathName: "role",
  value: "role",
  permissionKey: "user",
};
