import { customerAddressTag } from "@/utils/urlConstants";

export default (axios) => ({
  getCustomerAddressTagList(params) {
    return axios.get(customerAddressTag.base, { params: params });
  },
  addCustomerAddressTag(data) {
    return axios.post(customerAddressTag.base, data);
  },
  deleteCustomerAddressTag(id) {
    return axios.delete(`${customerAddressTag.base}${id}/`);
  },
});
