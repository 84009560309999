<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    :color="color"
    :class="btnClass"
    class="text-none"
  >
    <v-icon v-if="icon" :class="iconClass">{{ icon }}</v-icon>
    <span v-if="text">{{ text }}</span>
  </v-btn>
</template>

<script>
export default {
  emits: ["text"],
  name: "primary-button",
  props: {
    text: {
      type: String,
    },
    color: {
      default: "primary",
    },
    btnClass: {
      default: "text-subtitle-1",
    },
    icon: {
      type: String,
    },
    iconAttr: {
      type: [Array, Object],
      default: () => ({
        size: "20",
      }),
    },
    iconClass: {
      type: String,
      default: "mr-1",
    },
  },
};
</script>
