import { dashboard } from "@/utils/urlConstants";

export default ({ get }) => ({
  async getOperationStatistic(params) {
    return get(`${dashboard.base}statistics/`, { params: params });
  },
  async getTripAndOrderStatistic(params) {
    return get(`${dashboard.base}trip_vs_order/`, { params: params });
  },
  async getInternalExternalTripStatistic(params) {
    return get(`${dashboard.base}internal_vs_external_trips/`, {
      params: params,
    });
  },
  async getTripCostStatistic(params) {
    return get(`${dashboard.base}trip_cost_chart/`, { params: params });
  },
  async getFleetUtilizationStatistic(params) {
    return get(`${dashboard.base}avg_fleet_capacity_utilization_chart/`, {
      params: params,
    });
  },
  async getDriverOverTimeStatistic(params) {
    return get(`${dashboard.base}driver_overtime_chart/`, { params: params });
  },
  async getDriverOverTimeCostStatistic(params) {
    return get(`${dashboard.base}driver_overtime_cost_chart/`, {
      params: params,
    });
  },
});
