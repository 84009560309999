<template>
  <span :class="titleClass ? titleClass : 'text-h5'"> {{ title }} </span>
</template>

<script>
export default {
  props: {
    titleClass: {
      default: null,
    },
    title: {
      required: true,
      type: String,
    },
  },
};
</script>

<style>
</style>