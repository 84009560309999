import Blank from "@/layouts/blank.vue";
import { planningTemplateLabel } from "@/utils/labels/tripPlanningLabels";

const tripPlanningTemplateRoutes = [
  {
    path: `${planningTemplateLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${planningTemplateLabel.value}-list`,
        component: () =>
          import("@/pages/planTrip/planningTemplate/PlanningTemplateList.vue"),
        default: true,
      },
    ],
  },
];

export default tripPlanningTemplateRoutes;
