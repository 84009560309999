import Vue from "vue";
import "vuetify/dist/vuetify.min.css";
import Vuetify from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import colorFormatter from "@/utils/colorFormatter";
import { defaultTheme } from "@/utils/constants";

Vue.use(Vuetify);

function generateColors(color) {
  let i = 1;
  const colorPallet = {
    base: color,
  };
  while (i < 8) {
    colorPallet[`lighten${i}`] = colorFormatter(12.85 * (i / 100), color);
    colorPallet[`darken${i}`] = colorFormatter(12.85 * (i / -100), color);
    i++;
  }
  return colorPallet;
}

export default new Vuetify({
  customVariables: ["@/assets/styles/variables.scss"],
  theme: {
    dark: false,
    themes: {
      // dark: {
      //   primary: generateColors("#17b7d0"),
      //   accent: colors.grey.darken3,
      //   secondary: colors.amber.darken3,
      //   info: colors.teal.lighten1,
      //   warning: colors.amber.base,
      //   error: colors.deepOrange.accent4,
      //   success: colors.green.accent3,
      //   heading: colors.grey.darken1,
      //   subHeading: colors.grey.darken3,
      // },
      light: {
        ...defaultTheme,
        primary: generateColors("#2c4c9c"),
        secondary: generateColors("#3F4254"),
        // accent: generateColors("#FFFFFF"),
        error: generateColors("#FF5252"),
        info: generateColors("#2196F3"),
        success: generateColors("#4CAF50"),
        warning: generateColors("#FB8C00"),
        heading: generateColors("#90A4AE"),
        "sub-heading": generateColors("#90A4AE"),
        "text-color": generateColors("#91939F"),
        background: "#EFF2F5",
        greyLighten2: "#edecec",
        greyLighten1: "#d2d2d2",
      },
    },
  },
});
