import Blank from "@/layouts/blank.vue";
import { paymentMethodLabel } from "@/utils/labels/configurationLabels";

const paymentMethodRoutes = [
  {
    path: `${paymentMethodLabel.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${paymentMethodLabel.value}-list`,
        component: () =>
          import("@/pages/configuration/paymentMethod/PaymentMethodList.vue"),
        default: true,
      },
    ],
  },
];

export default paymentMethodRoutes;
