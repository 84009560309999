import Blank from "@/layouts/blank.vue";
import { orderLabel, returnOrderLabel } from "@/utils/labels/orderLabels";

const orderRoutes = [
  {
    path: `${orderLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${orderLabel.name}-list`,
        component: () => import("@/pages/order/OrderList.vue"),
        default: true,
      },
      {
        path: "details/:id",
        name: `${orderLabel.value}-details`,
        component: () => import("@/pages/order/OrderDetails.vue"),
      },
      {
        path: "add/",
        name: `${orderLabel.value}-add`,
        component: () => import("@/pages/order/OrderAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${orderLabel.value}-edit`,
        component: () => import("@/pages/order/OrderEdit.vue"),
      },
      {
        path: "add-return/",
        name: `${returnOrderLabel.value}-add`,
        component: () => import("@/pages/order/ReturnOrderAdd.vue"),
      },
      {
        path: "edit-return/:id",
        name: `${returnOrderLabel.value}-edit`,
        component: () => import("@/pages/order/ReturnOrderEdit.vue"),
      },
      {
        path: "return-order-assign/",
        name: `return-${returnOrderLabel.value}`,
        component: () => import("@/pages/order/ReturnOrderAssign.vue"),
      },
      {
        path: "bulk-upload/",
        name: "bulk-upload",
        component: () => import("@/pages/order/bulkUpload.vue"),
      },
      {
        path: "new-bulk-upload/",
        name: "new-bulk-upload",
        component: () => import("@/pages/order/bulkUploadV2.vue"),
      },
    ],
  },
];

export default orderRoutes;
