export const tagLabel = {
  name: "Tag",
  plural: "Tags",
  // Used in routes and navigation
  pathName: "tag",
  value: "tag",
  permissionKey: "configuration",
};

export const driverTagLabel = {
  name: "Driver Tag",
  plural: "Driver Tags",
  // Used in services
  apiName: "driverTag",
  apiModelName: "DriverTag",
  keyword: "driver_tag",
  permissionKey: "configuration",
};

export const vehicleTagLabel = {
  name: "Vehicle Tag",
  plural: "Vehicle Tags",
  // Used in services
  apiName: "vehicleTag",
  apiModelName: "VehicleTag",
  keyword: "vehicle_tag",
  permissionKey: "configuration",
};

export const customerAddressTagLabel = {
  name: "CustomerAddress Tag",
  plural: "CustomerAddress Tags",
  // Used in services
  apiName: "customerAddressTag",
  apiModelName: "CustomerAddressTag",
  keyword: "customer_tag",
  permissionKey: "configuration",
};
