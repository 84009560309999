<template>
  <v-row class="ma-0" justify="space-between">
    <v-col cols="12" class="align-self-center">
      <BreadCrumb />
      <!-- <v-badge
          offset-y="15"
          offset-x="0"
          overlap
          :value="totalItems"
          :content="totalItems"
        >
      </v-badge> -->
      <CustomTitle title="Dashboard" v-once />
    </v-col>

    <v-col cols="12" class="pt-5">
      <v-card class="rounded-lg px-3" elevation="0">
        <v-row>
          <v-col cols="8">
            <span class="text-h6"> Operation Statistics </span>
          </v-col>
          <v-col cols="4" class="text-right">
            <span v-if="isMonthlyData">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-left="200"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip class="primary mr-2" v-bind="attrs" v-on="on">
                    <h4
                      style="width: 90px"
                      class="text-center text-caption font-weight-bolder"
                    >
                      <span class="mr-2">
                        {{ selectedYear }} - {{ monthName }}</span
                      >
                      <v-icon color="white">mdi-menu-down</v-icon>
                    </h4>
                  </v-chip>
                </template>
                <v-date-picker
                  class="pt-4"
                  v-model="operationStatisticDate"
                  type="month"
                  @input="getStatisticAPI"
                ></v-date-picker>
              </v-menu>
            </span>

            <v-menu rounded="rounded-lg" offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-chip class="primary" v-bind="attrs" v-on="on">
                  <h4
                    style="width: 80px"
                    class="text-center text-caption font-weight-bolder"
                  >
                    <span class="mr-2">
                      {{ isMonthlyData ? "Monthly" : "Overall" }}</span
                    >
                    <v-icon color="white">mdi-menu-down</v-icon>
                  </h4>
                </v-chip>
              </template>
              <v-list dense class="pa-0">
                <v-list-item
                  style="min-height: 30px !important"
                  @click="getMonthlyStatistic"
                >
                  <v-list-item-title>monthly</v-list-item-title>
                </v-list-item>
                <v-list-item
                  style="min-height: 30px !important"
                  @click="getOverallStatistic"
                >
                  <v-list-item-title>Overall</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" class="pt-5">
      <div class="dashboard-card-border rounded-lg">
        <v-card
          class="rounded-lg dashboard-card-background"
          elevation="0"
          v-if="dashboardStatisticData && dashboardStatisticData.trip_stats"
        >
          <v-card-title class="blue px-5 rounded-lg">
            <div class="w-100">
              <h5 class="white--text d-flex justify-space-between">
                <span>Total {{ labels.tripLabel.plural }}</span>
                <div>
                  <v-chip label class="white primary--text mr-2" small
                    >Short Haul ({{
                      dashboardStatisticData.trip_stats.total_short_haul
                    }})</v-chip
                  >
                  <v-chip label class="white primary--text" small
                    >Long Haul ({{
                      dashboardStatisticData.trip_stats.total_long_haul
                    }})</v-chip
                  >
                </div>
              </h5>
              <div class="d-flex justify-space-between">
                <v-icon color="white">mdi-truck-fast</v-icon>
                <h3
                  class="font-weight-bolder white--text"
                  v-if="dashboardStatisticData.trip_stats.total_trip"
                >
                  {{ dashboardStatisticData.trip_stats.total_trip.value }}
                </h3>
              </div>
            </div>
          </v-card-title>
          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="12" class="pb-0 d-flex justify-space-between">
                <span class="text-subtitle">
                  Internal {{ labels.tripLabel.plural }}
                  <span
                    v-if="
                      dashboardStatisticData.trip_stats.total_internal_trips
                    "
                  >
                    (
                    {{ dashboardStatisticData.trip_stats.total_internal_trips }}
                    )
                  </span>
                </span>
                <DashboardTooltip top>
                  <template #tooltipContext>
                    <v-row class="py-2" style="width: 300px">
                      <v-col cols="12" class="mb-0">
                        <h6 class="text-caption font-weight-bold">
                          In Progress
                        </h6>
                        <!-- <v-divider
                          class="white my-2"
                          style="opacity: 0.5"
                        ></v-divider> -->
                        <h6 class="text-caption">
                          Trips Statuses - Scheduled | Real time
                        </h6>
                      </v-col>
                      <v-col cols="12" class="mb-0">
                        <h6 class="text-caption font-weight-bold">Active</h6>
                        <!-- <v-divider
                          class="white my-2"
                          style="opacity: 0.5"
                        ></v-divider> -->
                        <h6 class="text-caption">
                          Trips Statuses - Active, Pause and Admin Action
                          Required | Real Time
                        </h6>
                      </v-col>
                      <v-col cols="12">
                        <h6 class="text-caption">Completed</h6>
                        <!-- <v-divider class="white my-2" style="opacity: 0.5">
                        </v-divider> -->
                        <h6 class="text-caption">
                          Status : Completed | Real Time
                        </h6>
                      </v-col>
                    </v-row>
                  </template>
                </DashboardTooltip>
              </v-col>
              <v-col cols="4">
                <DashboardCard
                  v-if="dashboardStatisticData.trip_stats.in_progress"
                  :label="`In Progress`"
                  :text="dashboardStatisticData.trip_stats.in_progress.value"
                  icon="mdi-truck-trailer"
                />
              </v-col>
              <v-col cols="4">
                <DashboardCard
                  v-if="dashboardStatisticData.trip_stats.total_active"
                  :label="`Active`"
                  :text="dashboardStatisticData.trip_stats.total_active.value"
                  icon="mdi-truck-fast"
                />
              </v-col>
              <v-col cols="4">
                <DashboardCard
                  v-if="dashboardStatisticData.trip_stats.total_completed_trips"
                  :label="`Completed`"
                  :text="
                    dashboardStatisticData.trip_stats.total_completed_trips
                      .value
                  "
                  icon="mdi-store-marker"
                />
              </v-col>
              <v-col cols="12" class="py-0 d-flex justify-space-between">
                <span class="text-subtitle">
                  External {{ labels.tripLabel.plural }}
                  <span
                    v-if="
                      dashboardStatisticData.trip_stats.total_external_trips
                    "
                  >
                    (
                    {{ dashboardStatisticData.trip_stats.total_external_trips }}
                    )
                  </span>
                </span>
                <DashboardTooltip top>
                  <template #tooltipContext>
                    <v-row class="py-2" style="width: 300px">
                      <v-col cols="12" class="mb-0">
                        <h6 class="text-caption font-weight-bold">
                          In Progress
                        </h6>
                        <!-- <v-divider
                          class="white my-2"
                          style="opacity: 0.5"
                        ></v-divider> -->
                        <h6 class="text-caption">
                          Trips Statuses - Scheduled, Pending, Approved, Declined | Real time
                        </h6>
                      </v-col>
                      <v-col cols="12" class="mb-0">
                        <h6 class="text-caption font-weight-bold">Active</h6>
                        <!-- <v-divider
                          class="white my-2"
                          style="opacity: 0.5"
                        ></v-divider> -->
                        <h6 class="text-caption">
                          Trips Statuses - Active, Pause and Admin Action
                          Required | Real Time
                        </h6>
                      </v-col>
                      <v-col cols="12">
                        <h6 class="text-caption font-weight-bold">Completed</h6>
                        <!-- <v-divider class="white my-2" style="opacity: 0.5">
                        </v-divider> -->
                        <h6 class="text-caption">
                          Status : Completed | Real Time

                        </h6>
                      </v-col>
                    </v-row>
                  </template>
                </DashboardTooltip>
              </v-col>
              <v-col cols="4">
                <DashboardCard
                  v-if="
                    dashboardStatisticData.trip_stats.in_progress_external_trips
                  "
                  :label="`In Progress`"
                  :text="
                    dashboardStatisticData.trip_stats.in_progress_external_trips
                      .value
                  "
                  icon="mdi-truck-trailer"
                />
              </v-col>
              <v-col cols="4">
                <DashboardCard
                  v-if="
                    dashboardStatisticData.trip_stats
                      .total_active_external_trips
                  "
                  :label="`Active`"
                  :text="
                    dashboardStatisticData.trip_stats
                      .total_active_external_trips.value
                  "
                  icon="mdi-truck-fast"
                />
              </v-col>
              <v-col cols="4">
                <DashboardCard
                  v-if="
                    dashboardStatisticData.trip_stats
                      .total_completed_external_trips
                  "
                  :label="`Completed`"
                  :text="
                    dashboardStatisticData.trip_stats
                      .total_completed_external_trips.value
                  "
                  icon="mdi-store-marker"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-col>
    <v-col cols="12" lg="6" class="pt-5">
      <div class="dashboard-card-border rounded-lg">
        <v-card
          class="rounded-lg dashboard-card-background"
          elevation="0"
          v-if="dashboardStatisticData && dashboardStatisticData.order_stats"
        >
          <v-card-title class="blue px-5 rounded-lg">
            <div class="w-100">
              <h5 class="white--text">Total Delivery {{ labels.orderLabel.plural }}</h5>
              <div class="d-flex justify-space-between">
                <v-icon color="white">mdi-archive</v-icon>
                <h3
                  class="font-weight-bolder white--text"
                  v-if="dashboardStatisticData.order_stats.total_delivery_orders"
                >
                  {{ dashboardStatisticData.order_stats.total_delivery_orders.value }}
                </h3>
              </div>
            </div>
          </v-card-title>
          <v-card-text class="pt-4" style="height: 180px">
            <v-row>
              <v-col cols="12" class="pb-0 d-flex justify-space-between">
                <span class="text-subtitle"> </span>
                <DashboardTooltip top>
                  <template #tooltipContext>
                    <v-row class="py-2" style="width: 300px">
                      <v-col cols="12" class="mb-0">
                        <h6 class="text-caption">Pending</h6>
                        <!-- <v-divider
                          class="white my-2"
                          style="opacity: 0.5"
                        ></v-divider> -->
                        <h6 class="text-caption">
                        Order Statuses - Open Transaction, Unassigned | Real time
                        </h6>
                      </v-col>
                      <v-col cols="12" class="mb-0">
                        <h6 class="text-caption">In Progress</h6>
                        <!-- <v-divider
                          class="white my-2"
                          style="opacity: 0.5"
                        ></v-divider> -->
                        <h6 class="text-caption">
                          Order Statuses - Assigned, In Transit | Real time
                        </h6>
                      </v-col>
                      <v-col cols="12">
                        <h6 class="text-caption">Completed</h6>
                        <!-- <v-divider
                          class="white my-2"
                          style="opacity: 0.5"
                        ></v-divider> -->
                        <h6 class="text-caption">
                          Order Statuses - Completed | Real time.
                        </h6>
                      </v-col>
                    </v-row>
                  </template>
                </DashboardTooltip>
              </v-col>
              <v-col cols="4">
                <DashboardCard
                  v-if="dashboardStatisticData.order_stats.total_delivery_pending_orders"
                  :label="`Pending`"
                  :text="
                    dashboardStatisticData.order_stats.total_delivery_pending_orders
                      .value
                  "
                  icon="mdi-archive-alert"
                />
              </v-col>
              <v-col cols="4">
                <DashboardCard
                  v-if="
                    dashboardStatisticData.order_stats.total_delivery_progress_orders
                  "
                  :label="`In Progress`"
                  :text="
                    dashboardStatisticData.order_stats.total_delivery_progress_orders
                      .value
                  "
                  icon="mdi-archive-clock"
                />
              </v-col>
              <v-col cols="4">
                <DashboardCard
                  v-if="
                    dashboardStatisticData.order_stats.total_delivery_completed_orders
                  "
                  :label="`Completed`"
                  :text="
                    dashboardStatisticData.order_stats.total_delivery_completed_orders
                      .value
                  "
                  icon="mdi-archive-check"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <div
        class="py-4"
        v-if="dashboardStatisticData && dashboardStatisticData.asset_stats"
      >
        <v-row>
          <v-col cols="6">
            <div class="dashboard-card-border rounded-lg">
              <DashboardCard
                v-if="dashboardStatisticData.asset_stats.total_kms_travelled"
                :label="` Total Kms Travelled`"
                :text="
                  dashboardStatisticData.asset_stats.total_kms_travelled.value
                "
                icon="mdi-road-variant"
              />
            </div>
          </v-col>
          <v-col cols="6">
            <div class="dashboard-card-border rounded-lg">
              <DashboardCard
                v-if="
                  dashboardStatisticData &&
                  dashboardStatisticData.asset_stats &&
                  dashboardStatisticData.asset_stats.total_fuel_cost
                "
                :label="` Total Fuel Cost`"
                :prependText="`${currencyName}`"
                :text="`${dashboardStatisticData.asset_stats.total_fuel_cost.value}`"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <v-col cols="12" lg="6" class="pt-5">
      <div class="dashboard-card-border rounded-lg">
        <v-card
          class="rounded-lg dashboard-card-background"
          elevation="0"
          v-if="dashboardStatisticData && dashboardStatisticData.order_stats"
        >
          <v-card-title class="blue px-5 rounded-lg">
            <div class="w-100">
              <h5 class="white--text">Total Return {{ labels.orderLabel.plural }}</h5>
              <div class="d-flex justify-space-between">
                <v-icon color="white">mdi-archive</v-icon>
                <h3
                  class="font-weight-bolder white--text"
                  v-if="dashboardStatisticData.order_stats.total_return_orders"
                >
                  {{ dashboardStatisticData.order_stats.total_return_orders.value }}
                </h3>
              </div>
            </div>
          </v-card-title>
          <v-card-text class="pt-4" style="height: 180px">
            <v-row>
              <v-col cols="12" class="pb-0 d-flex justify-space-between">
                <span class="text-subtitle"> </span>
                <DashboardTooltip top>
                  <template #tooltipContext>
                    <v-row class="py-2" style="width: 300px">
                      <v-col cols="12" class="mb-0">
                        <h6 class="text-caption">Pending</h6>
                        <!-- <v-divider
                          class="white my-2"
                          style="opacity: 0.5"
                        ></v-divider> -->
                        <h6 class="text-caption">
                        Order Statuses - Open Transaction, Unassigned | Real time
                        </h6>
                      </v-col>
                      <v-col cols="12" class="mb-0">
                        <h6 class="text-caption">In Progress</h6>
                        <!-- <v-divider
                          class="white my-2"
                          style="opacity: 0.5"
                        ></v-divider> -->
                        <h6 class="text-caption">
                          Order Statuses - Assigned, In Transit | Real time
                        </h6>
                      </v-col>
                      <v-col cols="12">
                        <h6 class="text-caption">Completed</h6>
                        <!-- <v-divider
                          class="white my-2"
                          style="opacity: 0.5"
                        ></v-divider> -->
                        <h6 class="text-caption">
                          Order Statuses - Completed | Real time.
                        </h6>
                      </v-col>
                    </v-row>
                  </template>
                </DashboardTooltip>
              </v-col>
              <v-col cols="4">
                <DashboardCard
                  v-if="dashboardStatisticData.order_stats.total_return_pending_orders"
                  :label="`Pending`"
                  :text="
                    dashboardStatisticData.order_stats.total_return_pending_orders
                      .value
                  "
                  icon="mdi-archive-alert"
                />
              </v-col>
              <v-col cols="4">
                <DashboardCard
                  v-if="
                    dashboardStatisticData.order_stats.total_return_progress_orders
                  "
                  :label="`In Progress`"
                  :text="
                    dashboardStatisticData.order_stats.total_return_progress_orders
                      .value
                  "
                  icon="mdi-archive-clock"
                />
              </v-col>
              <v-col cols="4">
                <DashboardCard
                  v-if="
                    dashboardStatisticData.order_stats.total_return_completed_orders
                  "
                  :label="`Completed`"
                  :text="
                    dashboardStatisticData.order_stats.total_return_completed_orders
                      .value
                  "
                  icon="mdi-archive-check"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-col>

    <v-col cols="12" class="py-5">
      <v-card class="rounded-lg px-3" elevation="0">
        <v-row>
          <v-col cols="8">
            <span class="text-h6"> Operation Charts </span>
          </v-col>
          <v-col cols="4" class="text-right"> </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" xl="6" class="pt-4">
      <v-card class="rounded-lg px-3" elevation="0">
        <v-card-title class="px-0 py-2">
          <v-row>
            <v-col cols="6">
              <h5 class="font-weight-light">
                {{ labels.tripLabel.plural }} And {{ labels.orderLabel.plural }}
              </h5>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <DashboardTooltip top>
                <template #tooltipContext>
                  <v-row class="py-2" style="width: 300px">
                    <v-col cols="12" class="mb-0">
                      <h5 class="text-subtitle-1 font-weight-bolder mb-2">
                        Information
                      </h5>
                      <h6 class="text-caption">
                        An overview of trip and order data on a monthly basis.
                        <br/> Last Updated : 12:00 AM
                      </h6>
                    </v-col>
                  </v-row>
                </template>
              </DashboardTooltip>
              <span class="mr-3"></span>

              <YearPicker
                v-model="tripOrderDate"
                @change="getTripAndOrderStatistic"
                :max="returnToday()"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider class="pa-0 ma-0"></v-divider>
        <v-card-text
          class="pa-4"
          v-if="tripsAndOrderData && Object.keys(tripsAndOrderData).length"
        >
          <BarChart
            :data="tripsAndOrderData"
            :height="120"
            :options="options"
          />
        </v-card-text>
        <v-card-text
          v-else
          class="pa-4 d-flex justify-center align-center"
          style="height: 220px"
        >
          <h6 class="text-caption">No data at the moment.</h6>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" xl="6" class="pt-3">
      <v-card class="rounded-lg px-3" elevation="0">
        <v-card-title class="px-0 py-2">
          <v-row>
            <v-col cols="6">
              <h5 class="font-weight-light">
                Internal {{ labels.tripLabel.name }} And External
                {{ labels.tripLabel.name }} Chart
              </h5>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <DashboardTooltip top>
                <template #tooltipContext>
                  <v-row class="py-2" style="width: 300px">
                    <v-col cols="12" class="mb-0">
                      <h5 class="text-subtitle-1 font-weight-bolder mb-2">
                        Information
                      </h5>
                      <h6 class="text-caption">
                        An overview of Internal trips and external trips data on a monthly basis.
                        <br/> Last Updated : 12:00 AM
                      </h6>
                    </v-col>
                  </v-row>
                </template>
              </DashboardTooltip>
              <span class="mr-3"></span>

              <YearPicker
                v-model="internalExternalDate"
                @change="getInternalExternalTripStatistic"
                :max="returnToday()"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider class="pa-0 ma-0"></v-divider>
        <v-card-text
          class="pa-4"
          v-if="
            internalAndExternalTripData &&
            Object.keys(internalAndExternalTripData).length
          "
        >
          <BarChart
            :data="internalAndExternalTripData"
            :height="120"
            :options="options"
          />
        </v-card-text>
        <v-card-text
          v-else
          class="pa-4 d-flex justify-center align-center"
          style="height: 220px"
        >
          <h6 class="text-caption">No data at the moment.</h6>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" xl="6" class="pt-3">
      <v-card class="rounded-lg px-3" elevation="0">
        <v-card-title class="px-0 py-2">
          <v-row>
            <v-col cols="6">
              <h5 class="font-weight-light">Fleet Operation Cost Chart</h5>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <DashboardTooltip top>
                <template #tooltipContext>
                  <v-row class="py-2" style="width: 300px">
                    <v-col cols="12" class="mb-0">
                      <h5 class="text-subtitle-1 font-weight-bolder mb-2">
                        Information
                      </h5>
                      <h6 class="text-caption">
                        An overview of Fixed Cost and Variable Cost data on a monthly basis.
                        <br/>
                        <br/>
                        <b>Fixed Cost</b> : Vehicle depreciation cost + Insurance + Regular Services + Admin Fee
                        <br/>
                         <br/>
                        <b>Variable Cost</b> : Consumables cost + Emergency Service cost + Fuel Cost
                         <br/>
                        <br/> Last Updated : 12:00 AM
                      </h6>
                    </v-col>
                  </v-row>
                </template>
              </DashboardTooltip>
              <span class="mr-3"></span>

              <YearPicker
                v-model="tripCostDate"
                @change="getTripCostStatistic"
                :max="returnToday()"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider class="pa-0 ma-0"></v-divider>
        <v-card-text
          class="pa-4"
          v-if="tripCostData && Object.keys(tripCostData).length"
        >
          <BarChart :data="tripCostData" :height="120" :options="options" />
        </v-card-text>
        <v-card-text
          v-else
          class="pa-4 d-flex justify-center align-center"
          style="height: 220px"
        >
          <h6 class="text-caption">No data at the moment.</h6>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" xl="6" class="pt-3">
      <v-card class="rounded-lg px-3" elevation="0">
        <v-card-title class="px-0 py-2">
          <v-row>
            <v-col cols="6">
              <h5 class="font-weight-light">
                Avg Fleet Capacity Utilization Chart
              </h5>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <DashboardTooltip top>
                <template #tooltipContext>
                  <v-row class="py-2" style="width: 300px">
                    <v-col cols="12" class="mb-0">
                      <h5 class="text-subtitle-1 font-weight-bolder mb-2">
                        Information
                      </h5>
                      <h6 class="text-caption">
                         An overview of Fleet capacity utilisation data on a monthly basis.
                        <br/> Last Updated : 12:00 AM
                      </h6>
                    </v-col>
                  </v-row>
                </template>
              </DashboardTooltip>
              <span class="mr-3"></span>

              <YearPicker
                v-model="avgFleetDate"
                @change="getFleetUtilizationStatistic"
                :max="returnToday()"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider class="pa-0 ma-0"></v-divider>
        <v-card-text
          class="pa-4"
          v-if="
            avgFleetCapacityUtilizationData &&
            Object.keys(avgFleetCapacityUtilizationData).length
          "
        >
          <LineChart
            :data="avgFleetCapacityUtilizationData"
            :height="120"
            :options="avgFleetCapacityUtilizationOption"
          />
        </v-card-text>
        <v-card-text
          v-else
          class="pa-4 d-flex justify-center align-center"
          style="height: 220px"
        >
          <h6 class="text-caption">No data at the moment.</h6>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" xl="6" class="pt-3">
      <v-card class="rounded-lg px-3" elevation="0">
        <v-card-title class="px-0 py-2">
          <v-row>
            <v-col cols="6">
              <h5 class="font-weight-light">
                {{ labels.driverLabel.name }} Overtime Chart
              </h5>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <DashboardTooltip top>
                <template #tooltipContext>
                  <v-row class="py-2" style="width: 300px">
                    <v-col cols="12" class="mb-0">
                      <h5 class="text-subtitle-1 font-weight-bolder mb-2">
                        Information
                      </h5>
                      <h6 class="text-caption">
                        Driver overtime on a monthly basis.
                        <br/>Only for Completed Trips
                        <br/> Last Updated : 12:00 AM

                      </h6>
                    </v-col>
                  </v-row>
                </template>
              </DashboardTooltip>
              <span class="mr-3"></span>

              <YearPicker
                v-model="driverOverTimeDate"
                @change="getDriverOverTimeStatistic"
                :max="returnToday()"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider class="pa-0 ma-0"></v-divider>
        <v-card-text
          class="pa-4"
          v-if="driverOverTimeData && Object.keys(driverOverTimeData).length"
        >
          <!-- <div class="app-relative">
            <div
              class="dashboard-card-absolute pa-4 d-flex justify-center align-center"
            >
              <h6 class="text-caption">Data display with dummy values</h6>
            </div>
          </div> -->
          <BarChart
            :data="driverOverTimeData"
            :height="120"
            :options="driverOverTimeOption"
          />
        </v-card-text>
        <v-card-text
          v-else
          class="pa-4 d-flex justify-center align-center"
          style="height: 220px"
        >
          <h6 class="text-caption">No data at the moment.</h6>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" xl="6" class="pt-3">
      <v-card class="rounded-lg px-3" elevation="0">
        <v-card-title class="px-0 py-2">
          <v-row>
            <v-col cols="6">
              <h5 class="font-weight-light">
                {{ labels.driverLabel.name }} Overtime Cost Chart
              </h5>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <DashboardTooltip top>
                <template #tooltipContext>
                  <v-row class="py-2" style="width: 300px">
                    <v-col cols="12" class="mb-0">
                      <h5 class="text-subtitle-1 font-weight-bolder mb-2">
                        Information
                      </h5>
                      <h6 class="text-caption">
                        Overtime costs for drivers on a monthly basis.
                        <br/>
                        Last Update : 12:00 AM
                      </h6>
                    </v-col>
                  </v-row>
                </template>
              </DashboardTooltip>
              <span class="mr-3"></span>

              <YearPicker
                v-model="driverOverTimeCostDate"
                @change="getDriverOverTimeCostStatistic"
                :max="returnToday()"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider class="pa-0 ma-0"></v-divider>
        <v-card-text
          class="pa-4"
          v-if="
            driverOverTimeCostData && Object.keys(driverOverTimeCostData).length
          "
        >
          <!-- <div class="app-relative">
            <div
              class="dashboard-card-absolute pa-4 d-flex justify-center align-center"
            >
              <h6 class="text-caption">Data display with dummy values</h6>
            </div>
          </div> -->
          <BarChart
            :data="driverOverTimeCostData"
            :height="120"
            :options="driverOverTimeCastOption"
          />
        </v-card-text>
        <v-card-text
          v-else
          class="pa-4 d-flex justify-center align-center"
          style="height: 220px"
        >
          <h6 class="text-caption">No data at the moment.</h6>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import CustomTitle from "@/components/General/Title.vue";
import BreadCrumb from "@/components/BaseComponents/BreadCrumb.vue";
import BarChart from "@/components/BaseComponents/Charts/BarChart.vue";
import LineChart from "@/components/BaseComponents/Charts/LineChart.vue";
import YearPicker from "@/pages/dashBoard/util/YearPicker.vue";
import { returnToday } from "@/utils/functions";
import { tripLabel } from "@/utils/labels/tripLabels";
import { orderLabel, orderPackageLabel } from "@/utils/labels/orderLabels";
import { driverLabel } from "@/utils/labels/assetManagementLabels";
import DashboardCard from "@/pages/dashBoard/util/DashboardCard.vue";
import { currencyName } from "@/utils/constants";
import DashboardTooltip from "@/pages/dashBoard/util/DashboardTooltip.vue";

export default {
  name: "dashboard-page",
  components: {
    CustomTitle,
    BreadCrumb,
    BarChart,
    LineChart,
    YearPicker,
    DashboardCard,
    DashboardTooltip,
  },
  data() {
    return {
      returnToday,
      currencyName,
      dashboardStatisticData: {},
      tripsAndOrderData: {},
      internalAndExternalTripData: {},
      tripCostData: {},
      driverOverTimeData: {},
      driverOverTimeCostData: {},
      avgFleetCapacityUtilizationData: {},
      isMonthlyData: false,
      labels: { tripLabel, orderLabel, orderPackageLabel, driverLabel },
      options: {
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Months",
              },
            },
          ],
        },
      },
      driverOverTimeOption: {
        responsive: true,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Hours",
              },
              ticks: {
                min: 0,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Months",
              },
            },
          ],
        },
      },
      driverOverTimeCastOption: {
        responsive: true,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Cost",
              },
              ticks: {
                min: 0,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Months",
              },
            },
          ],
        },
      },
      avgFleetCapacityUtilizationOption: {
        responsive: true,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "",
              },
              ticks: {
                min: 0,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Months",
              },
            },
          ],
        },
      },
      monthList: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      menu: false,
      operationStatisticDate: returnToday(),
      tripOrderDate: new Date().getFullYear().toString(),
      internalExternalDate: new Date().getFullYear().toString(),
      tripCostDate: new Date().getFullYear().toString(),
      avgFleetDate: new Date().getFullYear().toString(),
      driverOverTimeDate: new Date().getFullYear().toString(),
      driverOverTimeCostDate: new Date().getFullYear().toString(),
    };
  },
  computed: {
    monthName() {
      return this.monthList[
        parseInt(this.operationStatisticDate.slice(5, 7)) - 1
      ];
    },
    monthNumber() {
      return this.operationStatisticDate.slice(5, 7);
    },
    selectedYear() {
      return this.operationStatisticDate.slice(0, 4);
    },
  },
  methods: {
    getStatisticAPI() {
      this.menu = false;
      this.getOperationStatistic(true);
    },
    getMonthlyStatistic() {
      this.isMonthlyData = true;
      this.getOperationStatistic(true);
    },
    getOverallStatistic() {
      this.isMonthlyData = false;
      this.operationStatisticDate = returnToday();
      this.getOperationStatistic(false);
    },
    getOperationStatistic(isMonthlyDate = false) {
      this.dashboardStatisticData = {};
      this.$bus.$emit("showLoader", true);
      let params = {};
      if (isMonthlyDate) {
        params.date = `${this.selectedYear}-${this.monthNumber}-01`;
      }
      this.$api.dashboard
        .getOperationStatistic(params)
        .then((res) => {
          this.dashboardStatisticData = { ...res.data };
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getTripAndOrderStatistic() {
      this.tripsAndOrderData = {};
      this.$api.dashboard
        .getTripAndOrderStatistic({ date: `${this.tripOrderDate}-01-01` })
        .then((res) => {
          this.tripsAndOrderData = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getInternalExternalTripStatistic() {
      this.internalAndExternalTripData = {};
      this.$api.dashboard
        .getInternalExternalTripStatistic({
          date: `${this.internalExternalDate}-01-01`,
        })
        .then((res) => {
          this.internalAndExternalTripData = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTripCostStatistic() {
      this.tripCostData = {};
      this.$api.dashboard
        .getTripCostStatistic({ date: `${this.tripCostDate}-01-01` })
        .then((res) => {
          this.tripCostData = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFleetUtilizationStatistic() {
      this.avgFleetCapacityUtilizationData = {};
      this.$api.dashboard
        .getFleetUtilizationStatistic({ date: `${this.avgFleetDate}-01-01` })
        .then((res) => {
          this.avgFleetCapacityUtilizationData = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getDriverOverTimeStatistic() {
      this.driverOverTimeData = {};
      this.$api.dashboard
        .getDriverOverTimeStatistic({
          date: `${this.driverOverTimeDate}-01-01`,
        })
        .then((res) => {
          this.driverOverTimeData = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getDriverOverTimeCostStatistic() {
      this.driverOverTimeCostData = {};
      this.$api.dashboard
        .getDriverOverTimeCostStatistic({
          date: `${this.driverOverTimeCostDate}-01-01`,
        })
        .then((res) => {
          this.driverOverTimeCostData = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.getTripAndOrderStatistic();
    this.getInternalExternalTripStatistic();
    this.getTripCostStatistic();
    this.getFleetUtilizationStatistic();
    this.getDriverOverTimeStatistic();
    this.getDriverOverTimeCostStatistic();
    this.getOperationStatistic(false);
  },
};
</script>

<style>
.dashboard-card-absolute {
  height: 240px;
  position: absolute;
  z-index: 1;
  background-color: #ffffffb3;
  width: 100%;
  top: 0;
}
.DashBoard-Image {
  width: 90%;
  height: calc(100vh - 160px);
}
.dashboard-card-border {
  border: 1px solid #2c4c9c !important ;
}
.dashboard-card-background {
  background-color: #eff2f5 !important;
}
</style>