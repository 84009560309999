import { customer, customerAddress } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getCustomerList(params) {
    return get(`${customer.base}`, { params: params });
  },
  getCustomerLogs(params) {
    return get(`${customer.logs}`, { params: params });
  },
  getCustomerSelectList(params = {}) {
    return get(`${customer.base}select/`, {
      params: params,
    });
  },
  getAddressSelectList(params = {}) {
    return get(`${customerAddress.base}select/`, {
      params: params,
    });
  },
  getCustomerObject(id) {
    return get(`${customer.base}${id}/`);
  },
  getCustomerDetails(id) {
    return get(`${customer.base}${id}/view/`);
  },
  addCustomer(data) {
    return post(`${customer.base}`, data);
  },
  updateCustomer(data, id) {
    return put(`${customer.base}${id}/`, data);
  },
});
