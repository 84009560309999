import { tag } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getTagList(params = {}) {
    return get(`${tag.base}`, {
      params: params,
    });
  },
  getTagSelectList(params = {}) {
    return get(`${tag.base}select/`, {
      params: params,
    });
  },
  getTagObject(id) {
    return get(`${tag.base}${id}/`);
  },
  getAssignedAssetsList(id) {
    return get(`${tag.base}${id}/${tag.assignedAssets}/`);
  },
  addTag(data) {
    return post(`${tag.base}`, data);
  },
  updateTag(data, id) {
    return put(`${tag.base}${id}/`, data);
  },
});
