import { driver, transporterDriver } from "@/utils/urlConstants";
import { isTransporter } from "@/utils/functions";

export default ({ get, post, put, patch }) => ({
  getDriverList(params) {
    return get(`${isTransporter() ? transporterDriver.base : driver.base}`, {
      params: params,
    });
  },
  getDriverActiveTrip(id) {
    return get(
      `${
        isTransporter() ? transporterDriver.base : driver.base
      }${id}/current_trip/`
    );
  },
  getDriverLogs(params) {
    return get(`${transporterDriver.logs}`, { params: params });
  },
  getDriverSelectList(params = {}) {
    return get(
      `${isTransporter() ? transporterDriver.base : driver.base}select/`,
      {
        params: params,
      }
    );
  },
  getDriverObject(id) {
    return get(
      `${isTransporter() ? transporterDriver.base : driver.base}${id}/`
    );
  },
  getDriverDetails(id) {
    return get(
      `${isTransporter() ? transporterDriver.base : driver.base}${id}/view/`
    );
  },
  addDriver(data) {
    return post(
      `${isTransporter() ? transporterDriver.base : driver.base}`,
      data
    );
  },
  updateDriver(data, id) {
    return put(
      `${isTransporter() ? transporterDriver.base : driver.base}${id}/`,
      data
    );
  },
  changeDriverStatus(data, id) {
    return patch(
      `${
        isTransporter() ? transporterDriver.base : driver.base
      }${id}/update_driver_status/`,
      data
    );
  },
  getPassCodeGenerate(id) {
    return put(
      `${
        isTransporter() ? transporterDriver.base : driver.base
      }${id}/regenerate_pass_code/`
    );
  },
});
