import Blank from "@/layouts/blank.vue";
import { userManagementLabel } from "@/utils/labels/userLabels.js";
import rolesRoutes from "./role";
import userRoutes from "./user";

const userManagementRoutes = [
  {
    path: `${userManagementLabel.pathName}/`,
    component: Blank,
    children: [...rolesRoutes, ...userRoutes],
    name: userManagementLabel.value,
  },
];

export default userManagementRoutes;
