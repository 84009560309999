export const orderLabel = {
  name: "Order",
  plural: "Orders",
  // Used in routes and navigation
  pathName: "order",
  value: "order",
  permissionKey: "order",
};

export const returnOrderLabel = {
  name: "Return Order",
  plural: "Return Orders",
  // Used in routes and navigation
  pathName: "return-order",
  value: "return-order",
  permissionKey: "order",
};

export const orderPackageLabel = {
  name: "Package",
  plural: "Packages",
};

export const orderSelectOptionLabel = {
  statusList: [
    {
      label: "Assigned",
      value: "assigned",
    },
    {
      label: "Open Transaction",
      value: "open_transaction",
    },
    {
      label: "Unassigned",
      value: "unassigned",
    },
    {
      label: "Not Delivered",
      value: "not_delivered",
    },
    {
      label: "Partially Delivered",
      value: "partially_delivered",
    },
    {
      label: "Enroute",
      value: "enroute",
    },
    {
      label: "In Transit",
      value: "intransit",
    },
    {
      label: "Completed",
      value: "completed",
    },
  ],
  packageStatusList: [
    {
      label: "Assigned",
      value: "assigned",
    },
    {
      label: "Open Transaction",
      value: "open_transaction",
    },
    {
      label: "Unassigned",
      value: "unassigned",
    },
    {
      label: "Not Delivered",
      value: "not_delivered",
    },
    {
      label: "Partially Delivered",
      value: "partially_delivered",
    },
    {
      label: "Enroute",
      value: "enroute",
    },
    {
      label: "In Transit",
      value: "intransit",
    },
    {
      label: "Delivered",
      value: "delivered",
    },
  ],
  statusType: {
    assigned: "assigned",
    unassigned: "unassigned",
    cancelled: "cancelled",
    openTransaction: "open_transaction",
    notDelivered: "not_delivered",
    partialDelivered: "partially_delivered",
    enroute: "enroute",
    inTransit: "intransit",
    completed: "completed",
    delivered: "delivered",
  },
  warningTypeList: [
    {
      value: "no_coordinates",
      label: "No Coordinates",
    },
    {
      value: "outside_serviceable_area",
      label: "Outside Serviceable Area",
    },
    // {
    //   value: "failed_during_bulk_upload",
    //   label: "Failed During Bulk Upload",
    // },
    // {
    //   value: "split_recommended",
    //   label: "Split Recommended",
    // },
  ],
  orderTypeList: [
    {
      label: "Delivery",
      value: "delivery",
    },
    {
      label: "Return",
      value: "return",
    },
  ],
};
