import Blank from "@/layouts/blank.vue";
import { routeLabel } from "@/utils/labels/laneManagementLabels";

const laneRoutes = [
  {
    path: `${routeLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${routeLabel.value}-list`,
        component: () =>
          import("@/pages/laneManagement/laneRoute/LaneRouteList.vue"),
      },
      {
        path: "details/:id",
        name: `${routeLabel.value}-details`,
        component: () =>
          import("@/pages/laneManagement/laneRoute/LaneRouteDetail.vue"),
      },
      {
        path: "add/",
        name: `${routeLabel.value}-add`,
        component: () =>
          import("@/pages/laneManagement/laneRoute/LaneRouteAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${routeLabel.value}-edit`,
        component: () =>
          import("@/pages/laneManagement/laneRoute/LaneRouteEdit.vue"),
      },
    ],
  },
];

export default laneRoutes;
