<template>
  <v-dialog
    v-model="showCommonDialog"
    :max-width="width"
    :width="dialogWidth"
    v-bind="$attrs"
    v-on="$listeners"
    scrollable
    :persistent="persistent"
    :fullscreen="fullscreen"
  >
    <v-card :color="color" rounded="lg">
      <v-card-title class="white--text py-1 px-4">
        <h5>{{ title }}</h5>
        <v-spacer />
        <v-btn icon depressed @click="closeDialog">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        class="pa-3 white overflow-y-auto"
        :class="bodyClass"
        :style="`height: ${contentHeight}`"
      >
        <slot name="body" />
      </v-card-text>

      <v-divider v-if="$slots.actions" />
      <v-card-actions
        v-if="$slots.actions"
        class="d-flex justify-end white px-4 py-2"
      >
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-component",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Title",
    },
    color: {
      type: String,
      default: "primary",
    },
    width: {
      type: String,
      default: "50%",
    },
    dialogWidth: {
      type: String,
    },
    contentHeight: {
      default: "",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    bodyClass: {
      default: "",
    },
  },
  computed: {
    showCommonDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
      this.showCommonDialog = false
    }
  }
};
</script>
