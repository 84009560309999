// import CryptoJS from "crypto-js";
import { useUserInfoStore } from "@/stores/userInfo";
import { organizationType } from "@/utils/labels/organizationTypeLabels";
import { filterName } from "@/utils/constants";

export function createGoogleMapsDirectionLink(
  originCoords,
  destinationCoords,
  waypoints = ""
) {
  if (waypoints) {
    return `https://www.google.com/maps/dir/?api=1&origin=${originCoords}&destination=${destinationCoords}&waypoints=${waypoints}`;
  } else {
    return `https://www.google.com/maps/dir/?api=1&origin=${originCoords}&destination=${destinationCoords}`;
  }
}

export function getFileNameFromUrl(url) {
  let chunk = url.split("/");
  return chunk[chunk.length - 1];
}

export function getUtcDate(date) {
  var old_date = new Date(date);
  var date_utc = Date.UTC(
    old_date.getUTCFullYear(),
    old_date.getUTCMonth(),
    old_date.getUTCDate(),
    old_date.getUTCHours(),
    old_date.getUTCMinutes(),
    old_date.getUTCSeconds()
  );
  return new Date(date_utc);
}
/**
 * Replace char from keys of array of object
 * @param {Array<Object>} array Array of Object to format keys in.
 * @param {string} charToReplace char to replace from keys
 * @param {string} charToReplaceWith char to replace with
 * @param {null | 'uppercase' | 'lowercase'} changeCaseTo change case
 * @returns [{ key: value }]
 */
export async function formatDataKeys(
  array,
  charToReplace,
  charToReplaceWith,
  changeCaseTo = null
) {
  let regex = new RegExp(charToReplace, "g");
  return array.map((data) => {
    for (let key in data) {
      let __key = key.replace(regex, charToReplaceWith);
      if (changeCaseTo == "lowercase") {
        __key = __key.toLowerCase();
      }
      if (changeCaseTo == "uppercase") {
        __key = __key.toUpperCase();
      }
      data[__key] = data[key];
      delete data[key];
    }
    return data;
  });
}

export function isPrimitive(val) {
  return !(typeof val == "object" || typeof val == "function");
}

export function isAuthenticated() {
  return !!localStorage.getItem("user");
}

export function hasRouteAccess(route) {
  let user_type = localStorage.getItem("user_type");

  if (!user_type) {
    return false;
  }

  const r = route.name.split("-");
  return r && r.length && r[1] == user_type.toLowerCase();
}

export function getOrganizationType() {
  return localStorage.getItem("organization_type");
}

export function getPermissions(module) {
  let permissions = localStorage.getItem("permissions");
  if (permissions) {
    permissions = JSON.parse(permissions);
    return permissions[module] ? permissions[module] : null;
  } else {
    return null;
  }
}

export function getFilters() {
  let filters = localStorage.getItem(filterName);
  if (filters) {
    return JSON.parse(filters);
  }
  return {};
}

export function getModuleFilter(key) {
  let filters = getFilters();
  if (key && filters && filters[key]) {
    return filters[key];
  }
  return {};
}

export function getAllUserPreferences() {
  let preferences = localStorage.getItem("userPreferences");
  if (preferences) {
    return JSON.parse(preferences);
  }
  return {};
}

export function getUserPreferences(key) {
  let preferences = getAllUserPreferences();
  if (preferences && preferences[key]) {
    return preferences[key];
  }
  return [];
}

export function getUserProfile() {
  const userData = localStorage.getItem("userProfile");
  if (userData) {
    return JSON.parse(userData);
  }
  return userData;
}

export function getOrganizationName() {
  let organizationName = "organization";
  const userData = getUserProfile();
  const userType = localStorage.getItem("user_type");
  if (
    userData &&
    ["Organization User", "Organization Admin"].indexOf(userType) > -1
  ) {
    organizationName = userData.organization_name;
  }
  return organizationName;
}

export function generateChartOptions(
  chartType,
  chartId = "vue-chart-1",
  labelsArray = []
) {
  let obj = {};
  if (chartType == "stackedBar" || chartType == "bar") {
    obj = {
      chart: {
        type: chartType == "stackedBar" ? "bar" : chartType,
        height: "450",
        stacked: chartType == "stackedBar" ? true : false,
        id: chartId,
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: labelsArray,
        fill: {
          opacity: 1,
        },
      },
    };
  }
  if (chartType == "pie") {
    obj = {
      chart: {
        type: chartType,
      },
      labels: labelsArray,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
  }

  return obj;
}

export function generateChartSeries(seriesNames, dataToPopulate) {
  let arrayToReturn = [];
  if (
    Array.isArray(seriesNames) &&
    Array.isArray(dataToPopulate) &&
    dataToPopulate.every((val) => Array.isArray(val))
  ) {
    seriesNames.map((val, index) => {
      arrayToReturn[index] = {
        name: val,
        data: dataToPopulate[index],
      };
    });
  } else {
    arrayToReturn = [
      {
        name: seriesNames,
        data: dataToPopulate,
      },
    ];
  }
  return arrayToReturn;
}

// export function encryptText(text) {
//   let key = process.env.VUE_APP_CIPHER_KEY;
//   key = CryptoJS.enc.Utf8.parse(key);
//   let encrypted = CryptoJS.AES.encrypt(text, key, {
//     mode: CryptoJS.mode.ECB,
//   });
//   encrypted = encrypted.toString();
//   return encrypted;
// }

export function generateBlobData(data, filename) {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: "application/octet-stream" })
  );
  download(url, filename);
}
export function generateBlobFileData(data, filename) {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: "application/pdf" })
  );
  download(url, filename);
}

export function download(url, filename) {
  var a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", filename);
  a.click();
}
export function getFileName(URL) {
  return URL ? URL.slice(URL.lastIndexOf("/") + 1, URL.length) : URL;
}
export async function responseBlob(URL) {
  const result = await fetch(URL).then((res) => res.blob());
  return result;
}

export async function downloadFile(fileURL, fileName) {
  let a = document.createElement("a");
  const blob = await responseBlob(fileURL);
  const objUrl = window.URL.createObjectURL(blob);

  a.href = objUrl;
  a.download = getFileName(fileURL);
  a.click();

  window.URL.revokeObjectURL(objUrl);
}

export async function generateFormData(data) {
  if (!data || Array.isArray(data)) {
    throw new Error(
      "generateFormData function can be only used to generate form data from JSON"
    );
  }
  const formData = new FormData();
  const keys = Object.keys(data);
  while (keys.length) {
    const key = keys.pop();
    let value = data[key];

    if (value && typeof value == "object" && !(value instanceof File)) {
      value = JSON.stringify(value);
    }

    formData.append(key, value);
  }
  return formData;
}

export function isValidImageFile(name) {
  const lastDot = name.lastIndexOf(".");
  const ext = name.substring(lastDot);
  let allowed = /(\.jpg|\.jpeg|\.png|\.svg)$/i;
  return allowed.exec(ext);
}

export function verifyGridCellValue(params) {
  return params.value ? params.value : "-";
}

export function verifyValue(value) {
  return value || "-";
}

export function getCurrentDateTime() {
  let currentDateTime = new Date();

  let currentDateString = currentDateTime.toISOString().slice(0, 10);

  let currentMinutes = currentDateTime.getMinutes();
  let currentTimeString = null;

  if (currentMinutes.toString().length == 1) {
    currentTimeString = currentDateTime.getHours() + ":" + `0${currentMinutes}`;
  } else {
    currentTimeString = currentDateTime.getHours() + ":" + currentMinutes;
  }

  let currentDateTimeString = `${currentDateString} ${currentTimeString}`;
  return currentDateTimeString;
}

export async function getDateDifferenceInDays(dateOne, dateTwo) {
  const _dateOne = new Date(dateOne);
  const _dateTwo = new Date(dateTwo);

  let differenceInTime = _dateTwo.getTime() - _dateOne.getTime();

  return differenceInTime / (1000 * 3600 * 24);
}

export function setBreadCrumbTitle(object = {}) {
  let titles = JSON.parse(localStorage.getItem("breadCrumbTitles"));

  if (!titles) titles = {};

  titles = { ...titles, ...object };

  localStorage.setItem("breadCrumbTitles", JSON.stringify(titles));
}

export function removeBreadCrumbTitle(key) {
  let titles = JSON.parse(localStorage.getItem("breadCrumbTitles"));

  if (titles) {
    delete titles[key];
  }

  localStorage.setItem("breadCrumbTitles", JSON.stringify(titles));
}

export function clearBreadCrumbTitles() {
  localStorage.removeItem("breadCrumbTitles");
}

export function addMinutesToDateTime(date, minutes) {
  date.setMinutes(date.getMinutes() + minutes);
  return date;
}

export function formatDateTime(date) {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  return `${yyyy}-${mm}-${dd}T${hours}:${minutes}`;
}

export function convertToHoursMinutes(minutes) {
  let d = Math.floor(minutes / 1440);
  let h = Math.floor((minutes - d * 1440) / 60);
  let m = Math.round(minutes % 60);

  if (d > 0) {
    return d + " Day(s), " + h + " Hour(s), " + m + " Minute(s)";
  }
  return h + " Hour(s), " + m + " Minute(s)";
}

export function convertToBlobAndDownload(data, filename) {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: "application/octetstream" })
  );
  var a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export function downloadBlob(blob, fileName, fileType) {
  let url = window.URL.createObjectURL(blob);

  let a = document.createElement("a");

  a.href = url;
  a.download = `${fileName}${fileType}`;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function getDateTime(_d) {
  let _ = new Date(_d),
    date = _.getDate() < 10 ? "0" + _.getDate() : _.getDate(),
    month = _.getMonth() + 1,
    year = _.getFullYear();

  month = month < 10 ? "0" + month : month;

  return [year, month, date].join("-");
}

export function returnToday(addDays = 0, date = null) {
  let today = date ? new Date(date) : new Date();
  today.setDate(today.getDate() + addDays);
  return getDateTime(today);
}

export function emailValidation(string) {
  let validRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validRegex.test(string);
}
export function contactValidation(val) {
  return val.length == 10;
}

export function getBranchName() {
  const userInfoStore = useUserInfoStore();
  if (!userInfoStore.$state?.userInfo?.branchPath) {
    userInfoStore.setUserInfo();
  }
  return userInfoStore.$state.userInfo.branchPath;
}
export function isTransporter() {
  return (
    localStorage.getItem("organization_type") === organizationType.TRANSPORTER
  );
}
export function organizationName() {
  const userInfoStore = useUserInfoStore();
  if (
    userInfoStore &&
    userInfoStore.$state &&
    userInfoStore.$state.userInfo &&
    userInfoStore.$state.userInfo.organization_type
  ) {
    return userInfoStore.$state.userInfo.organization_type;
  }
}

export function formatDate(value, withTime) {
  if (value) {
    const _date = new Date(value);
    if (_date) {
      let date = _date.getDate();
      let month = _date.getMonth() + 1;
      let year = _date.getFullYear();
      date = date < 10 ? `0${date}` : `${date}`;
      month = month < 10 ? `0${month}` : `${month}`;
      let fullDate = [date, month, year].join("/");
      let time = [];
      if (withTime) {
        let hour = _date.getHours();
        let minute = _date.getMinutes();
        hour = hour < 10 ? `0${hour}` : `${hour}`;
        minute = minute < 10 ? `0${minute}` : `${minute}`;
        time = [hour, minute].join(":");
        fullDate = [fullDate, time].join(" ");
      }
      return fullDate;
    } else {
      return "";
    }
  }
  return "";
}

export function tripStatusColor(status, needTextColor = false) {
  status = status && typeof status == "string" && status.toLowerCase();
  switch (status) {
    case "scheduled":
      return needTextColor ? "trip_scheduled white--text" : "trip_scheduled";
    case "approved":
      return needTextColor ? "trip_approved white--text" : "trip_approved";
    case "active":
      return needTextColor ? "trip_active white--text" : "trip_active";
    case "pending":
      return needTextColor ? "trip_pending white--text" : "trip_pending";
    case "paused":
      return needTextColor ? "trip_paused white--text" : "trip_paused";
    case "admin_action_required":
      return needTextColor
        ? "admin_action_required white--text"
        : "admin_action_required";
    case "completed":
      return needTextColor ? "trip_completed white--text" : "trip_completed";
  }
}

export function getOrderStatusColor(status, needTextColor = false) {
  if (status && typeof status == "string") {
    status = status.toLowerCase();
  }
  switch (status) {
    case "open_transaction":
      return needTextColor ? "" : "open_transaction";
    case "unassigned":
      return needTextColor ? "unassigned white--text" : "unassigned";
    case "assigned":
      return needTextColor ? "assigned white--text" : "assigned";
    case "pickedup":
      return needTextColor ? "pickedup white--text" : "pickedup";
    case "intransit":
      return needTextColor ? "intransit white--text" : "intransit";
    case "picked up":
    case "picked_up":
      return needTextColor ? "pickedup white--text" : "pickedup";
    case "not_pickedup":
      return needTextColor ? "not_pickedup text_color--text" : "not_pickedup";
    case "partially_delivered":
      return needTextColor
        ? "partially_delivered white--text"
        : "partially_delivered";
    case "partially delivered":
      return needTextColor
        ? "partially_delivered white--text"
        : "partially_delivered";
    case "enroute":
      return needTextColor ? "enroute text_color--text" : "enroute";
    case "out for delivery":
    case "out_for_delivery":
      return needTextColor ? "enroute text_color--text" : "enroute";
    case "on_hold":
      return needTextColor ? "on_hold white--text" : "on_hold";
    case "returned":
      return needTextColor ? "returned white--text" : "returned";
    case "cancelled":
      return needTextColor ? "cancelled white--text" : "cancelled";
    case "delivered":
      return needTextColor ? "delivered white--text" : "delivered";
    case "completed":
      return needTextColor ? "completed white--text" : "completed";
    case "not_delivered":
      return needTextColor ? "not_delivered white--text" : "not_delivered";
    case "not delivered":
      return needTextColor ? "not_delivered white--text" : "not_delivered";
  }
}

export function orderTextChange(orderStatus) {
  switch (orderStatus) {
    case "open_transaction":
      return "Open Transaction";
    case "unassigned":
      return "Unassigned";
    case "assigned":
      return "Assigned";
    case "pickedup":
      return "Pickedup";
    case "intransit":
      return "In Transit";
    case "partially_delivered":
      return "Partially Delivered";
    case "out for delivery":
      return "Out For delivery";
    case "enroute":
      return "Out For delivery";
    case "on_hold":
      return "On Hold";
    case "returned":
      return "Returned";
    case "cancelled":
      return "Cancelled";
    case "delivered":
      return "Delivered";
    case "Completed":
      return "Completed";
    case "not delivered":
      return "Not delivered";
    case "not_delivered":
      return "Not delivered";
    case "not_pickedup":
      return "Not Pickedup";
  }
}
