import Blank from "@/layouts/blank.vue";
import { userLabel } from "@/utils/labels/userLabels";

const userRoutes = [
  {
    path: `${userLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${userLabel.value}-list`,
        component: () => import("@/pages/user/UserList.vue"),
      },
      {
        path: "add/",
        name: `${userLabel.value}-add`,
        component: () => import("@/pages/user/UserAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${userLabel.value}-edit`,
        component: () => import("@/pages/user/UserEdit.vue"),
      },
    ],
  },
];

export default userRoutes;
