import { notification } from "@/utils/urlConstants";

export default ({ get, patch }) => ({
  getNotificationList(params) {
    return get(`${notification.base}`, { params: params });
  },
  notificationMarkAsRead(id) {
    return patch(`${notification.base}${notification.markAsSeen}/`, {
      id: id,
    });
  },
  getNotificationCount(params) {
    return get(`${notification.base}${notification.notificationCount}/`,{ params: params });
  },
});
