import { order, version } from "@/utils/urlConstants";
import { useUserInfoStore } from "@/stores/userInfo";

export default ({ get, post, delete: deleteMethod, put, patch }) => ({
  async getOrderList(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }

    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.base}`,
      {
        params: params,
      }
    );
  },
  async getOrderMovementType(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }

    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.getMovementType}`,
      {
        params: params,
      }
    );
  },
  async getOrderDetails(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.base}${id}/view/`
    );
  },

  async getOrderObject(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.base}${id}/`
    );
  },
  async getOrderItemList(params = {}) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.orderItem}`,
      { params }
    );
  },
  async getOrderPackageList(params = {}) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.orderPackage}`,
      { params }
    );
  },
  async getOrderLogList(params = {}) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.orderLogs}`,
      { params }
    );
  },
  async addOrder(data) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return post(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.base}`,
      data
    );
  },
  async removeOrderReturnFromTrips(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return patch(
      `${version}/${userInfoStore.$state.userInfo.branchPath}/return_order/${id}/unassigned_return_order_from_trip/`
    );
  },
  async orderCancellation(id, payload) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return patch(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.base}${id}/cancel_order/`,
      payload
    );
  },
  async updateOrder(data, id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return put(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.base}${id}/`,
      data
    );
  },
  async orderSchedule(id, data) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return patch(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.base}${id}/reschedule_order/`,
      data
    );
  },
  async addReturnOrder(data) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return post(
      `${version}/${userInfoStore.$state.userInfo.branchPath}/return_order/`,
      data
    );
  },
  async updateReturnOrder(data, id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return put(
      `${version}/${userInfoStore.$state.userInfo.branchPath}/return_order/${id}/`,
      data
    );
  },
  async getOrderAttachmentList(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }

    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.orderAttachment}`,
      {
        params: params,
      }
    );
  },
  async getEmptyPackagesList(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }

    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.emptyPackages}`,
      {
        params: params,
      }
    );
  },
  async uploadOrderAttachment(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }

    return post(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.orderAttachment}`,
      params
    );
  },
  async addEmptyPackage(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }

    return post(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.emptyPackages}`,
      params
    );
  },
  async deleteOrderAttachment(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }

    return deleteMethod(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.orderAttachment}${id}/`
    );
  },
  async deleteEmptyPackage(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }

    return deleteMethod(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.emptyPackages}${id}/`
    );
  },
  async getOrderPODSheetPDF(id, params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.base}${id}/generate_trip_pod_sheet/`,
      {
        params: params,
        headers: { "Content-type": "application/pdf" },
      }
    );
  },
  async getOrderStopLocations(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }

    return get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}/trip_stop_locations/`,
      {
        params: params,
      }
    );
  },
  async orderAssign(data) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return post(
      `${version}/${userInfoStore.$state.userInfo.branchPath}/return-order-trip-assignment/`,
      data
    );
  },
  async changeOrderExecutionDate(data) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return post(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${order.base}bulk_execution_date_change/`,
      data
    );
  },
  getPackageType() {
    return get(`${version}/package_type/`);
  },
  validateBulkDeliveryOrders(payload) {
    return post(`${version}/alraya/order/validate_bulk_upload_file/`, payload);
  },
  validateBulkReturnOrders(payload) {
    return post(
      `${version}/alraya/return_order/validate_bulk_upload_file/`,
      payload
    );
  },
  validateNewBulkDeliveryOrders(payload) {
    return post(`${version}/alraya/order/validate_bulk_upload_v2_file/`, payload);
  },
  validateNewBulkReturnOrders(payload) {
    return post(
      `${version}/alraya/return_order/validate_bulk_upload_v2_file/`,
      payload
    );
  },
});
