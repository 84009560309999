<template>
  <v-app-bar
    v-bind="$attrs"
    :clipped-left="true"
    :clipped-right="true"
    fixed
    app
    elevation="2"
    color="white"
  >
    <v-app-bar-nav-icon @click.stop="$emit('toggleDrawer')" />

    <!-- Brand Logo -->
    <img
      class="Brand-Logo"
      alt="Brand Logo"
      :src="require('@/assets/images/logo.png')"
    />
    <v-spacer></v-spacer>

    <!-- Change Language -->
    <!-- <MultiSelect :items="language" v-model="currentLanguage" label="Language" item-text="label" item-value="value"
      class="notranslate max-width-150-px" @change="googleTranslateElementInit" /> -->

    <div class="d-flex justify-start notranslate max-width-250-px mr-2">
      <div v-for="(lang, index) in language" :key="index">
        <v-btn
          text
          color="primary"
          :class="
            lang.value === currentLanguage ? 'primary lighten-7' : 'white'
          "
          class="mr-1"
          @click="googleTranslateElementInit(lang.value, true)"
        >
          {{ lang.label }}
        </v-btn>
      </div>
    </div>
    <div class="d-flex justify-start notranslate max-width-250-px mr-2">
      <MessageNotification />
    </div>
    <div class="d-flex justify-start notranslate max-width-250-px mr-2">
      <BellNotification />
    </div>

    <v-menu
      v-model="profileMenu"
      offset-y
      v-if="userInfoStore.userInfo"
      content-class="Profile-Menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <table v-bind="attrs" v-on="on" class="ml-6">
          <tbody>
            <tr>
              <td class="text-right" style="min-width: 120px">
                <div>
                  <span>
                    {{ userInfoStore.userInfo.username }}
                  </span>
                </div>
                <Tooltip top tooltipText="Branch">
                  <template #activator>
                    <v-chip
                      x-small
                      class="primary lighten-1 white--text"
                      v-if="
                        userInfoStore &&
                        userInfoStore.userInfo &&
                        userInfoStore.organizationType !==
                          organizationType.TRANSPORTER &&
                        userInfoStore.userInfo.preferred_branch
                      "
                    >
                      {{ userInfoStore.userInfo.preferred_branch }}
                    </v-chip>
                  </template>
                </Tooltip>
                <Tooltip top :tooltipText="`Organization Type`">
                  <template #activator>
                    <v-chip
                      x-small
                      class="primary lighten-1 white--text text-capitalize"
                      v-if="
                        userInfoStore.organizationType ===
                        organizationType.TRANSPORTER
                      "
                    >
                      {{ userInfoStore.userInfo.organization_type }}
                    </v-chip>
                  </template>
                </Tooltip>
              </td>
              <td colspan="2" class="pr-2">
                <v-img
                  class="mx-5 profile-image rounded-lg"
                  style="height: 40px !important; width: 40px !important"
                  contain
                  :src="
                    userInfoStore.userInfo &&
                    userInfoStore.userInfo.profile_image
                      ? userInfoStore.userInfo.profile_image
                      : userProfile
                  "
                  alt="User"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <v-card elevation="0" width="auto" min-width="300">
        <v-row no-gutters>
          <v-col cols="4" class="text-center py-3 px-0">
            <v-img
              class="mx-5 profile-image rounded-lg"
              style="height: 40px !important; width: 40px !important"
              contain
              :src="
                userInfoStore.userInfo && userInfoStore.userInfo.profile_image
                  ? userInfoStore.userInfo.profile_image
                  : userProfile
              "
              alt="User"
            />
          </v-col>
          <v-col cols="8" class="d-flex flex-column justify-center">
            <h5
              class="text_color--text text-capitalize d-flex justify-space-between"
            >
              <span>
                {{ userInfoStore.userInfo.first_name }}
                {{ userInfoStore.userInfo.last_name }}
              </span>
              <span class="pr-6">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="secondary"
                      small
                      @click="openProfileDialog = true"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span class="text-capitalize text-no-wrap">Edit Profile</span>
                </v-tooltip>
              </span>
            </h5>
            <h5
              class="text-body-2 font-weight-light"
              v-if="userInfoStore.userInfo.email"
            >
              {{ userInfoStore.userInfo.email }}
            </h5>
          </v-col>
        </v-row>

        <v-card-text class="px-8 pt-0">
          <div class="d-flex my-2" v-if="userInfoStore.userInfo.username">
            <TooltipWithIcon
              :text="userInfoStore.userInfo.username"
              label="username"
              icon="mdi-account"
            />
          </div>
          <div class="d-flex my-2" v-if="userInfoStore.userInfo.contact_number">
            <TooltipWithIcon
              :text="userInfoStore.userInfo.contact_number"
              label="contact number"
              icon="mdi-phone"
            />
          </div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-text class="pa-0">
          <v-list>
            <!-- <v-list-item
              :to="{ name: 'company-configuration' }"
              v-if="
                profileDetail &&
                profileDetail.role &&
                ['account_admin', 'normal_user'].indexOf(profileDetail.role) >
                  -1
              "
            >
              <v-icon class="pr-3">mdi-cog</v-icon>
              <span class="pl-2">Settings</span>
            </v-list-item> -->
            <!-- <v-list-item>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-icon class="pr-3">mdi-warehouse</v-icon>
                    <span class="pl-2">{{
                      selectedBranch ? selectedBranch.name : "Not Selected"
                    }}</span>
                  </div>
                </template>
                <span>Change Branch</span>
              </v-tooltip>
            </v-list-item> -->
            <v-list-item @click="$bus.$emit('showChangePasswordForm')">
              <v-icon class="pr-3">mdi-key</v-icon>
              <span class="pl-2 text-capitalize">Change Password</span>
            </v-list-item>
            <v-list-item @click="logout">
              <v-icon class="pr-3">mdi-logout</v-icon>
              <span class="pl-2 text-capitalize">logout</span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
    <ChangePasswordDialog @logout="clearData" />
    <ProfileForm v-model="openProfileDialog" @logout="logout" />
    <!-- <OrganizationSettingsDialog />
    <TransporterSettingsDialog /> -->
  </v-app-bar>
</template>

<script>
import { defineAsyncComponent } from "vue";
import Tooltip from "@/components/General/Tooltip.vue";
import BellNotification from "@/components/General/BellNotification.vue";
import MessageNotification from "@/components/General/MessageNotification.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import { getCookie, setCookie, eraseCookie } from "@/utils/cookieHandlers";
import { mapState } from "pinia";
import { useUserInfoStore } from "@/stores/userInfo";

const ChangePasswordDialog = defineAsyncComponent(() =>
  import("@/components/DialogForms/ChangePasswordDialog.vue")
);
import userProfile from "@/assets/images/profile.jpg";
import TooltipWithIcon from "@/components/BaseComponents/TooltipWithIcon.vue";
import ProfileForm from "@/components/BaseComponents/ProfileForm.vue";
import { organizationType } from "@/utils/labels/organizationTypeLabels";

export default {
  name: "app-bar",
  components: {
    MultiSelect,
    BellNotification,
    MessageNotification,
    ChangePasswordDialog,
    // OrganizationSettingsDialog,
    // TransporterSettingsDialog,
    Tooltip,
    TooltipWithIcon,
    ProfileForm,
  },
  props: {
    companyNotifications: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const userInfoStore = useUserInfoStore();
    return { userInfoStore };
  },
  data() {
    return {
      organizationType,
      userProfile: userProfile,
      title: "Fero",
      currentProfile: {},
      ltPermission: null,
      profileMenu: false,
      language: [
        {
          label: "English",
          value: "en",
        },
        {
          label: "عربي",
          value: "ar",
        },
      ],
      currentLanguage: "en",
      openProfileDialog: false,
    };
  },
  computed: {
    openNotifications: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentWSUserType() {
      if (this.currentProfile) {
        if (
          ["Organization Admin", "Organization User"].indexOf(
            this.currentProfile.user_type
          ) > -1
        ) {
          return "organization";
        } else {
          return "transporter";
        }
      } else {
        return null;
      }
    },
    logoUrl() {
      return (
        this.currentProfile.organization_logo || "@/assets/images/logo.svg"
      );
    },
    currentLanguageObj() {
      return this.language.find((obj) => obj.value == this.currentLanguage);
    },
  },
  methods: {
    updateSelectedBranch(id) {
      const payload = {
        branch: id,
      };
      this.$api.authentication.updateSelectedBranch(payload).then(async () => {
        this.profileMenu = false;
        await this.getProfileInfo();
        location.reload();
      });
    },

    onClose() {
      this.$ws && this.$ws.removeInstance();
    },
    onMessage(ev) {
      if (ev && ev.data) {
        const wsMessage =
          typeof ev.data == typeof "" ? JSON.parse(ev.data) : ev.data;
        this.handleReceivedMessage(wsMessage);
      }
    },
    handleReceivedMessage(wsMessage) {
      const wsMessageType = wsMessage.type;
      if (wsMessageType) {
        if (wsMessageType == "user_notification_received") {
          this.$bus.$emit("userNotificationReceived", wsMessage.data);
        }
        if (
          [
            "chat_history",
            "organization_message_received",
            "transporter_message_received",
            "driver_message_received",
          ].indexOf(wsMessageType) > -1
        ) {
          this.$bus.$emit("chatMessageReceived", wsMessage);
        }
      }
    },
    onOpen() {
      this.$bus.$emit("socketConnected");
    },
    clearData() {
      localStorage.removeItem("user");
      // this.userInfoStore.setUserInfo(null);
      this.$router.push("/login");
      this.$emit("closeWebSocket");
    },
    logout() {
      const config = {
        status: "error",
        title: "Logout",
        text: "Are you sure you want to Logout?",
      };
      this.$confirm(config).then(() => {
        this.$api.authentication
          .twoFactorLogout()
          .then((res) => {
            localStorage.removeItem("user");
            // this.userInfoStore.setUserInfo(null);
            this.$emit("closeWebSocket");
            this.$bus.$emit("showLoader", false);
            this.$router.push({ name: "login" });
          })
          .catch(() => {
            this.$bus.$emit("showLoader", false);
          });
      });
    },
    async setupWS() {
      if (!this.$ws.instance) {
        await this.$ws.createInstance(this.currentWSUserType);
        this.$ws.instance.onopen = this.onOpen;
      } else {
        this.onOpen();
      }
      this.$ws.instance.onmessage = this.onMessage;
    },
    getProfileInfo() {
      this.$bus.$emit("showLoader", true);
      return this.$api.authentication
        .getProfileInfo()
        .then((res) => {
          if (res && res.data) {
            this.userInfoStore.setUserInfo(res.data);
            // this.setupWS();
          }
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    checkCurrentLang() {
      this.currentLanguage = getCookie("currentLanguage")
        ? getCookie("currentLanguage")
        : "en";
    },
    googleTranslateElementInit(language = null, reload = false) {
      if (!language && this.currentLanguage === language) {
        return;
      }
      this.currentLanguage = language;

      if (getCookie("googtrans")) {
        eraseCookie("googtrans");
      }

      const previousLangugage = getCookie("currentLanguage") ?? "en";

      setCookie(
        "googtrans",
        `/${previousLangugage}/${this.currentLanguage}`,
        import.meta.env.VITE_APP_COOKIEDOMAIN
      );

      setCookie("currentLanguage", language);

      new google.translate.TranslateElement(
        {
          pageLanguage: this.currentLanguage,
          layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: true,
          includedLanguages: "ar,en",
        },
        "app"
      );

      setCookie(
        "currentLanguage",
        this.currentLanguage,
        import.meta.env.VITE_APP_COOKIEDOMAIN
      );
      if (reload || previousLangugage != this.currentLanguage) {
        location.reload();
      }
    },

    loadLang() {
      // setCookie("googtrans", `/en`, 30);
      // setCookie("currentLanguage", this.currentLanguage, 30);
      new google.translate.TranslateElement(
        {
          pageLanguage: "en",
          layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: true,
          includedLanguages: "ar,en",
        },
        "app"
      );
    },
  },
  mounted() {
    // this.$bus.$on("changeBranch", this.updateSelectedBranch);
    // this.$bus.$on("reloadProfileData", this.getProfileInfo);

    const interval = setInterval(() => {
      if (window && window.google && window.google.translate) {
        clearInterval(interval);
        this.checkCurrentLang();
        this.loadLang();
      }
    }, 300);
  },
  // async created() {
  //   if (!this.userInfoStore.$state.userInfo) await this.getProfileInfo();
  // }
};
</script>

<style scoped>
.Profile-Menu {
  margin-top: 10px;
}

.Branch-Height-Class {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
