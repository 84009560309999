import { project } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getProjectList(params = {}) {
    return get(`${project.base}`, {
      params: params,
    });
  },
  getProjectSelectList(params = {}) {
    return get(`${project.base}select/`, {
      params: params,
    })
  },
  getProjectObject(id) {
    return get(`${project.base}${id}/`);
  },
  addProject(data) {
    return post(`${project.base}`, data);
  },
  updateProject(data, id) {
    return put(`${project.base}${id}/`, data);
  },
});
