import Blank from "@/layouts/blank.vue";
import { assetManagementLabel } from "@/utils/labels/assetManagementLabels.js";
import driverRoutes from "./driver.js";
import vehicleRoutes from "./vehicle.js";

const assetsRoutes = [
  {
    path: `${assetManagementLabel.pathName}/`,
    component: Blank,
    children: [...driverRoutes, ...vehicleRoutes],
    name: assetManagementLabel.value,
  },
];

export default assetsRoutes;
