import Blank from "@/layouts/blank.vue";
import { roadBanLabel } from "@/utils/labels/configurationLabels";

const roadBanRoutes = [
  {
    path: `${roadBanLabel.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${roadBanLabel.value}-list`,
        component: () =>
          import("@/pages/configuration/roadBan/RoadBanList.vue"),
        default: true,
      },
      {
        path: "add/",
        name: `${roadBanLabel.value}-add`,
        component: () => import("@/pages/configuration/roadBan/RoadBanAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${roadBanLabel.value}-edit`,
        component: () =>
          import("@/pages/configuration/roadBan/RoadBanEdit.vue"),
      },
    ],
  },
];

export default roadBanRoutes;
