import Blank from "@/layouts/blank.vue";
import { transporterUserLabel } from "@/utils/labels/transporterManagementLabels";

const transporterUserRoutes = [
  {
    path: `${transporterUserLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${transporterUserLabel.value}-list`,
        component: () =>
          import(
            "@/pages/transporterManagement/transporterUser/TransporterUserList.vue"
          ),
      },
      {
        path: "add/",
        name: `${transporterUserLabel.value}-add`,
        component: () =>
          import(
            "@/pages/transporterManagement/transporterUser/TransporterUserAdd.vue"
          ),
      },
      {
        path: "edit/:id",
        name: `${transporterUserLabel.value}-edit`,
        component: () =>
          import(
            "@/pages/transporterManagement/transporterUser/TransporterUserEdit.vue"
          ),
      },
    ],
  },
];

export default transporterUserRoutes;
