import authHeader from "@/utils/authHeader";

const wsObject = {
  createInstance(url, protocols = []) {
    this.instance = new WebSocket(
      `${import.meta.env.VUE_APP_BASE_WS_URL}${url}/?token=${
        !!authHeader() ? authHeader().replace("token ", "") : ""
      }`,
      protocols
    );
    return;
  },

  removeInstance() {
    if (this.instance) {
      this.instance.close();
      this.instance = null;
    }
  },
};

export default wsObject;
