export const laneManagementLabel = {
  name: "Lane Management",
  plural: "Lane Management",
  pathName: "lane-management",
  value: "lane-management",
  permissionKey: "lane_management",
};
export const cityLabel = {
  name: "City",
  plural: "Cities",
  pathName: "city",
  value: "city",
  permissionKey: "lane_management",
};

export const routeLabel = {
  name: "Route",
  plural: "Routes",
  pathName: "routes",
  value: "routes",
  permissionKey: "lane_management",
};

export const zoneLabel = {
  name: "Zone",
  plural: "Zones",
  pathName: "zone",
  value: "zone",
  permissionKey: "lane_management",
};
