import Blank from "@/layouts/blank.vue";
import customerRoutes from "./customer";
import customerAddressRoutes from "./customerAddress";
import { customerManagementLabel } from "@/utils/labels/customerManagementLabels";

const customerManagementRoutes = [
  {
    path: `${customerManagementLabel.pathName}/`,
    component: Blank,
    children: [...customerRoutes, ...customerAddressRoutes],
    name: customerManagementLabel.value,
  },
];

export default customerManagementRoutes;
