export const integrationLogLabel = {
  name: "Integration Log",
  plural: "Integration logs",
  // Used in routes and navigation
  pathName: "integrationlog",
  value: "integration-log",
  permissionKey: "integration_log",
};

export const integrationTypes = [
  {
    name: "Order",
    value: "order_logs",
    headerKey: "order",
  },
  {
    name: "Customer",
    value: "customer_logs",
    headerKey: "customer",
  },
  {
    name: "Item",
    value: "item_logs",
    headerKey: "item",
  },
];

export const integrationTypeObj = {
  order: "order",
  customer: "customer",
  item: "item",
};
