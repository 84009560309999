import { tripPlan } from "@/utils/urlConstants";

export default (axios) => ({
  getPlannedTripList(params) {
    return axios.get(`${tripPlan.base}`, { params: params });
  },
  createTripPlanObject(data) {
    return axios.post(`${tripPlan.base}`, data);
  },
  getRetryTripPlan(id) {
    return axios.patch(`${tripPlan.base}${id}/retry_plan/`);
  },
  deleteTripPlan(tripId) {
    return axios.delete(`${tripPlan.base}${tripId}/`);
  },
  getTripPlanInfo(id) {
    return axios.get(`${tripPlan.base}${id}/summary/`);
  },
  getTripPlanDetails(id) {
    return axios.get(`${tripPlan.base}${id}/view/`);
  },
  getTripPlanOrderList(params = {}) {
    return axios.get(`${tripPlan.base}get_planned_packages/`, {
      params: params,
    });
  },
  getTripPlanOrderCount(params = {}) {
    return axios.get(`${tripPlan.base}get_planned_orders_count/`, {
      params: params,
    });
  },
  getTripPlanDriverList(params = {}) {
    return axios.get(`${tripPlan.base}get_planned_drivers/`, {
      params: params,
    });
  },
  getUnplannedOrderListList(params) {
    return axios.get(`${tripPlan.unplannedOrder}`, { params: params });
  },
  getUnplannedDriverList(params) {
    return axios.get(`${tripPlan.tripPlanDriver}`, { params: params });
  },
  getUnplannedVehicleList(params) {
    return axios.get(`${tripPlan.tripPlanVehicle}`, { params: params });
  },
  getPlanningTripList(params) {
    return axios.get(`${tripPlan.tripPlanList}`, { params: params });
  },
  getAllOrderData(plan_id) {
    return axios.get(`${tripPlan.base}${plan_id}/package_coordinates/`);
  },
  createSingleTrip(tripId, payload) {
    return axios.post(
      `${tripPlan.tripPlanList}${tripId}/create_trip/`,
      payload
    );
  },
  deletePlannedTrip(tripId) {
    return axios.delete(`${tripPlan.tripPlanList}${tripId}/`);
  },
  getBulkTripDownload(module, params) {
    return axios.get(`${module}`, {
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
      params: params,
    });
  },

  getTripPlanReportDownload(module, params) {
    return axios.get(`${module}trip_plan_level/`, {
//      headers: {
//        Accept:
//          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//      },
      responseType: "blob",
      params: params,
    });
  },

});




