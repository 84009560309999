export const transporterManagementLabel = {
  name: "Transporter Management",
  plural: "Transporter Management",
  pathName: "transporter-management",
  value: "transporter-management",
  permissionKey: "transporter_management",
};

export const transporterLabel = {
  name: "Transporter",
  plural: "Transporters",
  pathName: "transporter",
  value: "transporter",
  permissionKey: "transporter_management",
};

export const transporterRateLabel = {
  name: "Rate",
  plural: "Rates",
  pathName: "transporter-rate",
  value: "transporter-rate",
  permissionKey: "transporter_management",
};

export const rateLabel = {
  name: "Rate",
  plural: "Rates",
  pathName: "rate",
  value: "rate",
  permissionKey: "transporter_management",
};

export const transporterUserLabel = {
  name: "Transporter User",
  plural: "Transporter Users",
  pathName: "transporter-user",
  value: "transporter-user",
  permissionKey: "transporter_management",
};
