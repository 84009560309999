import { tripPlanning, tripPlan, version } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  getTripPlanningOptions(params) {
    return get(`${tripPlanning.options}`, { params: params });
  },
  getTripPlanningList(params) {
    return get(`${tripPlanning.base}`, { params: params });
  },
  deleteTripPlanningObject(planId) {
    // return delete(`${tripPlanning.base}${planId}/`)
  },
  getPlanTripRouteData(trip_id) {
    return get(
      `${tripPlanning.tripPlannedRoute}${trip_id}/planned_trip_route/`
    );
  },
  updateTripPlanningObject(id, payload) {
    return put(`${tripPlanning.base}${id}/`, payload);
  },
  partiallyUpdateTripPlanningObject(id, payload) {
    return patch(`${tripPlanning.base}${id}/`, payload);
  },
  // getTripPlanOrderList(params = {}) {
  //   return get(`${tripPlanning.base}planning_orders/`, { params: params });
  // },
  // getTripPlanDriverList(params = {}) {
  //   return get(`${tripPlanning.base}planning_drivers/`, { params: params });
  // },
  getDownloadFile(planId, url) {
    return get(`${tripPlanning.base}${planId}/${url}/`, {
      responseType: "blob",
      headers: { accept: "application/xlsx" },
    });
  },

  getAllOrderData(plan_id) {
    return get(`${tripPlanning.base}${plan_id}/order_coordinates/`);
  },
  getUnplannedDriverList(params) {
    return get(`${version}planned_performer/`, { params: params });
  },
  getUnplannedOrderListList(params) {
    return get(`${version}planned_order/`, { params: params });
  },
  getPlannedTripReturnOrderList(id) {
    return get(`${version}/planned_trip/${id}/return_order_recommendation/`);
  },
  assignPlannedTripReturnOrderList(id, payload) {
    return patch(`${version}/planned_trip/${id}/assign_planned_trip/`, payload);
  },
});
