
<template>
  <v-app class="background">
    <router-view />
    <CommonSnackBar
      v-model="showToastMessage"
      :color="snackBarColor"
      :timeout="timeOut"
      :textMessage="snackbarMessage"
      :position="position"
    />
    <CommonLoader :loadingMessage="loaderMessage" :showOverlay="showLoader" />
    <ConfirmationDialogue
      v-model="showConfirmationDialogue"
      :status="status"
      :title="title"
      :text="text"
      :rejectButton="rejectButton"
      :confirmButton="confirmButton"
      :hideRejectButton="hideRejectButton"
      :icon="icon"
      :showRemarks="showRemarks"
      :isRemarkRequired="isRemarkRequired"
      :remarkTitle="remarkTitle"
      :remarkValue="remarkValue"
    />

  </v-app>
</template>

<script>
import CommonSnackBar from "@/components/BaseComponents/SnackBar.vue";
import CommonLoader from "@/components/BaseComponents/Loader.vue";
import ConfirmationDialogue from "@/components/BaseComponents/ConfirmationDialogue.vue";
export default {
  name: "App",
  components: {
    CommonSnackBar,
    CommonLoader,
    ConfirmationDialogue,
  },
  data: () => ({
    showToastMessage: false,
    snackBarColor: "primary",
    snackbarMessage: "",
    timeOut: 1500,
    showLoader: false,
    loaderMessage: "Loading..",
    position: null,
    // Confirmation
    showConfirmationDialogue: false,
    status: "secondary",
    title: "Confirmation",
    text: "Are you sure?",
    hideRejectButton: false,
    icon: null,
    showRemarks: false,
    isRemarkRequired: false,
    remarkTitle: "Remarks",
    remarkValue: null,
    rejectButton: null,
    confirmButton: null,
  }),
  created() {
    this.$bus.$on("showConfirmationDialog", (config) => {
      this.status = config?.status || "warning";
      this.title = config?.title || "Confirmation";
      this.text = config?.text || "Are you sure?";
      this.rejectButton = config?.rejectButton || "No";
      this.confirmButton = config?.confirmButton || "Yes";
      this.icon = config?.icon || "mdi-information";
      this.showRemarks = config?.showRemarks || false;
      this.isRemarkRequired = config?.isRemarkRequired || false;
      this.remarkTitle = config?.remarkTitle || "Remarks";
      this.remarkValue = config?.remarkValue;
      this.hideRejectButton = !!config?.hideRejectButton;
      this.showConfirmationDialogue = true;
    });
    this.$bus.$on(
      "showToastMessage",
      ({ color, message, timeout, position, hideRejectButton }) => {
        this.timeOut = timeout;
        this.position = position;
        this.snackBarColor = color;
        this.snackbarMessage = message;
        this.hideRejectButton = hideRejectButton;
        this.showToastMessage = true;
      }
    );
    this.$bus.$on("showLoader", (value, message) => {
      this.loaderMessage = message;
      this.showLoader = value;
    });
  },
  beforeDestroy() {
    this.$bus.$off("showToastMessage");
    this.$bus.$off("showLoader");
    this.$bus.$off("showConfirmationDialog");
  },
};
</script>
