import Blank from "@/layouts/blank.vue";
import { tagLabel } from "@/utils/labels/tagLabels";

const tagRoutes = [
  {
    path: `${tagLabel.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${tagLabel.value}-list`,
        component: () => import("@/pages/configuration/tag/TagList.vue"),
        default: true,
      },
    ],
  },
];

export default tagRoutes;
