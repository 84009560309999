<template>
  <v-app-bar
    v-bind="$attrs"
    :clipped-left="true"
    :clipped-right="true"
    fixed
    app
    elevation="2"
    color="white"
  >
    <v-app-bar-nav-icon class="d-lg-none" @click.stop="$emit('toggleDrawer')" />

    <img
      class="Brand-Logo"
      alt="Brand Logo"
      :src="require('@/assets/images/logo.png')"
    />
    <v-spacer></v-spacer>

    <!-- <div class="d-flex justify-start notranslate max-width-250-px mr-2">
      <div v-for="(lang, index) in language" :key="index">
        <v-btn
          text
          color="primary"
          :class="
            lang.value === currentLanguage ? 'primary lighten-7' : 'white'
          "
          class="mr-1"
          @click="googleTranslateElementInit(lang.value, true)"
        >
          {{ lang.label }}
        </v-btn>
      </div>
    </div> -->
  </v-app-bar>
</template>

<script>
import { getCookie, setCookie, eraseCookie } from "@/utils/cookieHandlers";

export default {
  name: "customer-app",
  data() {
    return {
      language: [
        {
          label: "English",
          value: "en",
        },
        {
          label: "عربي",
          value: "ar",
        },
      ],
      currentLanguage: "en",
    };
  },
  methods: {
    clearData() {
      localStorage.clear();
      this.userInfoStore.setUserInfo(null);
      this.$router.push("/login");
      this.$emit("closeWebSocket");
    },
    logout() {
      this.$confirm({
        status: "error",
        title: "Logout",
        text: "Are you sure you want to Logout?",
      }).then(() => {
        // this.$bus.$emit("showLoader", true);
        // this.$api.authentication
        //   .logout()
        //   .then(() => {
        //     this.$bus.$emit("showLoader", false);
        localStorage.clear();
        this.userInfoStore.setUserInfo(null);
        this.$router.push({ name: "login" });
        this.$emit("closeWebSocket");
      });
    },
    checkCurrentLang() {
      this.currentLanguage = getCookie("currentLanguage")
        ? getCookie("currentLanguage")
        : "en";
    },
    googleTranslateElementInit(language = null, reload = false) {
      if (!language && this.currentLanguage === language) {
        return;
      }
      this.currentLanguage = language;

      if (getCookie("googtrans")) {
        eraseCookie("googtrans");
      }

      const previousLangugage = getCookie("currentLanguage") ?? "en";

      setCookie(
        "googtrans",
        `/${previousLangugage}/${this.currentLanguage}`,
        import.meta.env.VITE_APP_COOKIEDOMAIN
      );

      setCookie("currentLanguage", language);

      new google.translate.TranslateElement(
        {
          pageLanguage: this.currentLanguage,
          layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: true,
          includedLanguages: "ar,en",
        },
        "app"
      );

      setCookie(
        "currentLanguage",
        this.currentLanguage,
        import.meta.env.VITE_APP_COOKIEDOMAIN
      );
      if (reload || previousLangugage != this.currentLanguage) {
        location.reload();
      }
    },
    loadLang() {
      new google.translate.TranslateElement(
        {
          pageLanguage: "en",
          layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: true,
          includedLanguages: "ar,en",
        },
        "app"
      );
    },
  },
  mounted() {
    const interval = setInterval(() => {
      if (window && window.google && window.google.translate) {
        clearInterval(interval);
        this.checkCurrentLang();
        this.loadLang();
      }
    }, 300);
  },
};
</script>

<style scoped>
.Profile-Menu {
  margin-top: 10px;
}

.Branch-Height-Class {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
