export const operationLabel = {
  name: "Operation",
  plural: "Operation",
  pathName: "operation",
  value: "operation",
  permissionKey: "operation",
};

export const operationalAnalyticLabel = {
  name: "Operational Analytic",
  plural: "Operational Analytics",
  pathName: "operational-analytics",
  value: "operational-analytics",
  permissionKey: "operation",
};
