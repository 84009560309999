import { field } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getFieldList(params) {
    return get(`${field.base}`, {
      params: params,
    });
  },
  getFieldObject(id) {
    return get(`${field.base}${id}/`);
  },
  addField(data) {
    return post(`${field.base}`, data);
  },
  updateField(data, id) {
    return put(`${field.base}${id}/`, data);
  },
  getModuleList(params) {
    return get(`${field.module}`, {
      params: params,
    });
  },
  getModuleKeywordList(params) {
    return get(`${field.moduleKeyword}`, {
      params: params,
    });
  },
  getReasonList(params) {
    return get(`${field.reason}`, {
      params: params,
    });
  },
});
