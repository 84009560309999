import Blank from "@/layouts/blank.vue";
import { customerAddressLabel } from "@/utils/labels/customerManagementLabels";

const customerAddressRoutes = [
  {
    path: `${customerAddressLabel.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${customerAddressLabel.value}-list`,
        component: () =>
          import(
            "@/pages/customerManagement/customerAddress/CustomerAddressList.vue"
          ),
        default: true,
      },
      {
        path: "add/",
        name: `${customerAddressLabel.value}-add`,
        component: () =>
          import(
            "@/pages/customerManagement/customerAddress/CustomerAddressAdd.vue"
          ),
      },
      {
        path: "edit/:id",
        name: `${customerAddressLabel.value}-edit`,
        component: () =>
          import(
            "@/pages/customerManagement/customerAddress/CustomerAddressEdit.vue"
          ),
      },
      {
        path: "details/:id",
        name: `${customerAddressLabel.value}-details`,
        component: () =>
          import(
            "@/pages/customerManagement/customerAddress/CustomerAddressDetail.vue"
          ),
      },
    ],
  },
];

export default customerAddressRoutes;
