import Blank from "@/layouts/blank.vue";
import { cityLabel } from "@/utils/labels/laneManagementLabels";

const citiesRoutes = [
  {
    path: `${cityLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${cityLabel.value}-list`,
        component: () => import("@/pages/laneManagement/city/CityList.vue"),
      },
      {
        path: "details/:id",
        name: `${cityLabel.value}-details`,
        component: () => import("@/pages/laneManagement/city/CityDetail.vue"),
      },
      {
        path: "add/",
        name: `${cityLabel.value}-add`,
        component: () => import("@/pages/laneManagement/city/CityAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${cityLabel.value}-edit`,
        component: () => import("@/pages/laneManagement/city/CityEdit.vue"),
      },
    ],
  },
];

export default citiesRoutes;
