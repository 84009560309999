import { tripExpense, version, tripFuelExpense } from "@/utils/urlConstants";
import { useUserInfoStore } from "@/stores/userInfo";

export default (axios) => ({
  async getTripExpenseList(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return axios.get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${tripExpense.base}`,
      {
        params: params,
      }
    );
  },
  async getTripExpenseObject(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return axios.get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${tripExpense.base}${id}/`
    );
  },

  async addTripExpense(data) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return axios.post(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${tripExpense.base}`,
      data
    );
  },
  async updateTripExpense(data, id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return axios.patch(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${tripExpense.base}${id}/`,
      data
    );
  },
  async deleteTripExpense(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return axios.delete(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${tripExpense.base}${id}/`
    );
  },
  async getTripFuelExpenseList(params) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return axios.get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${tripFuelExpense.base}`,
      {
        params: params,
      }
    );
  },
  async getTripFuelExpenseObject(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return axios.get(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${tripFuelExpense.base}${id}/`
    );
  },

  async addTripFuelExpense(data) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return axios.post(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${tripFuelExpense.base}`,
      data
    );
  },
  async updateTripFuelExpense(data, id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return axios.patch(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${tripFuelExpense.base}${id}/`,
      data
    );
  },
  async deleteTripFuelExpense(id) {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state?.userInfo?.branchPath) {
      await userInfoStore.setUserInfo();
    }
    return axios.delete(
      `${version}/${userInfoStore.$state.userInfo.branchPath}${tripFuelExpense.base}${id}/`
    );
  },
});
