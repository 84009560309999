import { customerAddress, customerAddressTag } from "@/utils/urlConstants";

export default (axios) => ({
  getCustomerAddressList(params) {
    return axios.get(`${customerAddress.base}`, { params: params });
  },
  getCustomerAddressLogs(params) {
    return axios.get(`${customerAddress.logs}`, { params: params });
  },
  getCustomerAddressSelectList(params = {}) {
    return axios.get(`${customerAddress.base}select/`, {
      params: params,
    });
  },
  getCustomerAddressObject(id) {
    return axios.get(`${customerAddress.base}${id}/`);
  },
  getCustomerAddressDetails(id) {
    return axios.get(`${customerAddress.base}${id}/view/`);
  },
  addCustomerAddress(data) {
    return axios.post(`${customerAddress.base}`, data);
  },
  updateCustomerAddress(data, id) {
    return axios.put(`${customerAddress.base}${id}/`, data);
  },
  getCustomerAddressTagsList(params) {
    return axios.get(`${customerAddressTag.base}`, { params: params });
  },
  deleteCustomerAddressTag(id) {
    return axios.delete(`${customerAddressTag.base}${id}/`);
  },
});
