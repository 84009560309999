import "@/assets/styles/base.scss";

import Vue from "vue";
import VueRouter from "vue-router";

import customer from "@/layouts/customer.vue";
import Admin from "@/layouts/admin.vue";
import DashBoardPage from "@/pages/dashBoard/Index.vue";

import orderRoutes from "./order";
// import bidRoutes from "./bid";
import assetsRoutes from "./assets";
import configRoutes from "./configuration";
// import itemRoutes from "./item";
import customerManagementRoutes from "./customerManagement";
import userManagementRoutes from "./user";
// import integrationLogRoutes from "./integrationLog";
import laneManagementRoutes from "./laneManagement";
import tripRoutes from "./trip";
import planTripRoutes from "./planTrip";
import transporterManagementRoutes from "./transporterManagement";
import operationRoutes from "./operation";
import reportRoutes from "./report/report";
import supportRoutes from "./support";

import { dashBoardLabel } from "@/utils/labels/dashBoardLabels";
// import { isAuthenticated } from "@/utils/functions";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Admin,
    // beforeEnter: (to, from, next) => {
    //   const isAuth = isAuthenticated() ? next() : next({ name: "login" });
    // },
    children: [
      {
        path: dashBoardLabel.pathName,
        component: DashBoardPage,
        name: `${dashBoardLabel.value}-page`,
        default: true,
      },
      ...orderRoutes,
      // ...bidRoutes,
      ...assetsRoutes,
      ...configRoutes,
      // ...itemRoutes,
      ...customerManagementRoutes,
      ...userManagementRoutes,
      // ...integrationLogRoutes,
      ...laneManagementRoutes,
      ...tripRoutes,
      ...planTripRoutes,
      ...transporterManagementRoutes,
      ...operationRoutes,
      ...reportRoutes,
      ...supportRoutes,
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/Login.vue"),
  },
  {
    path: "/verify",
    name: "verify",
    component: () => import("@/pages/VerifyOTPPage.vue"),
  },
  {
    path: "/customer",
    component: customer,
    children: [
      {
        path: ":id",
        name: "customer-order-tracking",
        component: () => import("@/pages/orderTracking/Tracking.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  // base: import.meta.env.BASE_URL,
  routes: routes,
});

export default router;
