<template>
  <v-tooltip
    v-bind="$attrs"
    v-on="$listeners"
    :bottom="bottom"
    :top="top"
    :left="left"
    :right="right"
    open-delay="121"
    close-delay="121"
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <span v-if="tooltipTitle">
          {{ tooltipTitle }}
        </span>
        <slot name="activator" />
      </span>
    </template>
    <span v-if="tooltipText && !$slots.body">{{ tooltipText }}</span>
    <slot name="body" />
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltipTitle: {
      type: String,
    },
    tooltipText: {
      type: [Number, String],
    },
    bottom: {
      type: Boolean,
    },
    top: {
      type: Boolean,
    },
    left: {
      type: Boolean,
    },
    right: {
      type: Boolean,
    },
    tooltipClass: {
      type: String,
    },
  },
};
</script>
