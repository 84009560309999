import Blank from "@/layouts/blank.vue";
import { supportLabel } from "@/utils/labels/supportLabels";

const supportRoutes = [
  {
    path: `${supportLabel.pathName}/`,
    component: () => import("@/pages/support/support.vue"),
    // children: [
    //   {
    //     path: "",
    //     name: `${supportLabel.value}`,
    //     component: () => import("@/pages/support/support.vue"),
    //     default: true,
    //   },
    // ],
  },
];

export default supportRoutes;
