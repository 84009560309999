import { planningTemplate } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  getPlanningTemplateList(params) {
    return get(`${planningTemplate.base}`, { params: params });
  },
  getPlanningTemplateSelectList(params) {
    return get(`${planningTemplate.select}`, { params: params });
  },
  getPlanningTemplateObject(id) {
    return get(`${planningTemplate.base}${id}/`);
  },
  getPlanningTemplateDetails(id) {
    return get(`${planningTemplate.base}${id}/view/`);
  },
  addPlanningTemplate(data) {
    return post(`${planningTemplate.base}`, data);
  },
  updatePlanningTemplate(data, id) {
    return put(`${planningTemplate.base}${id}/`, data);
  },
});
