import { storageType } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getStorageTypeList(params) {
    return get(`${storageType.base}`, {
      params: params,
    });
  },
  getStorageTypeSelectList(params = {}) {
    return get(`${storageType.base}select/`, {
      params: params,
    })
  },
  getStorageTypeObject(id) {
    return get(`${storageType.base}${id}/`);
  },
  addStorageType(data) {
    return post(`${storageType.base}`, data);
  },
  updateStorageType(data,id) {
    return put(`${storageType.base}${id}/`, data);
  },
});
