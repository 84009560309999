export const assetManagementLabel = {
  name: "Asset Management",
  plural: "Asset Management",
  // Used in routes and navigation
  pathName: "asset",
  value: "asset-management",
  permissionKey: "asset_management",
};

export const vehicleLabel = {
  name: "Vehicle",
  plural: "Vehicles",
  // Used in routes and navigation
  pathName: "vehicle",
  value: "vehicle",
  modelName: "vehicle",
  permissionKey: "asset_management",
};

export const vehicleInsuranceLabel = {
  name: "Insurance",
  plural: "Insurances",
  // Used in routes and navigation
  pathName: "vehicle-insurance",
  value: "vehicle-insurance",
  modelName: "vehicle-insurance",
  permissionKey: "asset_management",
};

export const vehicleServiceLabel = {
  name: "Service",
  plural: "Services",
  // Used in routes and navigation
  pathName: "vehicle-service",
  value: "vehicle-service",
  modelName: "vehicle-service",
  permissionKey: "asset_management",
};

export const vehicleConsumableLabel = {
  name: "Consumable",
  plural: "Consumable",
  // Used in routes and navigation
  pathName: "vehicle-consumable",
  value: "vehicle-consumable",
  modelName: "vehicle-consumable",
  permissionKey: "asset_management",
};

export const driverLabel = {
  name: "Driver",
  plural: "Drivers",
  // Used in routes and navigation
  pathName: "driver",
  value: "driver",
  modelName: "driver",
  permissionKey: "asset_management",
};

export const vehicleDocumentLabel = {
  name: "Vehicle Document",
  plural: "Vehicle Documents",
  // Used in services
  apiName: "vehicleDocument",
  apiModelName: "VehicleDocument",
  keyword: "vehicle_document",
  permissionKey: "asset_management",
};

export const driverDocumentLabel = {
  name: "Driver Document",
  plural: "Driver Documents",
  // Used in services
  apiName: "driverDocument",
  apiModelName: "DriverDocument",
  keyword: "driver_document",
  permissionKey: "asset_management",
};

export const vehicleSelectOptionLabel = {
  fuelType: {
    name: "Fuel Type",
    value: "fuel_type",
  },
  ownershipList: [
    {
      label: "Owned",
      value: "owned",
    },
    {
      label: "Leased",
      value: "leased",
    },
    {
      label: "Third Party",
      value: "third_party",
    },
  ],
  ownershipType: {
    owned: "owned",
    leased: "leased",
    thirdParty: "third_party",
  },
  serviceTypeList: [
    {
      label: "Regular",
      value: "regular",
    },
    {
      label: "Emergency",
      value: "emergency",
    },
  ],
  statusList: [
    {
      label: "On Duty",
      value: "on_duty",
    },
    {
      label: "Off Duty",
      value: "off_duty",
    },
    {
      label: "On Trip",
      value: "on_trip",
    },
  ],
  statusType: {
    onDuty: "on_duty",
    onTrip: "on_trip",
    offDuty: "off_duty",
  },
};

export const driverSelectOptionLabel = {
  licenseType: {
    name: "License Type",
    value: "license_type",
  },
  statusList: [
    {
      label: "On Duty",
      value: "on_duty",
    },
    {
      label: "Off Duty",
      value: "off_duty",
    },
    {
      label: "On Trip",
      value: "on_trip",
    },
    {
      label: "On Break",
      value: "break",
    },
  ],
  statusType: {
    onDuty: "on_duty",
    onTrip: "on_trip",
    offDuty: "off_duty",
    onBreak: "break",
  },
};
