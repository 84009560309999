import { packageType } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getPackageTypeList(params = {}) {
    return get(`${packageType.base}`, {
      params: params,
    });
  },
  getPackageTypeSelectList(params = {}) {
    return get(`${packageType.base}select/`, {
      params: params,
    });
  },
  getPackageTypeObject(id) {
    return get(`${packageType.base}${id}/`);
  },
  addPackageType(data) {
    return post(`${packageType.base}`, data);
  },
  updatePackageType(data, id) {
    return put(`${packageType.base}${id}/`, data);
  },
});
