<template>
  <v-card class="pa-4 rounded-lg" elevation="0">
    <div class="d-flex justify-space-between">
      <h5 class="grey--text" style="font-size: 11px">
        {{ label }}
      </h5>
      <!-- <span class="success--text font-weight-bold text-caption">
        ( 10% ) <v-icon color="success">mdi-arrow-up-bold</v-icon>
      </span> -->
      <!-- <span class="error--text">
        ( 10% ) <v-icon color="error">mdi-arrow-down-bold</v-icon>
      </span> -->
    </div>
    <div class="d-flex mt-1">
      <v-icon color="primary" class="mr-3" v-if="icon && !prependText">
        {{ icon }}
      </v-icon>
      <h3 class="mr-3 primary--text" v-else>{{ prependText }}</h3>
      <h3 class="font-weight-bolder">
        {{ text }}
      </h3>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number, undefined],
    },
    text: {
      type: [String, Number, undefined],
    },
    prependText: {
      type: [String, Number, undefined],
    },
    icon: {
      default: "mdi-truck",
    },
  },
};
</script>