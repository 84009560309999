<template>
  <div class="pa-2" style="border: 1px solid #8080803b">
    <v-img
      v-if="profileImage"
      :src="profileImage"
      :height="imageHeight"
      :width="imageWidth"
      contain
    />
    <span v-else>
      <div
        v-if="defaultFileIcon"
        :style="`height:${imageHeight};width: calc(${imageWidth - '20px'});`"
        class="d-flex justify-center align-center  grey"
      >
          <v-btn tile  elevation="0" x-large color="primary"   icon>
            <v-icon x-large>{{ defaultFileIcon }}</v-icon>
          </v-btn>
      </div>
      <v-img
        v-else
        :src="defaultProfileImage"
        :height="imageHeight"
        :width="imageWidth"
        contain
      />
    </span>

    <v-file-input
      v-model="ImageSrc"
      label="Profile"
      class="d-none"
      ref="profileImage"
      accept="image/png, image/jpeg, image/bmp"
      @input="onFileChanged"
      @change="onFileChanged"
      outlined
      prepend-icon=""
      append-icon="mdi-paperclip"
      truncate-length="50"
      hide-details="auto"
      show-size
    ></v-file-input>

    <div class="text-center pt-5 pb-4">
      <PrimaryButton
        v-if="!profileImage"
        class="primary"
        depressed
        @click="$refs.profileImage.$refs.input.click()"
        :text="uploadName"
      >
      </PrimaryButton>
      <PrimaryButton
        v-else
        class="error"
        depressed
        :text="uploadedRemove"
        @click="removeImage"
      >
      </PrimaryButton>
    </div>
  </div>
</template>
<script>
import PrimaryButton from "../Forms/PrimaryButton.vue";
import defaultProfileImage from "@/assets/images/profile.jpg";
export default {
  components: { PrimaryButton },
  props: {
    value: {
      required: true,
    },
    defaultImage: {
      type: String,
    },
    defaultFileIcon: {
      type: String,
    },
    imageHeight: {
      default: "200px",
    },
    imageWidth: {
      default: "200px",
    },
    uploadName: {
      default: "Upload",
    },
    uploadedRemove: {
      default: "Remove",
    },
  },
  computed: {
    profileImage: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      defaultProfileImage,
      ImageSrc: null,
    };
  },
  methods: {
    removeImage() {
      let isChange = this.profileImage ? true : false;
      this.profileImage = null;
      this.ImageSrc = null;
      this.$emit("UploadProfile", null, isChange);
    },
    onFileChanged(e) {
      this.profileImage = e ? URL.createObjectURL(e) : null;
      this.$emit("UploadProfile", this.ImageSrc, true);
      this.$forceUpdate();
    },
  },
};
</script>
