import { warehouse } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getWareHouseList(params) {
    return get(warehouse.base, {
      params: params,
    });
  },
  getWareHouseSelectList(params = {}) {
    return get(`${warehouse.base}select/`, {
      params: params,
    });
  },
  getWareHouseObject(id) {
    return get(`${warehouse.base}${id}/`);
  },
  addWareHouse(data) {
    return post(`${warehouse.base}`, data);
  },
  updateWareHouse(data, id) {
    return put(`${warehouse.base}${id}/`, data);
  },
});
