import Blank from "@/layouts/blank.vue";
import { configurationLabel } from "@/utils/labels/configurationLabels";
import projectRoutes from "./project";
import wareHouseRoutes from "./wareHouse";
import storageTypeRoutes from "./storageType";
import vehicleTypeRoutes from "./vehicleType";
import roadBanRoutes from "./roadBan";
import paymentMethodRoutes from "./paymentMethod";
import fieldRoutes from "./field";
import tagRoutes from "./tag";
import packageTypeRoutes from "./packageType";

const configRoutes = [
  {
    path: `${configurationLabel.pathName}/`,
    component: Blank,
    children: [
      ...projectRoutes,
      ...wareHouseRoutes,
      ...storageTypeRoutes,
      ...roadBanRoutes,
      ...paymentMethodRoutes,
      ...fieldRoutes,
      ...tagRoutes,
      ...packageTypeRoutes,
      ...vehicleTypeRoutes,
    ],
    name: `${configurationLabel.value}`,
  },
];

export default configRoutes;
