<template>
  <v-autocomplete
    :rules="[
      ...(isRequired
        ? [(val) => isValidValue(val) || `${label} is Required`]
        : []),
      ...fieldRules,
    ]"
    hide-details="auto"
    :label="`${label}${isRequired ? ' *' : ''}`"
    :multiple="multiple"
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    v-model="selectedItem"
    :class="designClass"
    class="Border-Remove"
    background-color="background"
    :chips="chips"
    disable-lookup
    :auto-select-first="autoSelectFirst"
    outlined
    :return-object="returnObject"
    :dense="dense"
    :id="generatedId"
  >
    <template #prepend-item v-if="multiple && !hideSelectAll && items.length">
      <v-list-item @click="toggleSelectAll">
        <v-list-item-action>
          <v-checkbox
            @click.stop="toggleSelectAll"
            v-model="isSelectedItems"
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            v-if="!selectedItem || selectedItem.length != items.length"
          >
            Select All
          </v-list-item-title>
          <v-list-item-title v-else>Deselect All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template #selection="{ item, index }" v-if="multiple">
      <div v-if="showAll">
        <v-chip v-if="chips" class="my-1 primary--text" small color="white">
          <span class="text-truncate">
            {{
              typeof item == typeof {} && itemText in item
                ? item[itemText]
                : item
            }}
          </span>
        </v-chip>
        <span v-if="!chips && index > 0">,&nbsp;</span>
        <span v-if="!chips">
          {{
            typeof item == typeof {} && itemText in item ? item[itemText] : item
          }}
        </span>
      </div>
      <div v-else>
        <v-chip
          v-if="chips && index === 0"
          small
          color="white"
          class="primary--text"
        >
          <span class="text-truncate" :style="chipWidthStyle">
            {{
              typeof item == typeof {} && itemText in item
                ? item[itemText]
                : item
            }}
          </span>
        </v-chip>
        <span v-if="!chips && index === 0">
          {{
            typeof item == typeof {} && itemText in item ? item[itemText] : item
          }}
        </span>
        <span v-if="index === 1" class="grey--text text-caption">
          &nbsp;(+{{ selectedItem.length - 1 }} others)
        </span>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "SelectTag",
  props: {
    value: {
      type: [String, Number, Array, Object, Boolean],
    },
    label: {
      required: true,
    },
    hideSelectAll: {
      default: false,
    },
    dense: {
      default: true,
    },
    autoSelectFirst: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    designClass: {
      type: String,
    },
    returnObject: {
      type: Boolean,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    chipWidthStyle: {
      type: String,
    },
    isRequired: {
      default: false,
      type: Boolean,
    },
    fieldRules: {
      default: () => [],
    },
  },
  computed: {
    generatedId() {
      return this.label
        ? `${this.label.replaceAll(" ", "-")}-input-box`
        : `generated-input-box`;
    },
    selectedItem: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.$forceUpdate();
      },
    },
    isSelectedItems: {
      get() {
        return (
          this.selectedItem &&
          this.items &&
          this.selectedItem.length === this.items.length
        );
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    toggleSelectAll() {
      if (this.selectedItem?.length == this.items.length) {
        this.selectedItem = [];
      } else {
        if (typeof this.items[0] == typeof {}) {
          try {
            if (this.returnObject) {
              this.selectedItem = this.items;
            } else {
              this.selectedItem = [];
              this.selectedItem = this.items.map((obj) => {
                return obj[this.itemValue];
              });
            }
          } catch (err) {
            console.error(e);
          }
        } else {
          this.selectedItem = [...this.items];
        }
      }
      this.$emit("change", this.selectedItem);
    },

    isValidValue(value) {
      if (Array.isArray(value)) return !!value.length;
      return !!value;
    },
  },
};
</script>
