import { driverTag } from "@/utils/urlConstants";

export default (axios) => ({
  getDriverTagList(params) {
    return axios.get(driverTag.base, { params: params });
  },
  addDriverTag(data) {
    return axios.post(driverTag.base, data);
  },
  deleteDriverTag(id) {
    return axios.delete(`${driverTag.base}${id}/`);
  },
});
