<template>
  <div>
    <!-- Top Navigation -->
    <AppBar @toggleDrawer="drawer = !drawer" />
    <!-- :companyNotifications="companyNotifications"
        :unreadCount="unreadCount"
        :unreadMessages="unreadMessages" -->
    <!-- @openChat="openChat"
            @showRightDrawer="rightDrawer = true"
            @getOldNotifications="getOldNotifications"
            @refreshNotificationHistory="getNotificationHistory"
            @closeWebSocket="onClose" -->

    <!-- Left Side Navigation -->
    <SideNavigation v-model="drawer" />

    <!-- Right Side Navigation -->
    <!-- <RightDrawer v-model="rightDrawer" /> -->

    <!-- Base Content -->
    <v-main class="background">
      <v-container fluid class="pa-0">
        <router-view />
      </v-container>
      <div style="position: relative">
        <div style="position: fixed; right: 3%; bottom: 7%; z-index: 2">
          <OperationChat
            ref="chat"
            v-model="isChatVisible"
            :tripObj="tripDetails"
            :tripId="tripDetails && tripDetails.id"
            @closeChat="closeChat"
          />
        </div>
      </div>
    </v-main>
  </div>
</template>

<script>
import AppBar from "../components/BaseComponents/AppBar.vue";
// import RightDrawer from '../components/BaseComponents/RightDrawer.vue';
import SideNavigation from "../components/BaseComponents/SideNavigation.vue";
import OperationChat from "@/pages/operation/util/OperationChat.vue";
import { useUserInfoStore } from "@/stores/userInfo";
import { productFruits } from "product-fruits";

export default {
  name: "DefaultLayout",
  components: {
    AppBar,
    SideNavigation,
    OperationChat,
    // RightDrawer,
  },
  setup() {
    const userInfoStore = useUserInfoStore();

    return { userInfoStore };
  },
  data() {
    return {
      // Drawer vars
      drawer: true,
      isChatVisible: false,
      tripDetails: {},
      // rightDrawer: false,
    };
  },
  methods: {
    openChatBox(tripObj) {
      if (this.isChatVisible) {
        this.isChatVisible = false;
        setTimeout(() => {
          this.isChatVisible = true;
        }, 100);
      }
    },
    closeChat() {
      this.isChatVisible = false;
    },
  },
  mounted() {
    let timer = setInterval(() => {
      if (
        this.userInfoStore &&
        this.userInfoStore.userInfo &&
        this.userInfoStore.userInfo.username &&
        import.meta.env.VITE_PRODUCT_FRUITS_CODE &&
        import.meta.env.VITE_PRODUCT_FRUITS_LANGUAGE
      ) {
        clearInterval(timer);
        productFruits.init(
          import.meta.env.VITE_PRODUCT_FRUITS_CODE,
          import.meta.env.VITE_PRODUCT_FRUITS_LANGUAGE,
          {
            username: this.userInfoStore.userInfo.username,
          }
        );
      }
    }, 100);
    setTimeout(() => {
      clearInterval(timer);
    }, 3000);
  },
  created() {
    this.$bus.$on("openChatWindow", (notificationObj) => {
      this.tripDetails = notificationObj?.trip_details;
      this.isChatVisible= true;
      this.openChatBox(this.tripDetails?.trip_id);
    })
  },
  beforeDestroy() {
    this.$bus.$off("openChatWindow");
  },
  async beforeCreate() {
    const userInfoStore = useUserInfoStore();
    if (!userInfoStore.$state.userInfo) {
      await userInfoStore.setUserInfo();
    }
  },
};
</script>
