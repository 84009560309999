<template>
  <v-menu
    close-on-content-click
    max-width="400px"
    nudge-left="350"
    nudge-top="-20"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        top
        overlap
        :content="notificationCount"
        :color="notificationCount == 0 ? '' : 'green'"
      >
        <!-- <Tooltip left tooltipText="Notification"> -->
        <!-- <template #activator> -->
        <v-icon v-on="on" v-bind="attrs" @click="getNotificationList">
          mdi-bell
        </v-icon>
        <!-- </template> -->
        <!-- </Tooltip> -->
      </v-badge>
    </template>

    <v-card width="400px" flat class="rounded-lg">
      <v-card-title class="primary">
        <span class="text-body-1 white--text"> Notifications </span>
        <v-spacer></v-spacer>
        <v-icon
          v-show="notificationCount != 0"
          color="white"
          @click="deleteAllUserNotifications"
        >
          mdi-delete
        </v-icon>
      </v-card-title>

      <v-card-text
        style="max-height: 350px"
        class="pa-0 overflow-y-auto"
        @scroll="handleNotificationScroll"
        id="notificationCard"
      >
        <div
          v-if="!userNotifications.length"
          class="d-flex justify-center pa-3"
        >
          <span>No Notifications To Show!</span>
        </div>
        <div v-else>
          <div v-for="(notification, i) in userNotifications" :key="i">
            <v-row
              :class="[
                'ma-0',
                'my-1',
                notification.is_read ? 'grey--text' : '',
              ]"
              :style="
                !notification.is_read
                  ? { backgroundColor: '#e5f5fc', cursor: 'Pointer' }
                  : { backgroundColor: 'white', cursor: 'Pointer' }
              "
            >
              <v-col cols="12" class="pa-0 ma-0">
                <hr style="opacity: 0.4" />
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-center">
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 15px;
                  "
                >
                  <v-icon
                    color="primary"
                    v-if="notification.notification_category == 'Job'"
                  >
                    mdi-train-car-container
                  </v-icon>
                  <v-icon
                    color="primary"
                    v-else-if="notification.notification_category == 'Bid'"
                  >
                    mdi-cash-marker
                  </v-icon>
                  <v-icon
                    color="primary"
                    v-else-if="notification.notification_category == 'Campaign'"
                  >
                    mdi-ticket-percent
                  </v-icon>
                  <v-icon
                    color="primary"
                    v-else-if="notification.notification_category == 'Invoice'"
                  >
                    mdi-receipt-text
                  </v-icon>
                  <v-icon color="primary" v-else> mdi-receipt-text </v-icon>
                </div>
              </v-col>
              <v-col
                cols="10"
                class="d-flex align-center"
                @click="userNotificationMarkAsRead([notification.id])"
              >
                <v-row no-gutters>
                  <v-col cols="12" class="text-body-2">
                    <span >
                      {{ notification.title }}
                    </span>
                  </v-col>
                  <v-col cols="12" class="text-caption">
                    {{ notification.message }}
                  </v-col>
                  <v-col cols="12" class="text-caption font-weight-bold mt-1">
                    {{ notification.created }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
  
<script>
import Tooltip from "@/components/General/Tooltip.vue";
// import GridDeleteButton from "@/components/GeneralButtons/GridDeleteButton.vue";

export default {
  components: {
    Tooltip,
    // GridDeleteButton,
  },

  props: {},
  data() {
    return {
      userNotificationCount: 0,
      notificationCount: 0,
      userNotifications: [],
    };
  },
  methods: {
    deleteAllUserNotifications() {
      const config = {
        status: "red",
        title: `Notifications`,
        text: `Are you sure want to clear all notifications?`,
      };

      this.$confirm(config)
        .then(() => {
          let notificationIdList = [];

          this.userNotifications.forEach((notification) =>
            notificationIdList.push(notification.id)
          );
          this.userNotificationMarkAsRead(notificationIdList);
        })
        .catch((err) => {});
    },
    async getNotificationList() {
      // params = {
      //   limit: this.limit,
      //   offset: this.offset,
      //   ...params,
      // };
      // if (this.userNotifications.length) {
      //   params.offset = this.userNotifications.length;
      // }
      this.$api.notification
        .getNotificationList({ 
          limit: "all" 
        })
        .then((res) => {
          this.userNotifications = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleNotificationScroll() {
      // let notificationCard = document.getElementById("notificationCard");
      // const clientHeight = notificationCard.clientHeight;
      // const scrollHeight = notificationCard.scrollHeight;
      // const scrollTop = notificationCard.scrollTop;
      // let callNotifications =
      //   Math.ceil(clientHeight + scrollTop) == scrollHeight;
      // if (callNotifications) {
      //   this.getNotificationList();
      // }
    },

    userNotificationMarkAsRead(idList) {
      // this.$bus.$emit("showLoader", true);

      this.$api.notification
        .notificationMarkAsRead(idList)
        .then((res) => {
          this.getNotificationCount();
          if (this.notificationCount > 0) {
            this.getNotificationList();
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$bus.$emit("showLoader", false);
        });
    },
    getNotificationCount() {
      this.$api.notification
        .getNotificationCount({ 
          limit: "all" 
        })
        .then((res) => {
          this.notificationCount = res.data.count;
        })
        .catch((err) => {
          console.log(err);
          // this.$bus.$emit("showLoader", false);
        });
    },
  },
  mounted() {
    this.getNotificationCount();
    setInterval(() => {
      this.getNotificationCount();
    }, 105000);
  },
  // beforeDestroy() {
  //   this.$bus.$off("socketConnected");
  //   this.$bus.$off("userNotificationReceived");
  // },
};
</script>
  
<style scoped>
.card-top-outlined {
  border-top: 1px solid #0000004f !important;
}

.btn-position-fixed-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Chat-Height-Class {
  height: calc(100vh - 200px) !important;
}
</style>