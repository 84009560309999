import Blank from "@/layouts/blank.vue";
import { rateLabel } from "@/utils/labels/transporterManagementLabels";

const rateRoutes = [
  {
    path: `${rateLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${rateLabel.value}-list`,
        component: () =>
          import(
            "@/pages/transporterManagement/rate/rateList.vue"
          ),
      },
      {
        path: "details/:id",
        name: `${rateLabel.value}-details`,
        component: () =>
          import(
            "@/pages/transporterManagement/rate/rateDetail.vue"
          ),
      },
      {
        path: "add/",
        name: `${rateLabel.value}-add`,
        component: () =>
          import(
            "@/pages/transporterManagement/rate/rateAdd.vue"
          ),
      },
      {
        path: "edit/:id",
        name: `${rateLabel.value}-edit`,
        component: () =>
          import(
            "@/pages/transporterManagement/rate/rateEdit.vue"
          ),
      },
    ],
  },
];

export default rateRoutes;
