import Blank from "@/layouts/blank.vue";
import { vehicleConsumableLabel } from "@/utils/labels/assetManagementLabels";

const vehicleConsumableRoutes = [
  {
    path: `${vehicleConsumableLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${vehicleConsumableLabel.value}-list`,
        component: () =>
          import("@/pages/assets/vehicleConsumable/VehicleConsumableList.vue"),
      },
    ],
  },
];

export default vehicleConsumableRoutes;
