<template>
  <div class="d-flex align-center">
    <div class="d-flex align-center" v-for="(route, i) in routes" :key="i">
      <v-icon v-if="i != 0">mdi-chevron-right</v-icon>
      <v-btn
        text
        color="primary"
        class="pa-2 breadcrumb-button"
        :disabled="route.disabled"
        @click="goToPage(route.name)"
      >
        {{
          breadCrumbTitles[route.key]
            ? breadCrumbTitles[route.key]
            : route.title
        }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  data() {
    return {
      routes: [],
      breadCrumbTitles: {},
    };
  },
  computed: {
    activeParams() {
      return this.$route.params;
    },
  },
  methods: {
    loadTitles() {
      this.breadCrumbTitles =
        JSON.parse(localStorage.getItem("breadCrumbTitles")) || {};
    },
    getMatchedRoutes() {
      const routes = [...this.$route.matched];
      const route = routes[routes.length - 1];
      this.generateBreadCrumbsData(route);
    },
    generateBreadCrumbsData(route) {
      const paths = route.path.split("/");
      if (paths.length <= 3) return;

      const path = paths[paths.length - 1];
      let key = null;
      if (path[0] == ":") {
        key = path.substring(1, path.length);
      }

      const routeConfig = {};

      if (key) {
        routeConfig.key = key;
      }

      if (route.meta.breadcrumb) {
        routeConfig.title = route.meta.breadcrumb;
      }

      if (route.name) {
        routeConfig.name = route.name;
      } else if (route.meta.defaultRoute) {
        routeConfig.name = route.meta.defaultRoute;
      }

      if (this.routes.length == 0 || !routeConfig.name) {
        routeConfig.disabled = true;
      }

      if (routeConfig.title || routeConfig.key) {
        this.routes.unshift(routeConfig);
      }

      if (route.parent) {
        this.generateBreadCrumbsData(route.parent);
      }
    },
    goToPage(name) {
      this.$router.replace({ name: name, params: this.activeParams });
    },
    getDefaultRoute() {
      const routes = this.$router;
    },
  },
  mounted() {
    this.loadTitles();
    this.$bus.$on("refreshBreadcrumbTitles", this.loadTitles);
    this.getMatchedRoutes();
  },
  beforeDestroy() {
    this.$bus.$off("refreshBreadcrumbTitles");
  },
};
</script>
