import { laneRoute } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  getLaneRouteList(params) {
    return get(`${laneRoute.base}`, { params: params });
  },
  getLaneRouteSelectList(params) {
    return get(`${laneRoute.select}`, { params: params });
  },
  getLaneRouteObject(id) {
    return get(`${laneRoute.base}${id}/`);
  },
  getLaneRouteDetails(id) {
    return get(`${laneRoute.base}${id}/view/`);
  },
  addLaneRoute(data) {
    return post(`${laneRoute.base}`, data);
  },
  updateLaneRoute(data, id) {
    return put(`${laneRoute.base}${id}/`, data);
  },
});
