import Blank from "@/layouts/blank.vue";
import { projectLabel } from "@/utils/labels/configurationLabels";

const projectRoutes = [
  {
    path: `${projectLabel.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${projectLabel.value}-list`,
        component: () =>
          import("@/pages/configuration/project/ProjectList.vue"),
        default: true,
      },
      {
        path: "add/",
        name: `${projectLabel.value}-add`,
        component: () => import("@/pages/configuration/project/ProjectAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${projectLabel.value}-edit`,
        component: () =>
          import("@/pages/configuration/project/ProjectEdit.vue"),
      },
    ],
  },
];

export default projectRoutes;
