import { defineStore } from "pinia";
import api from "@/plugins/api";

export const useUserInfoStore = defineStore({
  id: "user-info",
  state: () => ({
    userInfo: null,
    organizationType: null,
    permissions: null,
  }),
  // getters: {
  //   doubleCount: (state) => state.counter * 2,
  // },
  actions: {
    async setUserInfo(resetValue = false) {
      if (!this.userInfo || resetValue) {
        await api.authentication.getProfileInfo().then((res) => {
          this.userInfo = structuredClone(res.data);
          this.organizationType = res.data.organization_type;
          this.permissions = res.data.permissions;
          if (
            this.userInfo?.preferred_branch &&
            this.userInfo.preferred_branch.toUpperCase().replaceAll(" ", "") ==
              "ALRAYA"
          ) {
            this.userInfo.branchPath = "alraya";
          } else {
            this.userInfo.branchPath = "alraya";
          }
        });
      }
    },
  },
});
