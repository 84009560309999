export const tripPlanningLabel = {
  name: "Trip Planning",
  plural: "Trip Planning",
  pathName: "trip-planning",
  value: "trip-planning",
  permissionKey: "trip_planning",
};
export const tripPlanLabel = {
  name: "Trip Plan",
  plural: "Trip Plans",
  pathName: "trip-plan",
  value: "trip-plan",
  permissionKey: "trip_planning",
};

export const planTripsLabel = {
  name: "Plan Trip",
  plural: "Plan Trips",
  pathName: "plan-trip",
  value: "plan-trip",
  permissionKey: "trip_planning",
};

export const planningTemplateLabel = {
  name: "Planning Template",
  plural: "Planning Templates",
  pathName: "planning-template",
  value: "planning-template",
  permissionKey: "trip_planning",
};

export const tripsPlanOrderLabel = {
  name: "Package",
  plural: "Packages",
};
