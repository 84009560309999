import axios from "@/plugins/axios";

import authentication from "@/service/user/authentication";
import order from "@/service/order/order";
import bid from "@/service/transporterManagement/bid";
import item from "@/service/item/item";
import project from "@/service/configuration/project";
import warehouse from "@/service/configuration/warehouse";
import storageType from "@/service/configuration/storageType";
import roadBan from "@/service/configuration/roadBan";
import paymentMethod from "@/service/configuration/paymentMethod";
import field from "@/service/configuration/field";
import vehicle from "@/service/assets/vehicle";
import vehicleInsurance from "@/service/assets/vehicleInsurance";
import vehicleService from "@/service/assets/vehicleService";
import vehicleConsumable from "@/service/assets/vehicleConsumable";
import customer from "@/service/customerManagement/customer";
import customerAddress from "@/service/customerManagement/customerAddress";
import user from "@/service/user/user";
import role from "@/service/user/role";
import integrationLog from "@/service/integrationLog/integrationLog";
import vehicleType from "@/service/configuration/vehicleType";
import driver from "@/service/assets/driver";
import vehicleDocument from "@/service/assets/vehicleDocument";
import driverDocument from "@/service/assets/driverDocument";
import tag from "@/service/configuration/tag";
import packageType from "@/service/configuration/packageType";
import bulkUpload from "@/service/bulkUpload/bulkUpload";
import city from "@/service/laneManagement/city";
import zone from "@/service/laneManagement/zone";
import laneRoute from "@/service/laneManagement/laneRoute";
import notification from "@/service/notification/notification";
import driverTag from "@/service/assets/driverTag";
import vehicleTag from "@/service/assets/vehicleTag";
import customerAddressTag from "@/service/customerManagement/customerAddressTag";
import trip from "@/service/trip/trip";
import tripPlan from "@/service/tripPlanning/tripPlan";
import tripPlanning from "@/service/tripPlanning/tripPlanning";
import planningTemplate from "@/service/tripPlanning/planningTemplate";
import tripExpense from "@/service/trip/tripExpense";
import transporter from "@/service/transporterManagement/transporter";
import transporterUser from "@/service/transporterManagement/transporterUser";
import transporterRate from "@/service/transporterManagement/transporterRate";
import rate from "@/service/transporterManagement/Rate";
import operation from "@/service/operation/operation";
import dashboard from "@/service/dashboard/dashboard";
import report from "@/service/report/report";
import support from "@/service/support/support";

import {
  driverDocumentLabel,
  vehicleDocumentLabel,
} from "@/utils/labels/assetManagementLabels";
import {
  vehicleTagLabel,
  driverTagLabel,
  customerAddressTagLabel,
} from "@/utils/labels/tagLabels";

let api = {
  authentication: authentication(axios),
  dashboard: dashboard(axios),
  user: user(axios),
  order: order(axios),
  bid: bid(axios),
  item: item(axios),
  project: project(axios),
  warehouse: warehouse(axios),
  storageType: storageType(axios),
  roadBan: roadBan(axios),
  paymentMethod: paymentMethod(axios),
  field: field(axios),
  role: role(axios),
  vehicle: vehicle(axios),
  vehicleInsurance: vehicleInsurance(axios),
  vehicleService: vehicleService(axios),
  vehicleConsumable: vehicleConsumable(axios),
  driver: driver(axios),
  customer: customer(axios),
  customerAddress: customerAddress(axios),
  integrationLog: integrationLog(axios),
  vehicleType: vehicleType(axios),
  bulkUpload: bulkUpload(axios),
  city: city(axios),
  zone: zone(axios),
  laneRoute: laneRoute(axios),
  tag: tag(axios),
  packageType: packageType(axios),
  notification: notification(axios),
  trip: trip(axios),
  tripPlanning: tripPlanning(axios),
  planningTemplate: planningTemplate(axios),
  tripPlan: tripPlan(axios),
  tripExpense: tripExpense(axios),
  transporter: transporter(axios),
  transporterUser: transporterUser(axios),
  transporterRate: transporterRate(axios),
  rate: rate(axios),
  operation: operation(axios),
  report: report(axios),
  support: support(axios),
};
api[vehicleDocumentLabel.apiName] = vehicleDocument(axios);
api[driverDocumentLabel.apiName] = driverDocument(axios);
api[driverTagLabel.apiName] = driverTag(axios);
api[vehicleTagLabel.apiName] = vehicleTag(axios);
api[customerAddressTagLabel.apiName] = customerAddressTag(axios);

export default api;
