<template>
  <v-navigation-drawer
    v-model="drawer"
    expand-on-hover
    :mini-variant="true"
    :clipped="true"
    fixed
    app
    dark
    color="primary"
    v-bind="$attrs"
    width="335px"
    class="app-nav-shadow Scrollbar"
  >
    <v-list dense shaped class="pr-1 pt-2">
      <v-list-item v-for="(link, i) in nav_items" :key="i" class="pa-0">
        <v-list-item-group
          v-if="!link.subLinks"
          v-model="selectedItem"
          class="Full-Width"
          :id="generatedId(link.title)"
          color="white"
        >
          <v-list-item :exact="!!link.exact" router :to="link.to" :id="generatedId(link.to)">
            <v-list-item-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ link.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-group
          v-else
          class="py-1 Full-Width"
          color="white"
          :id="generatedId(link.title)"
        >
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ link.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(subLink, i) in link.subLinks"
            router
            exact
            :key="i"
            :to="subLink.to"
            :id="generatedId(subLink.to)"
          >
            <v-list-item-action>
              <v-icon>{{ subLink.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="pl-6">
              <v-list-item-title>{{ subLink.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// import { getPermissions } from "@/utils/functions";
import { tripLabel } from "@/utils/labels/tripLabels";
import { orderLabel } from "@/utils/labels/orderLabels";
import { bidLabel } from "@/utils/labels/bidLabels";
import { itemLabel } from "@/utils/labels/itemLabels";
import {
  transporterLabel,
  transporterRateLabel,
  transporterUserLabel,
  transporterManagementLabel,
  rateLabel,
} from "@/utils/labels/transporterManagementLabels";
import {
  customerLabel,
  customerAddressLabel,
  customerManagementLabel,
} from "@/utils/labels/customerManagementLabels";
import {
  assetManagementLabel,
  driverLabel,
  vehicleLabel,
  vehicleInsuranceLabel,
  vehicleServiceLabel,
  vehicleConsumableLabel,
} from "@/utils/labels/assetManagementLabels";
import {
  configurationLabel,
  projectLabel,
  warehouseLabel,
  storageTypeLabel,
  fieldLabel,
  paymentMethodLabel,
  roadBanLabel,
  vehicleTypeLabel,
  packageTypeLabel,
} from "@/utils/labels/configurationLabels";
import { integrationLogLabel } from "@/utils/labels/integrationLogLabels";
import {
  userLabel,
  roleLabel,
  userManagementLabel,
} from "@/utils/labels/userLabels";
import { dashBoardLabel } from "@/utils/labels/dashBoardLabels";
import {
  tripPlanningLabel,
  planTripsLabel,
  planningTemplateLabel,
} from "@/utils/labels/tripPlanningLabels";
import {
  laneManagementLabel,
  cityLabel,
  routeLabel,
  zoneLabel,
} from "@/utils/labels/laneManagementLabels";
import { tagLabel } from "@/utils/labels/tagLabels";
import { organizationType } from "@/utils/labels/organizationTypeLabels";
import { useUserInfoStore } from "@/stores/userInfo";
import { mapState } from "pinia";
import { operationLabel } from "@/utils/labels/operationLabels";
import { reportLabel } from "@/utils/labels/reportLabels";
import { supportLabel } from "@/utils/labels/supportLabels";

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      organizationTypeLabel: organizationType,
      selectedItem: 0,
      baseNav: [
        {
          icon: "mdi-view-dashboard",
          title: dashBoardLabel.name,
          to: `/${dashBoardLabel.pathName}`,
          exact: true,
        },
        {
          icon: "mdi-chart-bar",
          title: operationLabel.name,
          to: `/${operationLabel.pathName}`,
          exact: true,
          permissionKey: operationLabel.permissionKey,
        },
        {
          icon: "mdi-map-marker-distance",
          title: tripPlanningLabel.name,
          permissionKey: tripPlanningLabel.permissionKey,
          subLinks: [
            {
              icon: "mdi-clipboard-list-outline",
              title: planningTemplateLabel.name,
              to: `/${planTripsLabel.pathName}/${planningTemplateLabel.pathName}/`,
            },
            {
              icon: "mdi-tanker-truck theme--dark",
              title: planTripsLabel.name,
              to: `/${planTripsLabel.pathName}/${tripPlanningLabel.pathName}/`,
            },
          ],
        },

        // {
        //   icon: "mdi-map-marker-distance",
        //   title: tripPlanningLabel.name,
        //   to: `/${tripPlanningLabel.pathName}/`,
        //   exact: true,
        // },
        {
          icon: "mdi-truck-fast",
          title: tripLabel.name,
          to: `/${tripLabel.pathName}/`,
          exact: true,
          permissionKey: tripLabel.permissionKey,
        },
        {
          icon: "mdi-earth",
          title: orderLabel.name,
          to: `/${orderLabel.pathName}/`,
          exact: true,
          permissionKey: orderLabel.permissionKey,
        },
        // {
        //   icon: "mdi-alpha-i-circle",
        //   title: itemLabel.name,
        //   to: `/${itemLabel.pathName}/`,
        //   exact: true,
        //   permissionKey: itemLabel.permissionKey,
        // },
        {
          icon: "mdi-bus-multiple",
          title: assetManagementLabel.name,
          permissionKey: assetManagementLabel.permissionKey,
          subLinks: [
            {
              icon: "mdi-account",
              title: driverLabel.name,
              to: `/${assetManagementLabel.pathName}/${driverLabel.pathName}/`,
            },
            {
              icon: "mdi-truck-fast-outline",
              title: vehicleLabel.name,
              to: `/${assetManagementLabel.pathName}/${vehicleLabel.pathName}/`,
            },
            {
              icon: "mdi-file-document-alert-outline",
              title: vehicleInsuranceLabel.name,
              to: `/${assetManagementLabel.pathName}/${vehicleLabel.pathName}/${vehicleInsuranceLabel.pathName}`,
            },
            {
              icon: "mdi-train-car-flatbed-car",
              title: vehicleServiceLabel.name,
              to: `/${assetManagementLabel.pathName}/${vehicleLabel.pathName}/${vehicleServiceLabel.pathName}`,
            },
            {
              icon: "mdi-car-wrench",
              title: vehicleConsumableLabel.name,
              to: `/${assetManagementLabel.pathName}/${vehicleLabel.pathName}/${vehicleConsumableLabel.pathName}`,
            },
            {
              icon: "mdi-wrench-clock",
              title: `${driverLabel.name} Overtime`,
              to: `/${assetManagementLabel.pathName}/${driverLabel.pathName}/${driverLabel.value}-overtime-list/`,
            },
          ],
        },
        {
          icon: "mdi-account-cog",
          title: customerManagementLabel.name,
          permissionKey: customerManagementLabel.permissionKey,
          subLinks: [
            {
              icon: "mdi-account-multiple-outline",
              title: customerLabel.name,
              to: `/${customerManagementLabel.pathName}/${customerLabel.pathName}/`,
            },
            {
              icon: "mdi-map-marker-account",
              title: customerAddressLabel.name,
              to: `/${customerManagementLabel.pathName}/${customerAddressLabel.pathName}/`,
            },
          ],
        },
        // {
        //   icon: "mdi-database-sync",
        //   title: integrationLogLabel.name,
        //   to: `/${integrationLogLabel.pathName}/`,
        //   exact: true,
        //   permissionKey: integrationLogLabel.permissionKey,
        // },
        {
          icon: "mdi mdi-account-group",
          title: userManagementLabel.name,
          permissionKey: userLabel.permissionKey,
          subLinks: [
            {
              icon: "mdi-account-multiple-outline",
              title: userLabel.plural,
              to: `/${userManagementLabel.pathName}/${userLabel.pathName}/`,
            },
            {
              icon: "mdi-account-key",
              title: roleLabel.plural,
              to: `/${userManagementLabel.pathName}/${roleLabel.pathName}/`,
            },
          ],
        },

        {
          icon: "mdi-map-marker-distance",
          title: laneManagementLabel.name,
          permissionKey: laneManagementLabel.permissionKey,
          subLinks: [
            {
              icon: "mdi-map-marker-radius",
              title: cityLabel.plural,
              to: `/${laneManagementLabel.pathName}/${cityLabel.pathName}`,
            },
            {
              icon: "mdi-road-variant",
              title: routeLabel.plural,
              to: `/${laneManagementLabel.pathName}/${routeLabel.pathName}/`,
            },
            {
              icon: "mdi-vector-polyline",
              title: zoneLabel.plural,
              to: `/${laneManagementLabel.pathName}/${zoneLabel.pathName}/`,
            },
          ],
        },
        {
          icon: "mdi-chart-gantt",
          title: "Bid Management",
          to: `/${transporterManagementLabel.pathName}/${bidLabel.pathName}/`,
          permissionKey: transporterManagementLabel.permissionKey,
        },
        {
          icon: "mdi-led-strip-variant",
          title: transporterManagementLabel.name,
          permissionKey: transporterManagementLabel.permissionKey,
          subLinks: [
            {
              icon: "mdi-truck-delivery-outline",
              title: transporterLabel.plural,
              to: `/${transporterManagementLabel.pathName}/${transporterLabel.pathName}/`,
            },
            {
              icon: "mdi-badge-account",
              title: transporterUserLabel.plural,
              to: `/${transporterManagementLabel.pathName}/${transporterUserLabel.pathName}/`,
            },
            {
              icon: "mdi-cash-multiple",
              title: rateLabel.plural,
              to: `/${transporterManagementLabel.pathName}/${rateLabel.pathName}/`,
            },
          ],
        },
        {
          icon: "mdi-application-cog-outline",
          title: configurationLabel.name,
          permissionKey: configurationLabel.permissionKey,
          subLinks: [
            {
              icon: "mdi-map",
              title: projectLabel.name,
              to: `/${configurationLabel.pathName}/${projectLabel.pathName}`,
            },
            {
              icon: "mdi-warehouse",
              title: warehouseLabel.name,
              to: `/${configurationLabel.pathName}/${warehouseLabel.pathName}/`,
            },
            {
              icon: "mdi-home-thermometer",
              title: storageTypeLabel.name,
              to: `/${configurationLabel.pathName}/${storageTypeLabel.pathName}/`,
            },
            {
              icon: "mdi-truck-cargo-container",
              title: vehicleTypeLabel.name,
              to: `/${configurationLabel.pathName}/${vehicleTypeLabel.pathName}/`,
            },
            {
              icon: "mdi-card-text",
              title: fieldLabel.name,
              to: `/${configurationLabel.pathName}/${fieldLabel.pathName}/`,
            },
            // {
            //   icon: "mdi-cash-sync",
            //   title: paymentMethodLabel.name,
            //   to: `/${configurationLabel.pathName}/${paymentMethodLabel.pathName}/`,
            // },
            {
              icon: "mdi-tag-multiple",
              title: tagLabel.name,
              to: `/${configurationLabel.pathName}/${tagLabel.pathName}/`,
            },
            {
              icon: "mdi-alert-decagram",
              title: roadBanLabel.name,
              to: `/${configurationLabel.pathName}/${roadBanLabel.pathName}/`,
            },
            {
              icon: "mdi-package-variant-closed",
              title: packageTypeLabel.name,
              to: `/${configurationLabel.pathName}/${packageTypeLabel.pathName}/`,
            },
          ],
        },
        {
          icon: "mdi-table-large",
          title: reportLabel.plural,
          to: `/${reportLabel.pathName}/`,
          permissionKey: reportLabel.permissionKey,
        },
        {
          icon: "mdi-ticket",
          title: supportLabel.plural,
          to: `/${supportLabel.pathName}/`,
          // permissionKey: supportLabel.permissionKey,
        },
        // {
        //   icon: "mdi-earth",
        //   title: "Country",
        //   to: "/app/admin/country",
        // },
      ],
      transporterNav: [
        {
          icon: "mdi-view-dashboard",
          title: dashBoardLabel.name,
          to: `/${dashBoardLabel.pathName}`,
          exact: true,
        },
        {
          icon: "mdi-truck-fast",
          title: tripLabel.name,
          to: `/${tripLabel.pathName}/`,
          exact: true,
        },
        {
          icon: "mdi-cash-multiple",
          title: transporterRateLabel.plural,
          to: `/${transporterManagementLabel.pathName}/${transporterRateLabel.pathName}/`,
          exact: true,
        },
        {
          icon: "mdi-chart-gantt",
          title: "Bid Management",
          to: `/${transporterManagementLabel.pathName}/${bidLabel.pathName}/`,
        },
        // {
        //   icon: "mdi-truck-fast",
        //   title: tripLabel.name,
        //   to: `/${tripLabel.pathName}/`,
        //   exact: true,
        // },
        {
          icon: "mdi-bus-multiple",
          title: assetManagementLabel.name,
          subLinks: [
            {
              icon: "mdi-account",
              title: driverLabel.name,
              to: `/${assetManagementLabel.pathName}/${driverLabel.pathName}/`,
            },
            {
              icon: "mdi-truck-fast-outline",
              title: vehicleLabel.name,
              to: `/${assetManagementLabel.pathName}/${vehicleLabel.pathName}/`,
            },
          ],
        },
        {
          icon: "mdi-account-multiple-outline",
          title: userLabel.plural,
          to: `/${userManagementLabel.pathName}/${userLabel.pathName}/`,
        },
        {
          icon: "mdi-account-circle",
          title: supportLabel.plural,
          to: `/${supportLabel.pathName}/`,
          // permissionKey: supportLabel.permissionKey,
        },
        // {
        //   icon: "mdi-chart-gantt",
        //   title: bidLabel.name,
        //   to: `/${bidLabel.pathName}/`,
        //   exact: true,
        // },
      ],
      userType: null,
    };
  },
  setup() {
    const userInfoStore = useUserInfoStore();

    return { userInfoStore };
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapState(useUserInfoStore, ["permissions"]),
    userDetails() {
      return this.userInfoStore?.userInfo;
    },
    organizationType() {
      return localStorage.getItem("organization_type");
    },
    items() {
      if (this.organizationType === this.organizationTypeLabel.TRANSPORTER) {
        return this.transporterNav;
      } else if (this.organizationType === this.organizationTypeLabel.ADMIN) {
        return this.baseNav;
      }
    },
    nav_items() {
      return this.filterNav(this.items);
    },
  },
  methods: {
    generatedId(val) {
      return val || val !== '/'  ? `${val.replaceAll("/", "")}-menu` : `generated-input-box`;
    },
    getPermissions(module) {
      if (this.permissions) {
        return this.permissions[module] ? this.permissions[module] : null;
      } else {
        return null;
      }
    },
    filterNav(navList) {
      return navList && Array.isArray(navList)
        ? navList.filter((navItem) => {
            // if (navItem.subLinks && Array.isArray(navItem.subLinks)) {
            //   navItem.subLinks = this.filterNav(navItem.subLinks);
            //   if (navItem.subLinks.length == 0) {
            //     return false;
            //   }
            // }
            if (!navItem.permissionKey) {
              return true;
            }
            const permission = this.getPermissions(navItem.permissionKey);
            return permission
              ? Object.values(permission).indexOf(true) > -1
              : false;
          })
        : [];
    },
  },
  mounted() {
    if (!localStorage.getItem("organization_type")) {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped>
.mdi-10px {
  font-size: 10px;
}

.v-list-item .v-list-item__title,
.app-list-margin .v-list-item__subtitle {
  font-size: 15px !important;
}
</style>
