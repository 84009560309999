export const tripLabel = {
  name: "Trip",
  plural: "Trips",
  pathName: "trip",
  value: "trip",
  permissionKey: "trip",
};

export const tripExpenseLabel = {
  name: "Trip Expense",
  plural: "Trip Expenses",
  shortHandName: "Expense",
  // pathName: "trip",
  // value: "trip",
  keyword: "expense",
};

export const tripSelectOptionLabel = {
  statusList: [
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Declined",
      value: "declined",
    },
    {
      label: "Scheduled",
      value: "scheduled",
    },
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Paused",
      value: "paused",
    },
    {
      label: "Admin Action Required",
      value: "admin_action_required",
    },
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Cancelled",
      value: "cancelled",
    },
  ],
  statusType: {
    pending: "pending",
    approved: "approved",
    declined: "declined",
    scheduled: "scheduled",
    active: "active",
    paused: "paused",
    admin_action_required: "admin_action_required",
    completed: "completed",
    cancelled: "cancelled",
  },
  statusTypeArray: [
    "pending",
    "approved",
    "declined",
    "scheduled",
    "active",
    "paused",
    "admin_action_required",
    "completed",
    "cancelled",
  ],
};
