export const version = "/api/v1";

export const orderTracking = {
  base: `${version}/alraya/trip_stop_locations/tracking_detail/`,
  orderPackage: `${version}/alraya/`,
};
export const user = {
  base: `${version}/user/`,
};

export const authentication = {
  login: `${version}/auth/login/`,
  twoFactorLogin: `${version}/auth/2fa-login/`,
  twoFactorLogout:`${version}/auth/2fa-logout/`,
  twoFactorLogoutAll:`${version}/auth/2fa-logoutall/`,
  verifyOTP:`${version}/auth/verify-otp/`,
  profileInfo: `${version}/user/profile/`,
  resendOTP: `${version}/auth/resend-otp/`,
  logout: `${version}/logout`,
  changePassword: `${user.base}change_password/`,
};

export const dashboard = {
  base: `${version}/summary/`,
};
export const trip = {
  base: `/trip/`,
  logs: `/trip_log/`,
  route: "/trip_routes/",
  tripLoadSheetExcel: "/trip_load_sheet/",
  tripSummary: "trip_summary/",
  tripLoadSheetList: "generate_trips_consolidated_sheet/",
  tripAdminActionRequired: "/alraya_mark_trip_as_admin_action_required/",
  tripComplete: "/alraya_mark_as_complete/",
  tripOdometer: "/alraya_update_odometer_readings/",
  tripStopLocation: "/trip_stop_locations/",
  tripAssetChange: "/asset_allocation/",
};

export const tripExpense = {
  base: `/trip_expense/`,
};
export const tripFuelExpense = {
  base: `/trip_fuel_expense/`,
};

export const order = {
  base: `/order/`,
  orderItem: "/order_item/",
  orderLogs: "/order_logs/",
  orderPackage: "/order_package/",
  getMovementType: `/order/get_movement_type/`,
  orderAttachment: `/order_attachment/`,
  emptyPackages: `/empty_packages/`,
};

export const bid = {
  base: `${version}/bid/`,
};
export const transporterBid = {
  base: `${version}/transporter_bid/`,
};
export const bidParticipant = {
  base: `${version}/bid_participant/`,
};

export const item = {
  base: `${version}/item/`,
};

// configuration endpoints
export const project = {
  base: `${version}/project/`,
};

export const warehouse = {
  base: `${version}/warehouse/`,
};

export const storageType = {
  base: `${version}/storage_type/`,
};

export const vehicleType = {
  base: `${version}/vehicle_type/`,
};

export const roadBan = {
  base: `${version}/road_ban/`,
};

export const paymentMethod = {
  base: `${version}/paymentmethod/`,
};

export const field = {
  base: `${version}/field_configuration/`,
  module: `${version}/module/`,
  moduleKeyword: `${version}/module_key_word/`,
  reason: `${version}/app/alraya/reason_for_returned/`,
};

export const role = {
  base: `${version}/role/`,
};

// tags endpoints
export const tag = {
  base: `${version}/tag/`,
  driverTag: `${version}/tag/driver/`,
  vehicleTag: `${version}/tag/vehicle/`,
  customerTag: `${version}/tag/customer/`,
  assignedAssets: "assigned_assets",
};

export const packageType = {
  base: `${version}/package_type/`,
};

export const customerAddressTag = {
  base: `${version}/customer_address_tag/`,
};

export const vehicleTag = {
  base: `${version}/vehicle_tag/`,
};

export const driverTag = {
  base: `${version}/driver_tag/`,
};

// assets endpoints
export const vehicle = {
  base: `${version}/vehicle/`,
  logs: `${version}/vehicle_logs/`,
};
// assets endpoints
export const transporterVehicle = {
  base: `${version}/transporter_vehicle/`,
  logs: `${version}/transporter_vehicle_logs/`,
  document: `${version}/transporter_vehicle_document/`,
};

export const vehicleInsurance = {
  base: `${version}/vehicle_insurance/`,
};
export const vehicleService = {
  base: `${version}/vehicle_service/`,
};
export const vehicleConsumable = {
  base: `${version}/vehicle_consumable/`,
};

export const driver = {
  base: `${version}/driver/`,
  logs: `${version}/driver_logs/`,
};
export const transporterDriver = {
  base: `${version}/transporter_driver/`,
  logs: `${version}/transporter_driver_logs/`,
  document: `${version}/transporter_driver_document/`,
};

export const vehicleDocument = {
  base: `${version}/vehicle_document/`,
};

export const driverDocument = {
  base: `${version}/driver_document/`,
};

// customer management endpoints
export const customer = {
  base: `${version}/customer/`,
  logs: `${version}/customer_logs/`,
};

export const customerAddress = {
  base: `${version}/customer_address/`,
  logs: `${version}/customer_address_logs/`,
};

export const integrationLog = {
  base: `${version}/integration/`,
  itemBase: `${version}/integration/item_logs/`,
  customerBase: `${version}/integration/customer_logs/`,
  orderBase: `${version}/integration/order_logs/`,
  getPayload: `get_payload`,
};

// notification endpoints
export const notification = {
  base: `${version}/notification/`,
  markAsSeen: "mark_as_seen",
  notificationCount: "notification_count",
};
// integration logs endpoints
export const city = {
  base: `${version}/city/`,
  select: `${version}/city/select/`,
};
export const zone = {
  base: `${version}/zone/`,
  select: `${version}/zone/select/`,
};
export const laneRoute = {
  base: `${version}/route/`,
  select: `${version}/route/select/`,
};
export const tripPlanning = {
  base: `${version}/trip-planning/`,
  options: `${version}/trip-planning/options/`,
  tripPlannedRoute: `${version}/planned_trip/`,
};
export const planningTemplate = {
  base: `${version}/planning_template/`,
  select: `${version}/planning_template/select/`,
};
export const tripPlan = {
  base: `${version}/trip_plan/`,
  unplannedOrder: `${version}/planned_cargo/`,
  tripPlanDriver: `${version}/planned_performer/`,
  tripPlanVehicle: `${version}/planned_transporter/`,
  tripPlanList: `${version}/planned_trip/`,
  report: `${version}/trip-planning-analysis-report/`,
};

export const transporterTrip = {
  base: `${version}/transporter_trip/`,
};

export const transporter = {
  base: `${version}/transporter/`,
};

export const transporterRate = {
  base: `${version}/lane_rate/`,
};

export const rate = {
  base: `${version}/lane_rate/`,
  sampleRateFile: `${version}/lane_rate/catalogue_sheet/`,
};

export const transporterUser = {
  base: `${version}/transporter_user/`,
};
export const operation = {
  base: `${version}/operations/`,
  downloadSheet: `${version}/operations/download_sheet/`
};

export const report = {
  base: `${version}/reports/`,
};

export const support = {
  base: `${version}/support/`,
  redirect: `${version}/support/redirect/`,
};
