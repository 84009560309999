import Blank from "@/layouts/blank.vue";
import { driverLabel } from "@/utils/labels/assetManagementLabels";

const driverRoutes = [
  {
    path: `${driverLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${driverLabel.value}-list`,
        component: () => import("@/pages/assets/driver/DriverList.vue"),
      },
      {
        path: "details/:id",
        name: `${driverLabel.value}-details`,
        component: () => import("@/pages/assets/driver/DriverDetail.vue"),
      },
      {
        path: "add/",
        name: `${driverLabel.value}-add`,
        component: () => import("@/pages/assets/driver/DriverAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${driverLabel.value}-edit`,
        component: () => import("@/pages/assets/driver/DriverEdit.vue"),
      },
      {
        path: `${driverLabel.value}-overtime-list`,
        name: `${driverLabel.value}-overtime-list`,
        component: () =>
          import("@/pages/assets/driverOverTime/driverOverTimeList.vue"),
      },
    ],
  },
];

export default driverRoutes;
