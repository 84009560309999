import { vehicleTag } from "@/utils/urlConstants";

export default (axios) => ({
  getVehicleTagList(params) {
    return axios.get(vehicleTag.base, { params: params });
  },
  addVehicleTag(data) {
    return axios.post(vehicleTag.base, data);
  },
  deleteVehicleTag(id) {
    return axios.delete(`${vehicleTag.base}${id}/`);
  },
});
