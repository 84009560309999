import { transporterRate } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  getTransporterRateList(params) {
    return get(`${transporterRate.base}`, { params: params });
  },
  getTransporterRateSelectList(params) {
    return get(`${transporterRate.base}`, { params: params });
  },
  getTransporterRateObject(id) {
    return get(`${transporterRate.base}${id}/`);
  },
  addTransporterRate(data) {
    return post(`${transporterRate.base}`, data);
  },
  updateTransporterRate(data, id) {
    return put(`${transporterRate.base}${id}/`, data);
  },
});
