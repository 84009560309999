import { zone } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  getZoneList(params) {
    return get(`${zone.base}`, { params: params });
  },
  getZoneSelectList(params) {
    return get(`${zone.select}`, { params: params });
  },
  getZoneObject(id) {
    return get(`${zone.base}${id}/`);
  },
  getZoneDetails(id) {
    return get(`${zone.base}${id}/view/`);
  },
  addZone(data) {
    return post(`${zone.base}`, data);
  },
  updateZone(data, id) {
    return put(`${zone.base}${id}/`, data);
  },
});
