const globals = {
  currency: "INR",
  phoneCode: 91,
  country: "India",
  lengthUnit: "CM",
  weightUnit: "KG",
  emailRegExp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
  specialCharactersRegExp: /^(?=.*[a-zA-Z0-9])[\w,._ -]+$/,
  formatDate: (value, withTime) => {
    if (value) {
      const _date = new Date(value)

      if (_date) {

        let date = _date.getDate()
        let month = _date.getMonth() + 1
        let year = _date.getFullYear()

        date = date < 10 ? `0${date}` : `${date}`
        month = month < 10 ? `0${month}` : `${month}`

        let fullDate = [date, month, year].join("/")
        let time = []
        if (withTime) {
          let hour = _date.getHours()
          let minute = _date.getMinutes()

          hour = hour < 10 ? `0${hour}` : `${hour}`
          minute = minute < 10 ? `0${minute}` : `${minute}`

          time = [hour, minute].join(":")

          fullDate = [fullDate, time].join(" ")
        }

        return fullDate
      } else {
        return ""
      }
    }
    return ''
  },
  formatToLocalDate: (value, withTime) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    if (value) {
      const _date = new Date(value)
      if (_date) {
        let formattedDate = _date.toLocaleDateString(undefined, options);
        if (withTime) {
          formattedDate = _date.toLocaleString(undefined, options);
        }
        return formattedDate.replace("am", "AM").replace("pm", "PM")
      } else {
        return "-"
      }
    }
    return "-"
  },
};

export default globals;