import Blank from "@/layouts/blank.vue";
import { tripLabel } from "@/utils/labels/tripLabels";

const tripRoutes = [
  {
    path: `${tripLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${tripLabel.name}-list`,
        component: () => import("@/pages/trip/TripList.vue"),
        default: true,
      },
      {
        path: "add/",
        name: `${tripLabel.value}-add`,
        component: () => import("@/pages/trip/TripAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${tripLabel.value}-edit`,
        component: () => import("@/pages/trip/TripEdit.vue"),
      },
      {
        path: "details/:id",
        name: `${tripLabel.value}-details`,
        component: () => import("@/pages/trip/TripDetail.vue"),
        default: true,
      },
      {
        path: "trip-schedule-cancelled/:id",
        name: `${tripLabel.value}-schedule-cancelled`,
        component: () => import("@/pages/trip/TripRescheduleAndCancel.vue"),
        default: true,
      },
      {
        path: "mark-trip-as-complete/:id",
        name: `${tripLabel.value}-complete`,
        component: () => import("@/pages/trip/MarkTripAsComplete.vue"),
        default: true,
      },
      {
        path: "schedule/",
        name: `${tripLabel.value}-schedule`,
        component: () => import("@/pages/trip/TripSchedule.vue"),
      },
    ],
  },
];

export default tripRoutes;
