import { vehicleInsurance } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  getVehicleInsuranceList(params) {
    return get(`${vehicleInsurance.base}`, {
      params: params,
    });
  },
  getVehicleInsuranceObject(id) {
    return get(`${vehicleInsurance.base}${id}/`);
  },
  addVehicleInsurance(data) {
    return post(`${vehicleInsurance.base}`, data);
  },
  updateVehicleInsurance(data, id) {
    return put(`${vehicleInsurance.base}${id}/`, data);
  },
});
