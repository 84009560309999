import Blank from "@/layouts/blank.vue";
import { transporterLabel } from "@/utils/labels/transporterManagementLabels";

const transporterRoutes = [
  {
    path: `${transporterLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${transporterLabel.value}-list`,
        component: () =>
          import(
            "@/pages/transporterManagement/transporter/TransporterList.vue"
          ),
      },
    ],
  },
];

export default transporterRoutes;
