import { city } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  getCityList(params) {
    return get(`${city.base}`, { params: params });
  },
  getCitySelectList(params) {
    return get(`${city.select}`, { params: params });
  },
  getCityObject(id) {
    return get(`${city.base}${id}/`);
  },
  getCityDetails(id) {
    return get(`${city.base}${id}/view/`);
  },
  addCity(data) {
    return post(`${city.base}`, data);
  },
  updateCity(data, id) {
    return put(`${city.base}${id}/`, data);
  },
});
