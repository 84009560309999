import Blank from "@/layouts/blank.vue";
import { transporterRateLabel } from "@/utils/labels/transporterManagementLabels";

const transporterRateRoutes = [
  {
    path: `${transporterRateLabel.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${transporterRateLabel.value}-list`,
        component: () =>
          import(
            "@/pages/transporterManagement/transporterRate/TransporterRateList.vue"
          ),
      },
      {
        path: "details/:id",
        name: `${transporterRateLabel.value}-details`,
        component: () =>
          import(
            "@/pages/transporterManagement/transporterRate/TransporterRateDetail.vue"
          ),
      },
      {
        path: "add/",
        name: `${transporterRateLabel.value}-add`,
        component: () =>
          import(
            "@/pages/transporterManagement/transporterRate/TransporterRateAdd.vue"
          ),
      },
      {
        path: "edit/:id",
        name: `${transporterRateLabel.value}-edit`,
        component: () =>
          import(
            "@/pages/transporterManagement/transporterRate/TransporterRateEdit.vue"
          ),
      },
    ],
  },
];

export default transporterRateRoutes;
