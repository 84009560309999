import { vehicleConsumable } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  getVehicleConsumableList(params) {
    return get(`${vehicleConsumable.base}`, {
      params: params,
    });
  },
  getVehicleConsumableObject(id) {
    return get(`${vehicleConsumable.base}${id}/`);
  },
  addVehicleConsumable(data) {
    return post(`${vehicleConsumable.base}`, data);
  },
  updateVehicleConsumable(data, id) {
    return put(`${vehicleConsumable.base}${id}/`, data);
  },
});
