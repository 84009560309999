import { bid, transporterBid, bidParticipant } from "@/utils/urlConstants";
import { isTransporter } from "@/utils/functions";

export default ({ get, post, put, patch }) => ({
  getBidList(params) {
    return get(`${isTransporter() ? transporterBid.base : bid.base}`, {
      params: params,
    });
  },
  getBidSelectList(params) {
    return get(`${bid.base}`, { params: params });
  },
  getBidParticipantList(params) {
    return get(`${bidParticipant.base}`, { params: params });
  },
  acceptedBidRate(id, data) {
    return patch(`${bid.base}${id}/accept_bid/`, data);
  },
  getBidObject(id) {
    return get(`${bid.base}${id}/`);
  },
  getBidDetails(id) {
    return get(`${bid.base}${id}/view/`);
  },
  addBid(data) {
    return post(`${bid.base}`, data);
  },
  updateBid(data, id) {
    return put(`${bid.base}${id}/`, data);
  },
  acceptedBid(id, data) {
    return patch(`${transporterBid.base}${id}/place_bid/`, data);
  },

  rejectedBid(id) {
    console.log(`Bid ID = ${id}`);
    return patch(`${transporterBid.base}${id}/reject_bid/`);
  },
});
