import Blank from "@/layouts/blank.vue";
import { laneManagementLabel } from "@/utils/labels/laneManagementLabels.js";
import citiesRoutes from "./city.js";
import laneRoutes from "./laneRoute.js";
import zoneRoutes from "./zone.js";

const laneManagementRoutes = [
  {
    path: `${laneManagementLabel.pathName}/`,
    component: Blank,
    children: [...citiesRoutes, ...laneRoutes, ...zoneRoutes],
    name: laneManagementLabel.value,
  },
];

export default laneManagementRoutes;
