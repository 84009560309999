import { driverDocument, transporterDriver } from "@/utils/urlConstants";
import { isTransporter } from "@/utils/functions";

export default (axios) => ({
  getDriverDocumentList(params) {
    return axios.get(
      `${isTransporter() ? transporterDriver.document : driverDocument.base}`,
      { params: params }
    );
  },
  addDriverDocument(data) {
    return axios.post(
      `${isTransporter() ? transporterDriver.document : driverDocument.base}`,
      data
    );
  },
  updateDriverDocument(data, id) {
    return axios.put(
      `${`${
        isTransporter() ? transporterDriver.document : driverDocument.base
      }`}${id}/`,
      data
    );
  },
  deleteDriverDocument(id) {
    return axios.delete(
      `${`${
        isTransporter() ? transporterDriver.document : driverDocument.base
      }`}${id}/`
    );
  },
});
