import { transporterUser } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  getTransporterUserList(params) {
    return get(`${transporterUser.base}`, { params: params });
  },
  getTransporterUserSelectList(params) {
    return get(`${transporterUser.select}`, { params: params });
  },
  getTransporterUserObject(id) {
    return get(`${transporterUser.base}${id}/`);
  },
  getTransporterUserDetails(id) {
    return get(`${transporterUser.base}${id}/view/`);
  },
  addTransporterUser(data) {
    return post(`${transporterUser.base}`, data);
  },
  updateTransporterUser(data, id) {
    return put(`${transporterUser.base}${id}/`, data);
  },
});
