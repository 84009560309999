<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="true"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip class="primary" v-bind="attrs" v-on="on">
        <h4
          style="width: 80px"
          class="text-center text-caption font-weight-bolder"
        >
          <span class="mr-2">
            {{ yearPicker }}
          </span>
          <v-icon color="white">mdi-menu-down</v-icon>
        </h4>
      </v-chip>
    </template>
    <v-date-picker
      width="100"
      @click:year="eventHandler"
      :active-picker.sync="activePicker"
      no-title
      v-bind="$attrs"
      v-on="$listeners"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
  },
  computed: {
    yearPicker: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    menu(val) {
      return val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  data() {
    return {
      activePicker: null,
      menu: false,
    };
  },
  methods: {
    eventHandler(year) {
      this.yearPicker = year;
      this.$emit("change", this.yearPicker);
    },
  },
};
</script>

<style>
</style>