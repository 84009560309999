import Blank from "@/layouts/blank.vue";
import { planTripsLabel } from "@/utils/labels/tripPlanningLabels.js";
import tripPlanningTemplateRoutes from "@/router/planTrip/planningTemplate.js";
import tripPlanning from "@/router/planTrip/tripPlanning.js";

const planTripRoutes = [
  {
    path: `${planTripsLabel.pathName}/`,
    component: Blank,
    children: [...tripPlanningTemplateRoutes, ...tripPlanning],
    name: planTripsLabel.value,
  },
];

export default planTripRoutes;
