import { transporter } from "@/utils/urlConstants";

export default ({ get, post, put, patch }) => ({
  getTransporterList(params) {
    return get(`${transporter.base}`, { params: params });
  },
  getTransporterSelectList(params) {
    return get(`${transporter.base}`, { params: params });
  },
  getTransporterObject(id) {
    return get(`${transporter.base}${id}/`);
  },
  getTransporterDetails(id) {
    return get(`${transporter.base}${id}/view/`);
  },
  addTransporter(data) {
    return post(`${transporter.base}`, data);
  },
  updateTransporter(data, id) {
    return put(`${transporter.base}${id}/`, data);
  },
});
